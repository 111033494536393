import { MenuConfig } from 'lego-v2/menu/config';
import useItems from './__use-items';


const useGalleryMarkersMarkersMenu = () => {
  const items = useItems();

  const getConfig = () => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getDuplicateMarkers(),
          ],
        },  
        {
          items: [
            items.getDeleteMarkers(),
          ],
        },  
      ]
    }
  
    return config;
  }

  const hook = () => {
    const config = getConfig();
    return config;
  }

  return hook;
}


export default useGalleryMarkersMarkersMenu;