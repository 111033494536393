import { MenuConfig } from 'lego-v2/menu/config';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import useItems from './__use-items';


const useSectionMenu = () => {
  const items = useItems();

  const getConfig = (sectionAddr: Types.SectionAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getAddSectionAbove(sectionAddr),
            items.getAddSectionBelowe(sectionAddr),
          ],
        }, 
        {
          items: [
            items.getProperties(sectionAddr),
          ],
        },    
        {
          items: [
            items.getDeleteSection(sectionAddr),
          ],
        },
      ]
    }

    return config;
  }


  const hook = (sectionAddr: Types.SectionAddr) => {
    const config = getConfig(sectionAddr);
    return config;
  }

  return hook;
}


export default useSectionMenu;