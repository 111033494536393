import React from 'react';

import ContentCellMenuComponent      from './content-cell-menu';
import ContentCellItemsMenuComponent from './content-cell-items-menu';
import CustomFieldsCellMenuComponent from './custom-fields-cell-menu';
import CustomRowsCellMenuComponent   from './custom-rows-cell-menu';
import BlankMenuComponent            from './blank-menu';
import GalleryItemsMenuComponent     from './gallery-items-menu';
import HeaderItemsMenuComponent      from './header-items-menu';

import { MainWrapper } from './styles';


interface Props {
}


export const TopMenuComponent: React.FC<Props> = (props: Props) => {
  return (
    <MainWrapper>
      <ContentCellMenuComponent />
      <ContentCellItemsMenuComponent />
      <CustomFieldsCellMenuComponent />
      <CustomRowsCellMenuComponent />
      <HeaderItemsMenuComponent />
      <GalleryItemsMenuComponent />
      <BlankMenuComponent />
    </MainWrapper>
  );
}
