import { useIsMobile } from 'lego-hooks/use-is-mobile';
import { MenuItemType } from 'lego-v2/menu';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { Icon } from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useFunctions from './__use-functions';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();
  const isMobile = useIsMobile();


  const getUploadImageFromDisk = (cellAddr: ContentTypes.CellAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('images, upload from disk'),
      iconComponent: Icon.Upload.Disk,
      onClick: (event: React.MouseEvent) => fns.uploadImageFromDisk(cellAddr),
    }
  });

  const getUploadImageFromCamera = (cellAddr: ContentTypes.CellAddr) => ({
    skip: ! isMobile,
    type: MenuItemType.BUTTON,
    data: {
      title: t('image, upload from camera'),
      iconComponent: Icon.Upload.Camera,
      onClick: (event: React.MouseEvent) => fns.uploadImageFromCamera(cellAddr),
    }
  });

  const getEditImage = (imageAddr: ContentTypes.ImageAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('image, edit'),
      iconComponent: Icon.Edit.Plain,
      onClick: (event: React.MouseEvent) => fns.editImage(imageAddr),
    }
  });

  const getDuplicateImage = (imageAddr: ContentTypes.ImageAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('image, duplicate'),
      iconComponent: Icon.Duplicate,
      onClick: (event: React.MouseEvent) => fns.duplicateImage(imageAddr),
    }
  });

  const getRemoveImage = (imageAddr: ContentTypes.ImageAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('image, remove'),
      iconComponent: Icon.Delete,
      onClick: (event: React.MouseEvent) => fns.removeImage(imageAddr),
      variant: 'delete'
    }
  });


  return {
    getUploadImageFromDisk,
    getUploadImageFromCamera,
    getEditImage,
    getDuplicateImage,
    getRemoveImage,
  }
}


export default useItems;