import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { UIState_HeaderyMarkersSelected } from 'app/ui/states/editor-instruction';

import { MainWrapper } from './styles';


interface Props {
  markerAddr: HeaderMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerSelectedComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children 
  } = props;

  const selected = useRecoilValue(UIState_HeaderyMarkersSelected.isSelected(markerAddr));
  
  return (
    <MainWrapper selected={selected}>
      { children }
    </MainWrapper>
  );
}
  