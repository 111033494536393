import { PanelControlsItemSectionItemType } from 'lego/components/panel-controls/config';

import jtl from 'tools/jtl';
import { settings } from 'app/configs';
import PanelItemGenerator from 'app/ui-v2/editor-instruction/panel-items/generator';
import { useTranslations } from "app/ui/hooks/app/use-translation";

import useStates from './__use-states';
import useFunctions from './__use-functions';



const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();
  const states = useStates();


  const getTableHeaderPadding = () => ({
    title: t('padding'),
    type: PanelControlsItemSectionItemType.SLIDER,
    onChange: fns.handleTableHeaderFontPaddingChange,
    onChangeDone: fns.handleTableHeaderFontPaddingChangeDone,
    min: settings.printView.table.header.padding.min, 
    max: settings.printView.table.header.padding.max, 
    value: jtl.css.valueToNumber(states.tableHeaderCSS.padding?.toString()),
  });
  
  const getTableHeaderFontSize = () => ({
    title: t('size'),
    type: PanelControlsItemSectionItemType.SLIDER,
    onChange: fns.handleTableHeaderFontSizeChange,
    onChangeDone: fns.handleTableHeaderFontSizeChangeDone,
    min: settings.printView.table.header.font.size.min, 
    max: settings.printView.table.header.font.size.max,
    value: jtl.css.valueToNumber(states.tableHeaderCSS.fontSize?.toString()),
  });

  const getTableHeaderFontWeight = () => ({
    title: t('font weight'),
    type: PanelControlsItemSectionItemType.SLIDER,
    onChange: fns.handleTableHeaderFontWeightChange,
    onChangeDone: fns.handleTableHeaderFontWeightChangeDone,
    min: 1, 
    max: 9,
    value: jtl.css.valueToNumber(states.tableHeaderCSS.fontWeight?.toString()) / 100,
  });

  const getTableHeaderFontCapsLock = () => 
    PanelItemGenerator.getFontCapslock({
      css: states.tableHeaderCSS,
      onClick: fns.handleTableHeaderCSSUpdate
  });

  const getTableHeaderFontColor = () => ({
    title: t('font color'),
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    onColorSelected: fns.handleTableHeaderColor,
    color: states.tableHeaderCSS.color,
  });

  const getTableHeaderBackground = () => ({
    title: t('background'),
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    onColorSelected: fns.handleTableHeaderBackground,
    color: states.tableHeaderCSS.background,
  });


  return {
    getTableHeaderPadding,
    getTableHeaderFontSize,
    getTableHeaderFontWeight,
    getTableHeaderFontCapsLock,
    getTableHeaderFontColor,
    getTableHeaderBackground,
  }
}


export default useItems;