import styled from "styled-components";
import ScrollbarSmall from "lego/styles/scrollbar-small";


export const DynamicWrapper = styled.div<{
  $left?: boolean, 
  $right?: boolean,
  $visible: boolean, 
  $width: number,
  $widthMax: boolean,
}>`
  ${props => props.$left ? 'direction: rtl': '' };
  width: ${props => props.$visible ? (
    props.$widthMax ?
    '100%' :
    `${props.$width}px`
  ): 0};

  height: 100%;

  overflow-x: clip;

  transition: 
    width 
    ${props => props.theme.defs.transition.time.long} 
    ease-in-out
  ;

  background: ${props => props.theme.defs.colors.background.primary};
`;

export const FixedWrapper = styled(ScrollbarSmall)<{
  $left?: boolean, 
  $right?: boolean,
  $width: number,
  $widthMax: boolean,
}>`
  ${props => props.$left ? 'direction: ltr': '' };

  width: ${props => props.$widthMax ? '100%' : `${props.$width}px`};
  height: 100%;
  overflow-y: auto;
  
  box-sizing: border-box;

  border-width: ${props => props.theme.defs.border.width.normal};
  border-color: ${props => props.theme.defs.colors.border.primary}; 
  
  border-left-style:  ${props => props.$right ? 'solid' : 'none'};
  border-right-style: ${props => props.$left ? 'solid' : 'none'};
`;
