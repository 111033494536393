import { ScreenSizeTypes } from "app/arch/app/screen";


export enum PanelType {
  NARROW_190 = 'narrow-192',
  NARROW_230 = 'narrow-230',

  WIDE_320 = 'wide-320',
  WIDE_360 = 'wide-360',
  WIDE_390 = 'wide-390',

  WIDE_MAX = 'wide-max',
}


export interface PropLeft {
  left: boolean;
  right?: never;
}

export interface PropRight {
  right: boolean;
  left?: never;
}

// export type PropLeftOrRight = (PropLeft | PropRight);

export type PropLeftOrRight =  {
  left?: boolean,
  right?: boolean,
}


export enum PanelFlow {
  IN_FLOW = 'in-flow',
  DETACHED = 'detached',
}


export type ScreenToPanelMap = {
  [key in ScreenSizeTypes.SizeVariant]: PanelType
}


export type PanelWidthsMap = {
  [key in PanelType]: number
}