import React from 'react';
import { useRecoilValue } from 'recoil';
import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { UIState_GalleryImagesSelected } from 'app/ui/states/editor-instruction';
import { MainWrapper } from './styles';


interface Props {
  image: RepoImagesTypes.Image;
  children: React.ReactNode;
}

export const ImageSelectedComponent: React.FC<Props> = (props: Props) => {
  const {
    image,
    children
  } = props;

  const selected = useRecoilValue(UIState_GalleryImagesSelected.isSelected(image));

  return (
    <MainWrapper selected={selected}>
      { children }
    </MainWrapper>
  );
}
  
