import { RepoMarkersTools } from '../../persistent/repo-markers';
import { RepoMarkersTypes } from '../../persistent/repo-markers';
import ItemsSelectedBase from '../_parts/items-selected-base';


export class GalleryMarkersSelected extends ItemsSelectedBase<RepoMarkersTypes.MarkerAddr> {
  protected getKey(markerAddr: RepoMarkersTypes.MarkerAddr): string {
    return RepoMarkersTools.getMarkerKey(markerAddr);
  }
}
