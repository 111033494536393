import { uuid } from "tools/uuid";
import Logger   from "libs/debug";

import { KeyBinding }            from "./types";
import { KeyBindingsRepository } from "./types";
import { KeyBindingPriority }    from "./types";

import { UUID } from "app/arch/types";

const getLogger = Logger.getEditorKeyBinding;

export class KeyboardBindings__ {
  private static instance: KeyboardBindings__;
  
  public static getInstance(): KeyboardBindings__ {
    if (!KeyboardBindings__.instance) {
      KeyboardBindings__.instance = new KeyboardBindings__();
    }
    return KeyboardBindings__.instance;
  }

  private _keyUpBindings:   KeyBindingsRepository;
  private _keyDownBindings: KeyBindingsRepository;
  private _enabled:         boolean;

  constructor() {
    this._keyUpBindings   = {};
    this._keyDownBindings = {};
    this._enabled         = true;
  }

  addKeyDownBinding(binding: KeyBinding) {
    const id = uuid();
    const bindingObj = this.createBinding(binding);
    this._keyDownBindings[id] = bindingObj;
    return id;
  }
  
  removeKeyDownBinding(bindingId: UUID) {
    delete this._keyDownBindings[bindingId];
  }

  addKeyUpBinding(binding: KeyBinding) {
    const id = uuid();
    const bindingObj = this.createBinding(binding);
    this._keyUpBindings[id] = bindingObj;
    return id;
  }

  removeKeyUpBinding(bindingId: UUID) {
    delete this._keyUpBindings[bindingId];
  }

  get keyUpBindings()   { return this.sortBindingsByPriority(this._keyUpBindings); }
  get keyDownBindings() { return this.sortBindingsByPriority(this._keyDownBindings);   }
  
  get enabled() { return this._enabled; }

  set enabled(enabled: boolean) { 
    const logger = getLogger();
    logger.debug(`Key bindings enabled: ${enabled}`);

    this._enabled = enabled; 
  }

  private sortBindingsByPriority(bindingsRepo: KeyBindingsRepository) {
    const bindings = Object.values(bindingsRepo);
    const sorted = bindings.sort((a, b) => b.priority! - a.priority!);

    return sorted;
  }

  private createBinding(binding: KeyBinding) {
    const preventDefault  = binding.preventDefault ?? true;
    const stopPropagation = binding.stopPropagation ?? true;
    const priority        = binding.priority ?? KeyBindingPriority.LOWEST;

    const bindingObj = {
      check: binding.check,
      callback: binding.callback,
      preventDefault,
      stopPropagation,
      priority,
    };

    return bindingObj;
  }
}

const KeyboardBindings = KeyboardBindings__.getInstance();

export default KeyboardBindings;
