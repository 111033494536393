import styled from "styled-components";
import { settings } from "app/configs";
import ContentItemBorder from "../../../styles/content-item-border";


const DOC_HEADER_REV_INFO = settings.document.header.revisionInfo;


export const MainWrapper = styled(ContentItemBorder)`
  display: flex;
  flex-direction: column;
  width: 100%;

  user-select: none;
`;


export const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  border: none;
  box-sizing: border-box;
`;


export const LogoWrapper = styled.div`
  border: none;
  box-sizing: border-box;
`;


export const SeparatorVertical = styled.div`
  border-right: 1px solid black;
`;


export const DocumentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: none;

  width: 100%;
  min-width: 120px;
`;


export const DocumentRevisionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing:   border-box;

  min-width: ${DOC_HEADER_REV_INFO.width}px;
  max-width: ${DOC_HEADER_REV_INFO.width}px;
`;

