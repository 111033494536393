import { MenuItemType } from 'lego-v2/menu';

import { Icon } from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useFunctions from './__use-functions';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();

  const getColumnsClear = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('columns, clear'),
      iconComponent: Icon.Clear,
      onClick: (event: React.MouseEvent) => fns.clearColumns(),
    }
  });

  const getColumnsDelete = () => ({
    type: MenuItemType.BUTTON,
    data: {
      variant: 'delete',
      title: t('columns, delete'),
      iconComponent: Icon.Delete,
      onClick: (event: React.MouseEvent) => fns.deleteColumns(),
    }
  });


  return {
    getColumnsClear,
    getColumnsDelete,
  }
}


export default useItems;