import { useEffect } from 'react';
import useKBCellsImages from './use-kb-cells-images';
import useKBCellsMarkers from './use-kb-cells-markers';
import useKBHeaderMarkers from './use-kb-header-markers';
import useKBGalleryMarkers from './use-kb-gallery-markers';
import useKBGalleryImages from './use-kb-gallery-images';


/**
 * Content cell key bindings
 */

const useKBContent = () => {
  const kbCellsImages = useKBCellsImages();
  const kbCellsMarkers = useKBCellsMarkers();
  const kbHeaderMarkers = useKBHeaderMarkers();
  const kbGalleryMarkers = useKBGalleryMarkers();
  const kbGalleryImages = useKBGalleryImages();
  

  const compsBindings = [
    kbCellsImages,
    kbCellsMarkers,
    kbHeaderMarkers,
    kbGalleryMarkers,
    kbGalleryImages,
  ];

  useEffect(() => {
    compsBindings.forEach(bindings => bindings.addBindings());

    return () => {
      compsBindings.forEach(bindings => bindings.removeBindings());
    }
  }, []);
}

export default useKBContent;