import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { DNDContext }       from 'app/ui-v2/editor-instruction/dnd-context';
import HLS_DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import useDNDDataSet from "app/ui-v2/editor-instruction/hooks/use-dnd-data-set";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import { useDocState } from "app/ui/contexts/document";

import CellIndexDraggedViewComponent from './cell-index-dragged-view';
import { DraggerWrapper } from './styles';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellIndexDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    cellAddr, 
    children, 
  } = props;

  const setDNDData = useDNDDataSet();
  const docState = useDocState();
  const { hideMenu } = useContextMenu();

  const {
    setContentRowsSelected,
  } = useEditorStatesSetters();

  const handleDragStart = () => {
    const rowAddr: Types.RowAddr = {
      sectionId: cellAddr.sectionId,
      rowId: cellAddr.rowId
    }

    const data = { rowAddr };
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  const handleDragMoveFirst = () => {
    hideMenu();
    docState.contentRowsSelected.reset();
    docState.contentRowsSelected.select(cellAddr);
    setContentRowsSelected();
  }

  return (
    <HLS_DraggerComponent
      dndContext={DNDContext.viewContent.table.row}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      Wrapper={DraggerWrapper}
      draggedView={
        <CellIndexDraggedViewComponent cellAddr={cellAddr} />
      }
    >
      { children }
    </HLS_DraggerComponent>
  );
}
