import React from 'react';
import { useRecoilValue } from 'recoil';

import { UUID } from 'app/arch/types';
import { UIState_ViewsCommon } from 'app/ui/states/editor-instruction';

import ContentDateComponent        from './content-date';
import ContentHeaderComponent      from './content-header';
import ContentDescriptionComponent from './content-description';
import ReleaseInfoContextMenuComponent from './release-info-context-menu';

import { MainColumn } from './styles';
import { MainInfo }   from './styles';


interface Props {
  releaselogId: UUID;
}


export const ReleaseInfoContentComponent: React.FC<Props> = (props: Props) => {
  const {
    releaselogId
  } = props;

  const tableHeaderCSS = useRecoilValue(UIState_ViewsCommon.tableHeaderCSS);

  const {
    color,
    background,
  } = tableHeaderCSS;

  const style = { color, background };


  return (
    <ReleaseInfoContextMenuComponent>
      <MainColumn style={style}>
        <MainInfo>
          <ContentHeaderComponent releaselogId={releaselogId} />
          <ContentDateComponent releaselogId={releaselogId} />
        </MainInfo>

        <ContentDescriptionComponent releaselogId={releaselogId} />
      </MainColumn>
    </ReleaseInfoContextMenuComponent>
  );
}
  
