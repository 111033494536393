import React from 'react';

import { useTimer } from 'lego-hooks/use-timer';

import useAppLogin from 'app/ui/hooks/app/use-app-login';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ViewPanelComponent from 'app/ui-v2/home-page/hls/view-panel';
import ProgressComponent from 'app/ui-v2/hls/progress';

import { Panel } from './styles';


export const TIMER_DURATION = 2;

interface Props {
  email: string;
  password: string;
}


export const PasswordUpdatedComponent: React.FC<Props> = (props: Props) => {
  const { 
    email, 
    password 
  } = props;

  const t = useTranslations();
  const appLogin = useAppLogin();

  const onTimerDone = () => {
    appLogin(email, password);
  }

  const timer = useTimer({
    duration: TIMER_DURATION,
    tick: 100,
    onDone: onTimerDone
  });


  return (
    <ViewPanelComponent 
      title={t("password reset ok")} 
      Panel={Panel}
    >
      <ProgressComponent />
    </ViewPanelComponent>
  );
}

