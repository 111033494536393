import useInfoStatesSetters from "../../../hooks/use-info-state-setters";
import useActionsState from "./use-actions-state";


const useActionsReset = () => {
  const actions = useActionsState();

  const {
    setActions
  } = useInfoStatesSetters();

  const resetActions = () => {
    actions.resetActions();
    setActions();
  }

  return resetActions;
}


export default useActionsReset;