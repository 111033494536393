import { HeaderMarkersTools } from '../../persistent/header-markers';
import { HeaderMarkersTypes } from '../../persistent/header-markers';
import ItemsSelectedBase from '../_parts/items-selected-base';


export class HeaderMarkersSelected extends ItemsSelectedBase<HeaderMarkersTypes.MarkerAddr> {
  protected getKey(markerAddr: HeaderMarkersTypes.MarkerAddr): string {
    return HeaderMarkersTools.getMarkerKey(markerAddr);
  }
}
