import React from 'react';

import { useDocState } from 'app/ui/contexts/document';
import useSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useItemMultiSelect from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-multi-select';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { HeaderCellProps } from '../types';

import { MainWrapper } from './styles';


interface Props extends HeaderCellProps {
}


export const HeaderCellSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    pageRowAddr,
    children,
  } = props;

  const docState = useDocState();
  const resetAllSelected = useSelectedReset();

  const {
    setCustomRowsRowsSelected,
  } = useEditorStatesSetters();

  type Addr = HeaderRowsTypes.PageRowAddr;

  const handleSelectItem = useItemMultiSelect<Addr>({
    isSelected: (addr: Addr) => docState.customRowsRowsSelected.isSelected(addr),
    isMultiselected: () => docState.customRowsRowsSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    select: (addr: Addr) => docState.customRowsRowsSelected.select(addr),
    multiSelect: (addr: Addr) => {
      resetAllSelected({skipCustomRowsReset: true});
      docState.customRowsRowsSelected.toggleSelect(addr);
    },
    toggleSelect: (addr: Addr) => docState.customRowsRowsSelected.toggleSelect(addr),
    setState: () => setCustomRowsRowsSelected(),
  });

  const handleSelectColumn = (event: React.MouseEvent) => {
    handleSelectItem(event, pageRowAddr);
  }

  return (
    <MainWrapper onPointerDown={handleSelectColumn}>
      { children }
    </MainWrapper>
  );
}
  
