import { PanelControlsConfig } from 'lego/components/panel-controls/config';
import useItems from './__use-items';


const usePanelConfig = () => {
  const items = useItems();

  const getConfig = (): PanelControlsConfig => {
    const config: PanelControlsConfig = {
      items: [
        {
          sections: [
            {
              subsections: [
                {
                  items: [
                    items.getVisible(),
                  ]
                },
              ],
            }
          ]
        },
      ]
    } // config

    return config;
  }

  return getConfig;
}


export default usePanelConfig;