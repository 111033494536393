import { useTranslations }   from 'app/ui/hooks/app/use-translation';
import useDocumentImageDeleteBase from './use-document-image-delete-base';


export const useDocumentImagesDelete = () => {
  const t = useTranslations();

  const deleteImages = useDocumentImageDeleteBase({
    title: t("images delete msgbox, title"),
    message: t("images delete msgbox, message"),
  }, {
    startMsg: t("images, deleting"),
    stopMsg:  t("images, deleted"),
  });
  

  return deleteImages;
}


export default useDocumentImagesDelete;
