import { MenuConfig } from 'lego-v2/menu/config';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useItems from './__use-items';


const useCellImagesMenu = () => {
  const items = useItems();


  const getConfig = (cellAddr: ContentTypes.CellAddr): MenuConfig => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getUploadImageFromDisk(cellAddr),
            items.getUploadImageFromCamera(cellAddr),
          ],
        },
      ]
    }

    return config;
  }


  const hook = (cellAddr: ContentTypes.CellAddr): MenuConfig => {
    const config = getConfig(cellAddr);
    return config;
  }

  return hook;
}


export default useCellImagesMenu;