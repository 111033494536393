import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useMarkerEdit from 'app/ui-v2/editor-markers/hooks/use-marker-edit';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = () => {
  const docState = useDocState();

  const {
    setContent,
    setRepoMarkers,
    setGalleryMarkersSelected,
    setHeaderMarkers,
  } = useEditorStatesSetters();

  const editMarker__ = useMarkerEdit();

  const {
    hideMenu
  } = useContextMenu();

  const createMarker = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    const newMarkerAddr = docState.repoMarkers.addMarker();
    docState.saveUndo();
    setRepoMarkers();

    docState.galleryMarkersSelected.reset();
    docState.galleryMarkersSelected.select(newMarkerAddr);
    setGalleryMarkersSelected();

    hideMenu();
  }

  const duplicateMarker = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    const newMarkerAddr = docState.repoMarkers.duplicateMarker(markerAddr);
    docState.saveUndo();
    setRepoMarkers();

    docState.galleryMarkersSelected.reset();
    docState.galleryMarkersSelected.select(newMarkerAddr);
    setGalleryMarkersSelected();

    hideMenu();
  }

  const editMarker = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    editMarker__(markerAddr);
    hideMenu();
  }

  const deleteMarker = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    docState.docMarker_delete(markerAddr);
    docState.saveUndo();

    setContent();
    setRepoMarkers();
    setHeaderMarkers();

    docState.galleryMarkersSelected.reset();
    setGalleryMarkersSelected();

    hideMenu();
  }


  return {
    createMarker,
    duplicateMarker,
    editMarker,
    deleteMarker,
  };
}


export default useFunctions;