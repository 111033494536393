import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = () => {
  const docState = useDocState();

  const {
    setContent,
    setContentCellMarkersSelected,
  } = useEditorStatesSetters();
  
  const removeMarker = (event: KeyboardEvent) => {
    const markersSelected = docState.contentCellMarkersSelected.getSelected();
    const markersKeys = Object.keys(markersSelected);
    if ( ! markersKeys.length ) {
      return false;
    }

    const markersAddrs = markersKeys.map(markerKey => ContentTools.markerKeyToAddr(markerKey));
    markersAddrs.map((markerAddr) => {
      docState.content.cellMarkers_removeMarker(markerAddr);
    });

    docState.saveUndo();
    setContent();

    docState.contentCellMarkersSelected.reset();
    setContentCellMarkersSelected();

    return true;
  }

  return {
    removeMarker,
  }
}

export default useFunctions;