import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useContextElement from 'app/ui-v2/editor-instruction/hooks/use-context-element';
import ColumnCreatePanelComponent from 'app/ui-v2/editor-instruction/components/column-create-panel';

import useContentSelectedReset from '../../use-selected-reset';
import useColumnsAutoAdjust from '../../columns/use-columns-auto-adjust';

import useColumnSelectedSet from '../use-column-selected-set';
import useColumnVisibleSet  from '../use-column-visible-set';
import useColumnPropsPanel from '../use-column-props-panel';
import useTablePropsPanel from '../../table/use-table-props-panel';


const useFunctions = () => {
  const t = useTranslations();
  const docState = useDocState();
  const columnsAutoAdjust = useColumnsAutoAdjust();

  const {
    hideMenu
  } = useContextMenu();

  const {
    showElement,
  }  = useContextElement();

  const setColumnVisible = useColumnVisibleSet();
  const contentResetSelected = useContentSelectedReset();
  const setColumnSelected = useColumnSelectedSet();
  const showColumnPropsPanel = useColumnPropsPanel();
  const showTablePropsPanel = useTablePropsPanel();

  const addColumn = (srcColumnAddr: Types.ColumnAddr, event: React.PointerEvent) => {
    const onDone = (newColumnAddr: Types.ColumnAddr) => {
      setColumnSelected({columnAddr: newColumnAddr});
    }

    showElement({
      Element: ColumnCreatePanelComponent, 
      ElementProps: {
        columnAddr: srcColumnAddr,
        onDone
      },
      event
    });

    hideMenu();
  }

  const deleteColumn = (columnAddr: Types.ColumnAddr) => {
    docState.content.deleteColumn(columnAddr);
    columnsAutoAdjust({ debounced: false });
    docState.saveUndo();

    contentResetSelected();
    hideMenu();
  }
  
  const hideColumn = (columnAddr: Types.ColumnAddr) => {
    setColumnVisible(columnAddr, false);
    hideMenu();
  }

  const showColumnProperties = () => {
    showColumnPropsPanel();
    hideMenu();
  }

  const showTableProperties = () => {
    showTablePropsPanel();
    hideMenu();
  }

  return {
    addColumn,
    deleteColumn,
    hideColumn,
    showColumnProperties,
    showTableProperties,
  }
}


export default useFunctions;