import { MenuConfig }   from 'lego-v2/menu/config';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState } from "app/ui/contexts/document";
import useItems from './__use-items';


const useColumnMenu = () => {
  const document = useDocState();
  const items = useItems();

  
  const getConfig = (columnAddr: Types.ColumnAddr) => {
    const columnType = document.content.getColumnType(columnAddr);
    const isIdxColumn = (columnType === Types.ColumnType.INDEX);

    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getColumnAdd(columnAddr),
          ],
        },        
        {
          items: [
            items.getProperties(),
            items.getColumnHide(columnAddr),
          ],
        },    
        {
          skip: isIdxColumn,
          items: [
            items.getColumnDelete(columnAddr),
          ],
        },
      ]
    }

    return config;
  }


  const hook = (columnAddr: Types.ColumnAddr) => {
    const config = getConfig(columnAddr);
    return config;
  }

  return hook;
}


export default useColumnMenu;