import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';

import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { RepoImagesTools } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { GalleryImageSelectedState as State } from 'app/arch/editor-instruction/document/states/sessions/gallery-image-selected';


/**
 * Atom
 */

export const state = atom<State.State>({
  key: "ei_galleryImage",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const isSelected = selectorFamily({
  key: "ei_galleryImage__isSelected",
  get: (image: RepoImagesTypes.Image) => ({ get }) => {
    const stateObj = get(state);
    const selected = State.isSelected(stateObj, RepoImagesTools.getImageKey(image));
    return selected;
  }
});

export const selected = selector({
  key: "ei_galleryImage__selected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selected = State.getSelected(stateObj);

    return selected;
  }
});
  