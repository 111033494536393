import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset     from '../../use-selected-reset';
import { HeaderRowsTools } from 'app/arch/editor-instruction/document/states/persistent/header-rows';


const useFunctions = () => {
  const docState = useDocState();
  const customRowsResetSelected = useSelectedReset();

  const {
    setHeaderRows,
    setHeaderRowsSession,
    setCustomRowsRowsSelected,
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();


  const clearRows = () => {
    const rows = docState.customRowsRowsSelected.getSelected();
    const rowsKeys = Object.keys(rows);
    rowsKeys.forEach(rowKey => {
      const selected = rows[rowKey];
      if ( ! selected) {
        return;
      }

      const rowAddr = HeaderRowsTools.pageRowKeyToAddr(rowKey);
      docState.headerRows.clearColumn(rowAddr);
    });

    docState.saveUndo();
    setHeaderRows();

    hideMenu();
  }


  const deleteRows = () => {
    const rows = docState.customRowsRowsSelected.getSelected();
    const rowsKeys = Object.keys(rows);
    rowsKeys.forEach(rowKey => {
      const selected = rows[rowKey];
      if ( ! selected) {
        return;
      }

      const rowAddr = HeaderRowsTools.pageRowKeyToAddr(rowKey);
      docState.headerRows.deleteRow(rowAddr);
    });

    customRowsResetSelected({skipStateUpdate: true});

    docState.saveUndo();
    setHeaderRows();
    setHeaderRowsSession();
    setCustomRowsRowsSelected();

    hideMenu();
  }


  return {
    clearRows,
    deleteRows,
  };
}


export default useFunctions;