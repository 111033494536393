import { useEffect } from "react";

import DocumentStatesComponent from "app/ui-v2/editor-instruction/__document/components/document-states";
import useActionsReset from "app/ui-v2/app/__modules/info/components/actions/hooks/use-actions-reset";

import { useIsPrintout } from "../use-is-printout";

// TODO
// Move these comps out of views
import LayerControlsComponent from './views/layer-controls';
import MenuTopRightComponent  from './views/controls/menu-top-right';

import ViewsComponent   from "./views"
import TopMenuComponent from "./top-menu";

import { TopMenuWrapper }       from "./styles";
import { MenuTopRightWrapper }  from "./styles";
import { LayerControlsWrapper } from "./styles";


interface Props {
}


export const DocumentComponent: React.FC<Props> = (props: Props) => {
  const resetActions = useActionsReset();

  useEffect(() => {
    return () => resetActions();
  });

  const isPrintout = useIsPrintout();
  
  const renderPanels = () => (
    <>
      <TopMenuWrapper>
        <TopMenuComponent />
      </TopMenuWrapper>

      <MenuTopRightWrapper>
        <MenuTopRightComponent />
      </MenuTopRightWrapper>

      <LayerControlsWrapper>
        <LayerControlsComponent />
      </LayerControlsWrapper>
    </>
  );


  return (
    <>
      { ! isPrintout && renderPanels() }
      <DocumentStatesComponent>
        <ViewsComponent />
      </DocumentStatesComponent>
    </>
  );
}
