import { PanelControlsItemSectionItemType } from 'lego/components/panel-controls/config';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import PanelItemGenerator  from 'app/ui-v2/editor-instruction/panel-items/generator';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useFunctions from './__use-functions';
import useStates from './__use-states';


const useItems = () => {
  const t = useTranslations();
  const fn = useFunctions();
  const states = useStates();


  const getEnable = () => 
    PanelItemGenerator.getToggleOnOff({
      title: t('enabled'),
      selected: states.sectionsEnabled,
      onClick: fn.setSectionsEnabled
    });

  const getIndexing = () => ({
    title: t('section, indexing'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    // skip: ! states.sectionsEnabled,
    buttons: [
      {
        text: t('indexing, local'),
        value: ContentTypes.SectionsIndexing.LOCAL,
        selected: states.sectionsIndexing === ContentTypes.SectionsIndexing.LOCAL,
      },
      {
        text: t('indexing, global'),
        value: ContentTypes.SectionsIndexing.GLOBAL,
        selected: states.sectionsIndexing === ContentTypes.SectionsIndexing.GLOBAL,
      },
    ],
    onClick: fn.setSectionsIndexing
  });
    
  const getSectionNamePadding = () =>
    PanelItemGenerator.getPadding({
      css: states.sectionsNamesCSS,
      onChange: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: true
        });
      },
      onChangeDone: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: false
        });
      },
    });

  const getSectionNamAlignHorizontal = () => 
    PanelItemGenerator.getAlignHorizontal({
      css: states.sectionsNamesCSS,
      onClick: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({ 
          cssUpdate,
          skipUndoSave: false 
        });
      },
      singleItem: true,
    });

  const getSectionNameFontSize = () => 
    PanelItemGenerator.getFontSize({
      css: states.sectionsNamesCSS,
      onChange: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: true
        });
      },
      onChangeDone: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: false
        });
      },
    });

  const getSectionNameFontWeight = () =>
    PanelItemGenerator.getFontWeight({
      css: states.sectionsNamesCSS,
      onChange: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: true
        });
      },
      onChangeDone: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: false
        });
      },
    });
    

  const getSectionNameFontVariant = () => 
    PanelItemGenerator.getFontCapslock({
      css: states.sectionsNamesCSS,
      onClick: (cssUpdate: React.CSSProperties) => {
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: false
        });
      }
    });


  return {
    getEnable,
    getIndexing,
    getSectionNamePadding,
    getSectionNamAlignHorizontal,
    getSectionNameFontSize,
    getSectionNameFontWeight,
    getSectionNameFontVariant,
  }
}


export default useItems;