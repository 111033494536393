import React from 'react';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ProgressModalViewComponent from 'lego-v2/progress-modal/progress-modal-view';
import AnimSquareSplit from 'libs/anim/squares-split';


interface Props {
}


export const ProgressViewComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  return (
    <ProgressModalViewComponent 
      text={t("document, pdf preview, do")}
      Loader={AnimSquareSplit}
    />
  );
}

