import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import { useDocState }  from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { PageFormatType, PageOrientationType } from 'app/arch/types/types';
import useCustomFieldsColumnsAutoAdjust from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/columns/use-columns-auto-adjust';
import useContentColumnsAutoAdjust      from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/columns/use-columns-auto-adjust';
import useReleaselogsColumnsAutoAdjust  from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/columns/use-releaselogs-columns-auto-adjust';


const useFunctions = () => {
  const docState = useDocState();
  const docReleaselogsColumnsAutoAdjust = useReleaselogsColumnsAutoAdjust()
  const docContent2ColumnsAutoAdjust    = useContentColumnsAutoAdjust();
  const contentCustomFieldsAutoAdjust   = useCustomFieldsColumnsAutoAdjust();

  const autoAdjustColumns = () => {
    docReleaselogsColumnsAutoAdjust();
    docContent2ColumnsAutoAdjust();
    contentCustomFieldsAutoAdjust();
  }

  const {
    setContentView,
    setContent,
    setViewsCommon,
  } = useEditorStatesSetters();

  const setMarkersVisible = (visible: TypesShared.PageItemVisibility) => {
    docState.contentView.updateHeaderMarkers({visible});
    docState.saveUndo();
    setContentView();
  }

  const setCustomFieldsVisible = (visible: TypesShared.PageItemVisibility) => {
    docState.contentView.updateHeaderFields({visible});
    docState.saveUndo();
    setContentView();
  }

  const setCutomRowsVisible = (visible: TypesShared.PageItemVisibility) => {
    docState.contentView.updateHeaderInternal({visible});
    docState.saveUndo();
    setContentView();
  }

  const setMetaVisible = (visible: TypesShared.PageItemVisibility) => {
    docState.contentView.updateHeaderMeta({visible});
    docState.saveUndo();
    setContentView();
  }

  const setSectionsEnabled = (enabled: boolean) => {
    const update = { enabled };

    docState.content.updateSectionsConfig(update);
    docState.saveUndo();
    setContent();
  }

  const setPageOrientation = (orientation: PageOrientationType) => {
    docState.viewsCommon.updatePageLayout({orientation});
    setViewsCommon();
    autoAdjustColumns();
    docState.saveUndo();
  }

  const setPageFormat = (format: PageFormatType) => {
    docState.viewsCommon.updatePageLayout({format});
    setViewsCommon();
    autoAdjustColumns();
    docState.saveUndo();
  }

  return {
    setMarkersVisible,
    setCustomFieldsVisible,
    setCutomRowsVisible,
    setMetaVisible,
    setSectionsEnabled,
    setPageOrientation,
    setPageFormat,
  }
}


export default useFunctions;