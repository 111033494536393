import styled from "styled-components";
import MarkerTextBase from "../styles/marker-text";
import { RepoMarkersTypes } from "app/arch/editor-instruction/document/states/persistent/repo-markers";

interface Props {
  rotate?: RepoMarkersTypes.LabelRotation;
}

export const MarkerText = styled(MarkerTextBase)<Props>`
  white-space: nowrap;
  transform-origin: center;
  transform: ${props => 
    [
      RepoMarkersTypes.LabelRotation.ROTATE_180, 
      RepoMarkersTypes.LabelRotation.ROTATE_270
    ].includes(props.rotate ?? RepoMarkersTypes.LabelRotation.ROTATE_0) ? 
    'rotate(180deg)' : 
    'rotate(0deg)'
  };

  writing-mode: ${props => 
    [
      RepoMarkersTypes.LabelRotation.ROTATE_90, 
      RepoMarkersTypes.LabelRotation.ROTATE_270
    ].includes(props.rotate ?? RepoMarkersTypes.LabelRotation.ROTATE_0) ? 
    'vertical-rl' : 
    'horizontal-tb'
  };

  padding: ${props => 
    [
      RepoMarkersTypes.LabelRotation.ROTATE_0, 
      RepoMarkersTypes.LabelRotation.ROTATE_180
    ].includes(props.rotate ?? RepoMarkersTypes.LabelRotation.ROTATE_0) ? 
    `${props.theme.defs.padding.medium} ${props.theme.defs.padding.large}` : 
    `${props.theme.defs.padding.large} ${props.theme.defs.padding.medium}` 
  };
`;
