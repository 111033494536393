import React from "react";

export interface DraggerProps {
  dndContext: string;
  onDragStart?: () => void;
  onDragMoveFirst?: () => void;
  onDragEnd?: () => void; // not used by anything for now

  draggedView?: React.ReactNode;

  Wrapper?: React.ElementType;
  children: React.ReactNode;

  debug?: boolean;
}


export enum State {
  SETUP   = 'setup',
  STARTED = 'started',
  STOPING = 'stoping',
  STOPING_CONTEXT = 'stoping-context',
  STOPPED = 'stopped',
}
