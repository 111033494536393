import React from 'react';
import { MutableRefObject } from 'react';

import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import CellTextEditorComponent from './cell-text-editor';
import CellContextMenuCancelerComponent from './cell-context-menu-canceler';


interface Props  {
  editorTextRef: MutableRefObject<HTMLDivElement | null>;
  pageCellAddr: HeaderRowsTypes.PageCellAddr;
}


export const CellTextComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr,
    editorTextRef,
  } = props;


  return (
    <CellContextMenuCancelerComponent>
      <CellTextEditorComponent
        editorTextRef={editorTextRef}
        pageCellAddr={pageCellAddr}
      />
    </CellContextMenuCancelerComponent>
  );
}
