import styled, { keyframes } from "styled-components";

// Keyframes
const l14_0 = keyframes`
  0%, 24.99%  {transform: rotate(0deg);}
  25%, 49.99% {transform: rotate(90deg);}
  50%, 74.99% {transform: rotate(180deg);}
  75%, 100%   {transform: rotate(270deg);}
`;

const l14 = keyframes`
  100% {transform: translate(-5px, -5px);}
`;

// Styled Component
const AnimCirclePie = styled.div`
  width: 20px;
  aspect-ratio: 1;
  color: ${props => props.theme.defs.accentColor.primary};
  background: conic-gradient(currentColor 0 270deg, #0000 0);
  border-radius: 50%;
  animation: ${l14_0} 4s infinite linear;

  &::before {
    content: "";
    display: block;
    height: 50%;
    width: 50%;
    border-top-left-radius: 100px;
    background: currentColor;
    animation: ${l14} 0.5s infinite alternate;
  }
`;

export default AnimCirclePie;
