import React from 'react';

import ContextPanelWindowComponent from './context-panel-window';
import ContextPanelContentComponent from './context-panel-content';
import ContextPanelWindowAdjusterComponent from './context-panel-window-adjuster';


interface Props {
}


export const ContextPanelComponent: React.FC<Props> = (props: Props) => {
  return (
    <ContextPanelWindowComponent>
      <ContextPanelWindowAdjusterComponent>
        <ContextPanelContentComponent />
      </ContextPanelWindowAdjusterComponent>
    </ContextPanelWindowComponent>
  );
}
  
