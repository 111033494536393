import * as Types from './types';


const __colors = ['#54BF54', '#4D7CFF', '#763D81'];


const __getPlanFree = (): Types.PricePlan => ({
  title: 'product basic, name',
  description: 'product basic, description',
  color: __colors[0],
  features: [
    {
      name: 'product feature, 1 editor',
    },
    {
      name: 'product feature, 3 documents',
    },
    {
      name: 'product feature, 20 MB disk space',
    },
    {
      name: 'product feature, generate pdf',
    },
    {
      name: 'product feature, export to doc',
    },
    {
      name: 'product feature, export to xls',
    },
  ],
  price: {
    lookup: {
      [Types.BillingPeriod.DAY]:   'free',
      [Types.BillingPeriod.MONTH]: 'free',
      [Types.BillingPeriod.YEAR]:  'free',
    },    
  }
});


const __getPlanEco = (): Types.PricePlan => ({
  title: 'product eco, name',
  description: 'product eco, description',
  color: __colors[1],
  features: [
    {
      name: 'product feature, 1 editor',
    },
    {
      name: 'product feature, unlimited documents',
    },
    {
      name: 'product feature, 5 viewers',
    },
    {
      name: 'product feature, 5 GB disk space',
    },
    {
      name: 'product feature, generate pdf',
    },
    {
      name: 'product feature, export to doc',
    },
    {
      name: 'product feature, export to xls',
    },
    {
      name: 'product feature, document template',
    },
  ],
  price: {
    lookup: {
      [Types.BillingPeriod.DAY]:   'eco_daily',
      [Types.BillingPeriod.MONTH]: 'eco_monthly_v2',
      [Types.BillingPeriod.YEAR]:  'eco_yearly_v2',
    },
    description: 'product eco, price description',
  }
});


const __getPlanTeam = (): Types.PricePlan => ({
  title: 'product team, name',
  description: 'product team, description',
  color: __colors[2],
  features: [
    {
      name: 'product feature, 5 editors',
    },
    {
      name: 'product feature, unlimited documents',
    },
    {
      name: 'product feature, unlimited viewers',
    },
    {
      name: 'product feature, 100 GB disk space',
    },
    {
      name: 'product feature, generate pdf',
    },
    {
      name: 'product feature, export to doc',
    },
    {
      name: 'product feature, export to xls',
    },
    {
      name: 'product feature, document template',
    },
    {
      name: 'product feature, shared repository',
    },
  ],
  price: {
    lookup: {
      [Types.BillingPeriod.DAY]:   'team_daily',
      [Types.BillingPeriod.MONTH]: 'team_monthly_v2',
      [Types.BillingPeriod.YEAR]:  'team_yearly_v2',
    },
    description: 'product team, price description',
  }
});


export const getPlans = (): Types.PricePlans => ({
  [Types.PlanType.FREE]: __getPlanFree(),
  [Types.PlanType.ECO ]: __getPlanEco(),
  [Types.PlanType.TEAM]: __getPlanTeam(),
});

