import React from 'react';
import useCustomRowMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-rows/row/use-custom-row-menu';
import useCustomRowsMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-rows/row/use-custom-rows-menu';
import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useItemContextMenu from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-context-menu';
import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { useDocState } from 'app/ui/contexts/document';
import { HeaderCellProps } from '../types';

import { MainWrapper } from './styles';


interface Props extends HeaderCellProps {
}


export const HeaderCellContextMenuComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageRowAddr,
    children
  } = props;

  const docState = useDocState();
  const getConfigRow = useCustomRowMenu();
  const getConfigRows = useCustomRowsMenu();
  const resetAllSelected = useAllSelectedReset();

  const {
    setCustomRowsRowsSelected,
  } = useEditorStatesSetters()

  type Addr = HeaderRowsTypes.PageRowAddr;

  const handleContextMenu_ = useItemContextMenu<Addr>({
    isSelected: (addr: Addr) => docState.customRowsRowsSelected.isSelected(addr),
    isMultiselected: () => docState.customRowsRowsSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    resetOtherSelected: () => resetAllSelected({skipCustomRowsReset: true}),
    select: (addr: Addr) => {
      docState.customRowsRowsSelected.select(addr);
      setCustomRowsRowsSelected();
    },
    getConfigItems: () => getConfigRows(),
    getConfigItem: (addr:Addr) => getConfigRow(addr)
  });

  const handleContextMenu = (event: React.MouseEvent) => {
    handleContextMenu_(event, pageRowAddr);
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
