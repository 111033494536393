import { MenuConfig } from 'lego-v2/menu/config';
import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import useItems from './__use-items';


const useImageMenu = () => {
  const items = useItems();

  const getConfig = (imageId: RepoImagesTypes.ImageId) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getImageDelete(imageId),
          ],
        },
      ]
    }

    return config;
  }

  const hook = (imageId: RepoImagesTypes.ImageId) => {
    const config = getConfig(imageId);
    return config;
  }

  return hook;
}


export default useImageMenu;