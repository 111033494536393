import { useRef } from 'react';
import { KeyBinding } from 'lego/components/key-bindings/arch/types';
import useKeyBindingsBase from 'lego/components/key-bindings/hooks/use-key-bindings';

import { UUID } from 'app/arch/types';


const useKeyBindingsAdd = (bindingsDefs: KeyBinding[]) => {
  const bindingIdsRef = useRef<UUID[]>([]);

  const {
    addKeyDownBinding,
    removeKeyDownBinding,
  } = useKeyBindingsBase();

  const addBindings = () => {
    bindingsDefs.forEach(binding => {
      const bindingIds = bindingIdsRef.current;
      const tmpId = addKeyDownBinding(binding);
      bindingIds.push(tmpId);
    });
  }

  const removeBindings = () => {
    const bindingIds = bindingIdsRef.current;
      bindingIds.forEach(id => removeKeyDownBinding(id));
      __resetBindingIds();
  }

  const __resetBindingIds = () => {
    bindingIdsRef.current = [];
  }

  return {
    addBindings,
    removeBindings
  }
}

export default useKeyBindingsAdd;