import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useDocMetaPropsPanel from "../use-doc-meta-props-panel";


const useFunctions = () => {
  const {
    hideMenu
  } = useContextMenu();
  
  const showContextPanel = useDocMetaPropsPanel();

  const showPropsPanel = () => {
    showContextPanel()
    hideMenu();
  }

  return {
    showPropsPanel,
  };
}


export default useFunctions;