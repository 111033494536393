import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';
import useKeyBindingsAdd from 'lego/components/key-bindings/hooks/use-key-bindings-add';
import useFunctions from './__use-functions';


/**
 *  Image editor key bindings
 */

const useKeyBindings = () => {
  const fns = useFunctions();

  const {
    addBindings,
    removeBindings
  } = useKeyBindingsAdd([
    {
      check: (event: any) => {
        return [
          'Delete',
          'Backspace'
        ].includes(event.key);
      },
      callback: fns.deleteSelectedWidgets,
      priority: KeyBindingPriority.EDITOR_IMAGE
    },
    {
      check: (event: any) => event.key === 'Escape',
      callback: fns.resetSelectedWidgets,
      priority: KeyBindingPriority.EDITOR_IMAGE
    },
  ]);

  return {
    addBindings,
    removeBindings
  }
}

export default useKeyBindings;
