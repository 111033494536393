import useContextPanel from 'app/ui-v2/editor-instruction/hooks/use-context-panel';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ContextPanelContentComponent from 'app/ui-v2/editor-instruction/components/context-panel-content';
import usePanelConfig from './use-panel-config/use-panel-config';


const useDocMetaPropsPanel = () => {
  const t = useTranslations();

  const {
    showPanel,
    hidePanel,
  }= useContextPanel();


  const hook = () => {
    showPanel({
      title: t("page header, main info"),
      content: <__PanelComponent />
    });
  }

  return hook;
}


interface __Props {}
 
const __PanelComponent: React.FC<__Props> = (props: __Props) => {
  const getConfig = usePanelConfig();
  return <ContextPanelContentComponent config={getConfig()} />;
}


export default useDocMetaPropsPanel;