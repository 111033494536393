import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDocContentSelectedReset       from './doc-content/use-selected-reset';
import useDocCustomFieldsSelectedReset  from './doc-custom-fields/use-selected-reset';
import useDocCustomRowsSelectedReset    from './doc-custom-rows';


const useSelectedReset = () => {
  const docState = useDocState();

  const {
    setGalleryImagesSelected,
    setGalleryMarkersSelected,

    setHeaderMarkersSelected,
  } = useEditorStatesSetters();

  const docContentSelectedReset = useDocContentSelectedReset();
  
  const docCustomFieldsSelectedReset = useDocCustomFieldsSelectedReset();
  const docCustomRowsSelectedReset = useDocCustomRowsSelectedReset();


  const hook = (props?: {
    skipStateUpdate?: boolean,
    
    skipContentColumnReset?: boolean,
    skipContentRowReset?: boolean,
    skipContentCellReset?: boolean,
    skipContentCellImagesReset?: boolean,
    skipContentCellMarkersReset?: boolean,

    skipCustomFieldsReset?: boolean,
    skipCustomRowsReset?: boolean,

    skipGalleryImagesReset?: boolean,
    skipGalleryMarkersReset?: boolean,

    skipHeaderMarkersReset?: boolean,
  }) => {
    const skipCustomFieldsReset = props?.skipCustomFieldsReset ?? false;
    const skipCustomRowsReset = props?.skipCustomRowsReset ?? false;
    const skipGalleryImagesReset = props?.skipGalleryImagesReset ?? false;
    const skipGalleryMarkersReset = props?.skipGalleryMarkersReset ?? false;
    const skipHeaderMarkersReset = props?.skipHeaderMarkersReset ?? false;

    docContentSelectedReset(props);
    
    if ( ! skipCustomFieldsReset ) {
      docCustomFieldsSelectedReset(props);
    }

    if ( ! skipCustomRowsReset ) {
      docCustomRowsSelectedReset(props);
    }

    if ( ! skipGalleryImagesReset ) {
      docState.galleryImageSelected.reset();
      setGalleryImagesSelected();
    }

    if ( ! skipGalleryMarkersReset ) {
      docState.galleryMarkersSelected.reset();
      setGalleryMarkersSelected();
    }

    if ( ! skipHeaderMarkersReset ) {
      docState.headerMarkersSelected.reset();
      setHeaderMarkersSelected();
    }
  }

  return hook;
}


export default useSelectedReset;
