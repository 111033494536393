import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { useDocState } from 'app/ui/contexts/document';
import MarkerViewComponent from 'app/ui-v2/editor-instruction/hls/markers/marker-view';

import { MainWrapper } from './styles';
import { Marker } from './styles';


interface Props {
  markerAddr: HeaderMarkersTypes.MarkerAddr;
}


export const MarkerDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr, 
  } = props;

  const docState = useDocState();
  const markerProps = docState.headerMarkers.getMarkerProps(markerAddr);

  return (
    <MainWrapper>
      <MarkerViewComponent 
        markerAddr={markerProps.repoMarkerAddr} 
        selected={false}
        Marker={Marker}
      />
    </MainWrapper>
  );
}
