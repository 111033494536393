import styled from "styled-components";
import ItemSelecteable from "./item-selecteable";


export const ItemMultiSelecteable = styled(ItemSelecteable)`
  border-color: ${props => props.selected ? props.theme.itemSelected.border.color : 'transparent'};
  background:   ${props => props.selected ? 'white' : 'transparent'};

  outline-width: ${props => props.theme.defs.border.width.xsmall};
  outline-color: ${props => props.theme.defs.accentColor.lightest};;
  outline-style: ${props => props.selected ? 'solid' : 'none'};

  &:active:not(:disabled) {
    background: white;
  }

  &:hover {
    /* outline-style: solid; */
    /* outline-color: ${props => props.selected ? props.theme.defs.accentColor.lightest : 'white'}; */
    background: white;
  }
`;


export default ItemMultiSelecteable;