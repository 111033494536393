import styled from 'styled-components';
import { FlexEditApp } from 'app/ui-v2/hls/branding/flex-edit-name/styles';


export const FlexEditWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.normal};
  align-items: flex-end;
  justify-content: center;
  /* background-color: blue;; */
`;

export const FlexEditText = styled(FlexEditApp)`
  color: #444;
  /* color: red; */
`;