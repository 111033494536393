import { MenuItemType } from 'lego-v2/menu';
import { Icon } from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useFunctions from './__use-functions';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();

  const getRowsClear = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('rows, clear'),
      iconComponent: Icon.Clear,
      onClick: (event: React.MouseEvent) => fns.clearRows(),
    }
  });

  const getRowsDelete = () => ({
    type: MenuItemType.BUTTON,
    data: {
      variant: 'delete',
      title: t('rows, delete'),
      iconComponent: Icon.Delete,
      onClick: (event: React.MouseEvent) => fns.deleteRows(),
    }
  });


  return {
    getRowsClear,
    getRowsDelete,
  }
}


export default useItems;