import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useFunctions = () => {
  const docState = useDocState();

  const {
    setViewsCommon,
  } = useEditorStatesSetters();


  const handleTableHeaderCSSUpdate = (cssUpdate: React.CSSProperties) => {
    docState.viewsCommon.updateTableHeaderCSS (cssUpdate);
    setViewsCommon();
    docState.saveUndo();
  }

  const handleTableHeaderBackground = (background: string) => {
    docState.viewsCommon.updateTableHeaderCSS ({background});
    setViewsCommon();
    docState.saveUndo();
  };

  const handleTableHeaderColor = (color: string) => {
    docState.viewsCommon.updateTableHeaderCSS ({color});
    setViewsCommon();
    docState.saveUndo();
  };
  
  const handleTableHeaderFontSizeChange = (fontSize: number) => {
    docState.viewsCommon.updateTableHeaderCSS ({
      fontSize: `${fontSize}px`
    });
    setViewsCommon();
    docState.saveUndo();
  }

  const handleTableHeaderFontSizeChangeDone = (fontSize: number) => {
    handleTableHeaderFontSizeChange(fontSize);
    docState.saveUndo();
  }
  
  const handleTableHeaderFontWeightChange = (fontWeight: number) => {
    docState.viewsCommon.updateTableHeaderCSS ({
      fontWeight: `${fontWeight * 100}`
    });
    setViewsCommon();
    docState.saveUndo();
  }

  const handleTableHeaderFontWeightChangeDone = (fontWeight: number) => {
    handleTableHeaderFontWeightChange(fontWeight);
    docState.saveUndo();
  }

  const handleTableHeaderFontPaddingChange = (padding: number) => {
    docState.viewsCommon.updateTableHeaderCSS ({
      padding: `${padding}px`
    });
    setViewsCommon();
    docState.saveUndo();
  }

  const handleTableHeaderFontPaddingChangeDone = (padding: number) => {
    handleTableHeaderFontPaddingChange(padding);
    docState.saveUndo();
  }

  return {
    handleTableHeaderCSSUpdate,
    handleTableHeaderBackground,
    handleTableHeaderColor,
    handleTableHeaderFontSizeChange,
    handleTableHeaderFontSizeChangeDone,
    handleTableHeaderFontWeightChange,
    handleTableHeaderFontWeightChangeDone,
    handleTableHeaderFontPaddingChange,
    handleTableHeaderFontPaddingChangeDone,
  }
}


export default useFunctions;