import React from 'react';

import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';

import MarkerViewComponent        from './marker-view';
import MarkerDropComponent        from './marker-drop';
import MarkerDraggerComponent     from './marker-dragger';
import MarkerContextMenuComponent from './marker-context-menu';
import MarkerEditComponent        from './marker-edit';
import MarkerSelectComponent      from './marker-select';
import MarkerSelectedComponent    from './marker-selected';


interface Props {
  markerAddr: HeaderMarkersTypes.MarkerAddr;
}


export const MarkerComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr
  } = props;

  
  return (
    <MarkerSelectComponent markerAddr={markerAddr}>
      <MarkerEditComponent markerAddr={markerAddr} >
        <MarkerContextMenuComponent markerAddr={markerAddr} >
          <MarkerDropComponent markerAddr={markerAddr} >
            <MarkerDraggerComponent markerAddr={markerAddr} >
              <MarkerSelectedComponent markerAddr={markerAddr} >
                <MarkerViewComponent markerAddr={markerAddr} />
              </MarkerSelectedComponent>
            </MarkerDraggerComponent>
          </MarkerDropComponent>
        </MarkerContextMenuComponent>
      </MarkerEditComponent>
    </MarkerSelectComponent>      
  );
}
