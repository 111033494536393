import produce from 'immer';
import * as State from './state';
import * as Types from './types';


export class ContextPanelSession {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */

  updateWindow(update: Types.WindowUpdate) {
    this._state = produce(this._state, draft => {
      const window = State.getWindow(draft);
      Object.assign(window, update);
    });
  }


  /**
   * Getters
   */
  
  getWindow() {
    return State.getWindow(this._state);
  }
}
