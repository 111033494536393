import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useColumnsAutoAdjust from '../../columns/use-columns-auto-adjust';


const useFunctions = () => {
  const docState = useDocState();

  const {
    setContentColumnsSelected,
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();

  const document = useDocState();
  const columnsAutoAdjust = useColumnsAutoAdjust();


  const hideColumns = () => {
    const columns = docState.contentColumnsSelected.getSelected();
    const columnsKeys = Object.keys(columns);
    columnsKeys.forEach(columnKey => {
      const selected = columns[columnKey];
      if ( ! selected) {
        return;
      }

      const columnAddr = ContentTools.columnKeyToAddr(columnKey);
      const visible = false;
      document.content.updateColumn(columnAddr, { visible });
    });

    columnsAutoAdjust();
    document.saveUndo();
    hideMenu();
  }

  const deleteColumns = () => {
    const columns = docState.contentColumnsSelected.getSelected();
    const columnsKeys = Object.keys(columns);
    columnsKeys.forEach(columnKey => {
      const selected = columns[columnKey];
      if ( ! selected) {
        return;
      }

      const columnAddr = ContentTools.columnKeyToAddr(columnKey);
      docState.content.deleteColumn(columnAddr);
    });

    columnsAutoAdjust({ debounced: false });
    document.saveUndo();
    
    docState.contentColumnsSelected.reset();
    setContentColumnsSelected();

    hideMenu();
  }


  return {
    hideColumns,
    deleteColumns,
  }
}


export default useFunctions;