import { PanelControlsConfig } from 'lego/components/panel-controls/config';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useItems from './__use-items';
import useStates from './__use-states';


const usePanelConfig = () => {
  const t = useTranslations();
  const items = useItems();

  const {
    visibleNone,
  } = useStates();

  const getConfig = (): PanelControlsConfig => {
    const config: PanelControlsConfig = {
      items: [
        {
          sections: [
            {
              subsections: [
                {
                  items: [
                    items.getVisible(),
                  ]
                },
                {
                  skip: visibleNone,
                  items: [
                    items.getPadding(),
                    items.getAlignHorizontal(),
                  ]
                },
                {
                  skip: visibleNone,
                  items: [
                    items.getFontSize(),
                    items.getFontWeight(),
                    items.getFontVariant(),
                  ]
                }              
              ],
            }
          ]
        },
      ]
    } // config

    return config;
  }

  return getConfig;
}


export default usePanelConfig;