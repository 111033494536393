import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = () => {
  const docState = useDocState();

  const {
    setRepoMarkers,
    setGalleryMarkersSelected,
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();

  const createMarker = () => {
    const newMarkerAddr = docState.repoMarkers.addMarker();
    docState.saveUndo();
    setRepoMarkers();

    docState.galleryMarkersSelected.reset();
    docState.galleryMarkersSelected.select(newMarkerAddr);
    setGalleryMarkersSelected();

    hideMenu();
  }

  return {
    createMarker,
  };
}


export default useFunctions;