import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import HLC_MarkerViewComponent from 'app/ui-v2/editor-instruction/hls/markers/marker-view';
import { Marker } from './styles';


interface Props {
  markerAddr: ContentTypes.MarkerAddr;
}


export const MarkerViewComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
  } = props;

  const document = useDocState();
  const markerProps = document.content.cellMarkers_getMarkerProps(markerAddr);
  
  return (
    <HLC_MarkerViewComponent 
      markerAddr={markerProps.repoMarkerAddr} 
      selected={false}
      Marker={Marker}
    />
  );
}
  
