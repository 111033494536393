import produce from 'immer';
import Logger from 'libs/debug';

import * as State from './state';
import * as Types from './types';
import * as Tools from './tools';
import * as Defaults from './defaults';


export class HeaderFields {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  /**
   * Table
   */
  updateTableHeaderCSS(
    updateCSS: React.CSSProperties
  ) {
    this._state = produce(this._state, draft => {
      const table = State.getTable(draft);
      const header = table.header;
      const css = header.css;
      header.css = {
        ...css,
        ...updateCSS,
      }
    });
  }


  /**
   * Column
   */
  addColumn(
    columnType: Types.ColumnType,
    columnProps?: Types.ColumnPropsUpdate
  ): Types.ColumnAddr {
    const columnAddr = Tools.createColumnAddr();

    this._state = produce(this._state, draft => {
      const columnsAddrs = State.getColumnsAddrs(draft);
      const idx = columnsAddrs.length;
    
      this.__addColumnAtIdx(
        draft,
        idx,
        columnAddr,
        columnType,
        columnProps
      );
    });

    return columnAddr;
  }

  addColumnAfter(
    srcColumnAddr: Types.ColumnAddr,
    columnType:    Types.ColumnType,
    columnProps?:  Types.ColumnPropsUpdate
  ): Types.ColumnAddr {
    const columnAddr = Tools.createColumnAddr();

    this._state = produce(this._state, draft => {
      const idx = State.getColumnIdx(draft, srcColumnAddr);

      this.__addColumnAtIdx(
        draft,
        idx + 1,
        columnAddr,
        columnType,
        columnProps
      );
    });

    return columnAddr;
  }

  private __addColumnAtIdx(
    draft: State.State, 
    idx: number,
    columnAddr: Types.ColumnAddr,
    columnType:  Types.ColumnType,
    columnPropsUpdate?: Types.ColumnPropsUpdate,
  ) {
    const columnsAddrs  = State.getColumnsAddrs(draft);
    const columnsProps  = State.getColumnsProps(draft);
    
    const columnProps = {
      ...Defaults.getColumnProps(columnType),
      ...columnPropsUpdate
    };
  
    const columnKey = Tools.getColumnKey(columnAddr);
  
    columnsAddrs.splice(idx, 0, columnAddr);
    columnsProps[columnKey]  = columnProps;
  
    //
    // Create cells
    //
    const cellAddr: Types.CellAddr = {
      columnId: columnAddr.columnId,
    }
    this.__createCell(draft, cellAddr, columnType);
  }

  deleteColumn(columnAddr: Types.ColumnAddr) {
    this._state = produce(this._state, draft => {
      const columnsAddrs  = State.getColumnsAddrs(draft);
      const columnsProps  = State.getColumnsProps(draft);
      
      const idx = State.getColumnIdx(draft, columnAddr);
      const columnKey = Tools.getColumnKey(columnAddr);
    
      columnsAddrs.splice(idx, 1);
      delete columnsProps[columnKey];
    
      //
      // Delete cells
      //
      const cellAddr: Types.CellAddr = {
        columnId: columnAddr.columnId,
      }
      this.__deleteCell(draft, cellAddr);
    });
  }

  clearColumn(columnAddr: Types.ColumnAddr) {
    this._state = produce(this._state, draft => {
      const columnType = this.getColumnType(columnAddr);
      const cellAddr: Types.CellAddr = {
        columnId: columnAddr.columnId,
      }

      this.__deleteCell(draft, cellAddr);
      this.__createCell(draft, cellAddr, columnType);
    });
  }

  updateColumn(
    columnAddr: Types.ColumnAddr,
    update: Types.ColumnPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const columnProps = State.getColumnProps(draft, columnAddr);
      Object.assign(columnProps, update);
    });
  }

  updateColumnCSS(
    columnAddr: Types.ColumnAddr,
    updateCSS: React.CSSProperties
  ) {
    this._state = produce(this._state, draft => {
      const columnProps = State.getColumnProps(draft, columnAddr);
      const css = columnProps.css;
      columnProps.css = {
        ...css,
        ...updateCSS,
      }
    });
  }

  updateColumnHeaderCSS(
    columnAddr: Types.ColumnAddr,
    updateCSS: React.CSSProperties
  ) {
    this._state = produce(this._state, draft => {
      const columnProps = State.getColumnProps(draft, columnAddr);
      const header = columnProps.header;
      const css = header.css;
      header.css = {
        ...css,
        ...updateCSS,
      }
    });
  }

  moveColumn(
    srcColumnAddr: Types.ColumnAddr,
    dstColumnAddr: Types.ColumnAddr,
  ) {
    let moved = false;

    this._state = produce(this._state, draft => {
      if ( Tools.compareColumnAddr(srcColumnAddr, dstColumnAddr )) {
        console.log(`Src and dst columns are the same. Skipping move.`)
        return;
      }
    
      const columnsAddrs = State.getColumnsAddrs(draft);
    
      const srcColumnIdx = State.getColumnIdx(draft, srcColumnAddr);
      const srcIdxLowerThanDstIdx = (srcColumnIdx < State.getColumnIdx(draft, dstColumnAddr));
    
      const srcColumn = columnsAddrs.splice(srcColumnIdx, 1)[0];
      const dstColumnIdx = State.getColumnIdx(draft, dstColumnAddr);
    
      if (srcIdxLowerThanDstIdx) {
        columnsAddrs.splice(dstColumnIdx + 1, 0, srcColumn);
      }
      else {
        columnsAddrs.splice(dstColumnIdx, 0, srcColumn);
      }

      moved = true;
    });

    return moved;
  }

  private __createCell(
    draft: State.State,
    cellAddr: Types.CellAddr,
    cellType: Types.ColumnType,
  ) {
    const log = Logger.getContentState();
    log.log(
      "Create cell\n" +
      Tools.debugCellAddr(cellAddr)
    );

    const cells = State.getCells(draft);
    const cellKey = Tools.getCellKey(cellAddr);

    if (cellKey in cells) {
      const msg = `Can create cell, as cell already exists`;
      throw new Error(msg);
    }

    const cellDef = Defaults.getCell(cellType);
    cells[cellKey] = cellDef;
  }

  private __deleteCell(
    draft: State.State,
    cellAddr: Types.CellAddr
  ) {
    const log = Logger.getContentState();
    log.log(
      "Delete cell called\n" +
      Tools.debugCellAddr(cellAddr)
    );

    const cells = State.getCells(draft);
    const cellKey = Tools.getCellKey(cellAddr);

    if (! ( cellKey in cells )) {
      const msg = `Can delete cell, as it is missing`;
      throw new Error(msg);
    }

    delete cells[cellKey];
  }


  /**
   * Cell Text
   */
  cellText_writeContent(
    cellAddr: Types.CellAddr, 
    editorState: string
  ) {
    this._state = produce(this._state, draft => {
      const cell = State.getCell(draft, cellAddr) as Types.TextCell;
      cell.editorState = editorState;
    });
  }
  

  //----------------------
  // Getters
  //

  /**
   * Table
   */
  getTable() {
    return State.getTable(this._state);
  }


  /**
   * Fields
   */
  getFields() {
    return State.getFields(this._state);
  }


  /**
   * Columns
   */
  getColumnsAddrs() {
    return State.getColumnsAddrs(this._state);
  }

  getColumnsProps() {
    return State.getColumnsProps(this._state);
  }


  /**
   * Column
   */
  getColumnProps(columnAddr: Types.ColumnAddr): Types.ColumnProps {
    return State.getColumnProps(this._state, columnAddr);
  }

  getColumnWidth(columnAddr: Types.ColumnAddr): number {
    return State.getColumnWidth(this._state, columnAddr);
  }

  getColumnType(columnAddr: Types.ColumnAddr): Types.ColumnType {
    return State.getColumnType(this._state, columnAddr);
  }

  getColumnIdx(columnAddr: Types.ColumnAddr): number {
    return State.getColumnIdx(this._state, columnAddr);
  }


  /**
   * Cells
   */
  getCells() {
    return State.getCells(this._state);
  }


  /**
   * Cell
   */
  getCell(cellAddr: Types.CellAddr) {
    return State.getCell(this._state, cellAddr);
  }
}
