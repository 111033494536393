import { useEffect, useRef } from "react";
import { UUID } from "app/arch/types";
import useKeyBindings     from 'lego/components/key-bindings/hooks/use-key-bindings';
import * as Types from '../types';


export const useMenuKeyBinder = (config: Types.Config) => {
  const {
    addKeyDownBinding, 
    removeKeyDownBinding
  } = useKeyBindings();

  const keysBindingsIds = useRef<string[]>([]);

  const processKeyBindings = (processFn: (item: Types.SectionItem)=> void) => {
    config.items.forEach((barItem: Types.Item) => {
      const sections = barItem.panel.sections;
      sections.forEach((section: any) => {
        const items = section.items;
        items.forEach((item: any) => {
          if (item.keysBindingCheck) {
            processFn(item);
          }
        });
      });
    });
  }

  const registerKeyBindings = () => {
    const addBinding_ = (item: Types.SectionItem) => {
      if ( item.keysBindingCheck === undefined ) {
        return;
      }

      const bindingId = addKeyDownBinding({
        check: item.keysBindingCheck, 
        callback: (data?: any) => {
          item.onClick();
          return true;
        }, 
        preventDefault: item.keysBindingPreventDefault, 
        stopPropagation: item.keysBindingStopPropagation,
        priority: item.keysBidningPriority
      });
        
      keysBindingsIds.current.push(bindingId);
    };

    processKeyBindings(addBinding_);
  }

  const unregisterKeyBindings = () => {
    keysBindingsIds.current.forEach((id: UUID) => {
      removeKeyDownBinding(id);
    });
    keysBindingsIds.current = [];
  }

  useEffect(() => {
    registerKeyBindings();
    return () => {
      unregisterKeyBindings();
    }
  }, []);

  return null;
}

