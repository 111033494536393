import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';


const useFunctions = () => {
  const document = useDocState();

  const {
    setContent,
    setContentView,
    setHeaderMeta,
  } = useEditorStatesSetters();

  
  const setVisible = (visible: TypesShared.PageItemVisibility) => {
    document.contentView.updateHeaderMeta({visible});
    document.saveUndo();
    setContentView();
  }
  
  const setLogoVisible = (visible: boolean) => {
    document.headerMeta.updateLogo({visible});
    document.saveUndo();
    setHeaderMeta();
  }

  const setRevisionInfoVisible = (visible: boolean) => {
    document.headerMeta.updateReleaseInfo({visible});
    document.saveUndo();
    setHeaderMeta();
  }


  const setSectionsEnabled = (enabled: boolean) => {
    const update = { enabled };

    document.content.updateSectionsConfig(update);
    document.saveUndo();
    setContent();
  }

  const setSectionsIndexing = (indexing: ContentTypes.SectionsIndexing) => {
    const update = { indexing };

    document.content.updateSectionsConfig(update);
    document.saveUndo();
    setContent();
  }

  const updateSectionsCSS = (props: {
    cssUpdate: React.CSSProperties,
    skipUndoSave?: boolean,
  }) => {
    const {
      cssUpdate,
      skipUndoSave
    } = props;
    
    document.content.updateSectionsNamesCSS(cssUpdate);
    if ( ! skipUndoSave ) {
      document.saveUndo();
    }
    setContent();
  }


  return {
    setVisible,
    setLogoVisible,
    setRevisionInfoVisible,

    setSectionsEnabled,
    setSectionsIndexing,
    updateSectionsCSS
  }
}


export default useFunctions;