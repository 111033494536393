import React from 'react';

import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import HLS_DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';

import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import HeaderCellDraggedViewComponent from './header-cell-dragged-view';
import { HeaderCellProps } from '../types';
import { Wrapper } from './styles';


interface Props extends HeaderCellProps {
}


export const HeaderCellDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageRowAddr,
    children
  } = props;

  const setDNDData = useDNDDataSet();
  const docState = useDocState();
  const { hideMenu } = useContextMenu();

  const {
    setCustomRowsRowsSelected,
  } = useEditorStatesSetters();

  const handleDragStart = () => {
    const data = { pageRowAddr };
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  const handleDragMoveFirst = () => {
    hideMenu();
    docState.customRowsRowsSelected.reset();
    docState.customRowsRowsSelected.select(pageRowAddr);
    setCustomRowsRowsSelected();
  }

  return (
    <HLS_DraggerComponent
      dndContext={DNDContext.viewContent.customRows.row}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      Wrapper={Wrapper}
      draggedView={
        <HeaderCellDraggedViewComponent
          pageRowAddr={pageRowAddr}
        />
      }
    >
      { children }
    </HLS_DraggerComponent>
  );
}
