import React from 'react';

import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import HLS_DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';

import Tools from '../tools';
import HeaderCellDraggedViewComponent from './header-cell-dragged-view';


interface Props {
  disabled?: boolean;
  columnAddr: ReleaselogsTypes.ColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    disabled,
    columnAddr, 
    children,
  } = props;
 
  if (disabled) {
    return children;
  }

  return (
    <EnabledComponent 
      columnAddr={columnAddr}
      disabled={disabled}
    >
      { children }
    </EnabledComponent>
  );
}


const EnabledComponent: React.FC<Props> = (props: Props) => {
  const { 
    columnAddr, 
    children,
  } = props;

  const setDNDData = useDNDDataSet();
  const { hideMenu } = useContextMenu();

  const handleDragStart = () => {
    const data = { columnAddr };
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  const handleDragMoveFirst = () => {
    hideMenu();
  }

  const dndContext = Tools.getDNDContext(columnAddr.releaselogId);

  return (
    <HLS_DraggerComponent
      dndContext={dndContext}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      draggedView={
        <HeaderCellDraggedViewComponent columnAddr={columnAddr} />
      }
    >
      { children }
      
    </HLS_DraggerComponent>
  );
}

