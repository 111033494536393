import React from 'react';
import ViewTitleProbeComponent   from './view-title-probe';
import ViewTitleContentComponent from './view-title-content';
import ViewTitleEditComponent    from './view-title-edit';
import ViewTitleCSSComponent     from './view-title-css';
import ViewTitleContextMenuComponent from './view-title-context-menu';

import { Border } from './styles';

interface Props {
}


export const ViewTitleComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <ViewTitleProbeComponent>
      <Border>
        <ViewTitleContextMenuComponent>
          <ViewTitleEditComponent>
            <ViewTitleCSSComponent>
              <ViewTitleContentComponent />
            </ViewTitleCSSComponent>
          </ViewTitleEditComponent>
        </ViewTitleContextMenuComponent>
      </Border>
    </ViewTitleProbeComponent>
  );
}
  
