
import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useItems from './use-items';


const useColumnTextConfig = () => {
  const t = useTranslations();
  const items = useItems();

  const getConfig = (): PanelControlsConfig => ({
    items: [
      {
        // title: t("column text"),
        sections: [
          {
            subsections: [
              {
                title: t("layout, column header"),
                items: [
                  items.getHeader_layout(),     
                ]
              },
              {
                title: t("layout, column"),
                items: [
                  items.getColumn_padding(),
                ]
              }
            ],              
          },
        ]
      },
    ]
  }); 


  return getConfig;
}

export default useColumnTextConfig;
