
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import HLS_DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import HeaderCellDraggedViewComponent from './header-cell-dragged-view';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


interface Props {
  columnAddr: Types.ColumnAddr; 
  disabled: boolean;
  children: React.ReactNode;
}


export const HeaderCellDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    columnAddr, 
    disabled,
    children,
  } = props;
 
  if (disabled) {
    return children;
  }

  return (
    <EnabledComponent columnAddr={columnAddr}>
      { children }
    </EnabledComponent>
  );
}


// Splitting into two components
// will prevent from uncessary rerender of dragger
// due to desk scale change, when dragger is disabled.
// Not a big improvment.

interface EnableProps {
  columnAddr: Types.ColumnAddr; 
  children: React.ReactNode;
}

const EnabledComponent: React.FC<EnableProps> = (props: EnableProps) => {
  const { 
    columnAddr, 
    children,
  } = props;

  const setDNDData = useDNDDataSet();
  const docState = useDocState();
  const { hideMenu } = useContextMenu();

  const {
    setContentColumnsSelected,
  } = useEditorStatesSetters();

  const handleDragStart = () => {
    const data = { columnAddr };
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  const handleDragMoveFirst = () => {
    hideMenu();
    docState.contentColumnsSelected.reset();
    docState.contentColumnsSelected.select(columnAddr);
    setContentColumnsSelected();
  }

  return (
    <HLS_DraggerComponent
      dndContext={DNDContext.viewContent.table.column}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      draggedView={
        <HeaderCellDraggedViewComponent
          columnAddr={columnAddr}
        />
      }
    >
      { children }
    </HLS_DraggerComponent>
  );

}