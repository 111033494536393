import styled, { keyframes } from "styled-components";

// Keyframes
const l19 = keyframes`
  100% {transform: rotate(1turn);}
`;

// Styled Component
const AnimCogWheel = styled.div`
  width: 35px;
  aspect-ratio: 1;
  display: grid;
  color: ${props => props.theme.defs.accentColor.primary};
  background: radial-gradient(farthest-side, currentColor calc(100% - 3px), #0000 calc(100% - 5px) 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 12px), #000 calc(100% - 11px));
  border-radius: 50%;
  animation: ${l19} 2s infinite linear;

  &::before,
  &::after {
    content: "";
    grid-area: 1/1;
    background:
      linear-gradient(currentColor 0 0) center,
      linear-gradient(currentColor 0 0) center;
    background-size: 100% 7px, 7px 100%;
    background-repeat: no-repeat;
  }

  &::after {
    transform: rotate(45deg);
  }
`;

export default AnimCogWheel;
