import React from 'react';
import HeaderInfoContentComponent from './header-info-content';
import HeaderInfoPanelContextComponent from './header-info-panel-context';


interface Props {
  logoVisible: boolean;
  revisionInfoVisible: boolean;
}


export const DocHeaderInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    logoVisible,
    revisionInfoVisible,
  } = props;

  
  return (
    <HeaderInfoPanelContextComponent>
      <HeaderInfoContentComponent
        logoVisible={logoVisible}
        revisionInfoVisible={revisionInfoVisible}
      />
    </HeaderInfoPanelContextComponent>
  );
}
  
