import { atom }           from 'recoil';
import { selector }       from 'recoil';
import { selectorFamily } from 'recoil';

import { ContentTypes as Types }from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTools as Tools }from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/content-session';



//----------------
// State
//

export const state = atom<State .State>({
  key: "ei_docContent_contentSession",
  default: State.createInitialState()
});


//--------------------
// Selectors
//


/**
 * 
 * Sections
 * 
 */
export const isSectionSelected = selectorFamily({
  key: "ei_docContent_contentSession_isSectionSelected",
  get: (sectionAddr: Types.SectionAddr) => ({ get }) => {
    const stateObj = get(state);
    const selected = State.isSectionSelected(stateObj, sectionAddr);
    return selected;
  }
});


/**
 * 
 * Cells
 * 
 */
export const cellSelected = selector({
  key: "ei_docContent_contentSession_cellSelected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selectedCellAddr = State.getCellSelected(stateObj);
    return selectedCellAddr;
  }
});

export const isCellSelected = selectorFamily({
  key: "ei_docContent_contentSession_isCellSelected",
  get: (cellAddr: Types.CellAddr) => ({ get }) => {
    const stateObj = get(state);
    const selected = State.isCellSelected(stateObj, cellAddr);
    return selected;
  }
});


/**
 * 
 * Cell Images
 * 
 */

export const cellImages_imagesUploadingCount = selectorFamily({
  key: `ei_docContent_contentSession_cellImages_imagesUploadingCount`,
  get: (cellAddr: Types.CellAddr) => ({ get }) => {
    const stateObj = get(state);
    const uploadingImgsCount = State.cellImages_countImagesUploading(stateObj, cellAddr);
    return uploadingImgsCount;
  }
});


/**
 * 
 * Cell Markers
 * 
 */

/**
 * 
 * Panels
 * 
 */
export const panelAssets = selector({
  key: `ei_docContent_contentSession_panelAssets`,
  get: ({ get }) => {
    const stateObj = get(state);
    const panel = State.getPanelAssets(stateObj);
    return panel;
  }
});

export const panelProperties = selector({
  key: `ei_docContent_contentSession_panelProperties`,
  get: ({ get }) => {
    const stateObj = get(state);
    const panel = State.getPanelProperties(stateObj);
    return panel;
  }
});

