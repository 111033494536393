import PanelItemGenerator  from 'app/ui-v2/editor-instruction/panel-items/generator';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useFunctions from './__use-functions';
import useStates from './__use-states';


const useItems = () => {
  const t = useTranslations();
  const fn = useFunctions();
  const states = useStates();

  const getVisible = () => 
    PanelItemGenerator.getPanelVisibility({
      visible: states.visible,
      onClick: fn.setVisible,
    });

  const getLogoVisibile = () => 
    PanelItemGenerator.getToggleOnOff({
      title: t('page header, show logo'),
      selected: states.logoVisible,
      onClick: fn.setLogoVisible,
    });

  const getRevisionInfoVisible = () => 
    PanelItemGenerator.getToggleOnOff({
      title: t('page header, show release'),
      selected: states.revisionVisible,
      onClick: fn.setRevisionInfoVisible,
    });

  return {
    getVisible,
    getLogoVisibile,
    getRevisionInfoVisible,
  }
}


export default useItems;