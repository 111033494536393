import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useSelectedReset = () => {
  const docState = useDocState();

  const {
    setHeaderRowsSession,
    setCustomRowsRowsSelected,
  } = useEditorStatesSetters();

  const hook = (props?: {
    skipStateUpdate?: boolean,
  }) => {

    const skipStateUpdate = props?.skipStateUpdate ?? false;;

    docState.customRowsRowsSelected.reset();
    // TODO: should it be here?
    docState.headerRowsSession.setPageCellSelected(null);

    if (skipStateUpdate) {
      return;
    }

    setHeaderRowsSession();
    setCustomRowsRowsSelected();
  }

  return hook;
}


export default useSelectedReset;
