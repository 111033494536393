import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = () => {
  const docState = useDocState();

  const {
    setContent,
    setContentRowsSelected,
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();


  const clearRows = () => {
    const rows = docState.contentRowsSelected.getSelected();
    const rowsKeys = Object.keys(rows);
    rowsKeys.forEach(rowKey => {
      const selected = rows[rowKey];
      if ( ! selected) {
        return;
      }

      const rowAddr = ContentTools.rowKeyToAddr(rowKey);
      docState.content.clearRow(rowAddr);
    });

    docState.saveUndo();
    setContent();

    hideMenu();
  }


  const deleteRows = () => {
    const rows = docState.contentRowsSelected.getSelected();
    const rowsKeys = Object.keys(rows);
    rowsKeys.forEach(rowKey => {
      const selected = rows[rowKey];
      if ( ! selected) {
        return;
      }

      const rowAddr = ContentTools.rowKeyToAddr(rowKey);
      docState.content.deleteRow(rowAddr);
    });

    docState.contentRowsSelected.reset();

    docState.saveUndo();
    setContent();
    setContentRowsSelected();
    hideMenu();
  }


  return {
    clearRows,
    deleteRows,
  };
}


export default useFunctions;