import React from 'react';
import useIsKeyDown from 'lego/components/key-bindings/hooks/use-is-key-down';
import useSystemKeys from 'lego/components/key-bindings/hooks/use-system-keys';
import { MenuConfig } from 'lego-v2/menu/config';

import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";


export interface Props<T> {
  isSelected: (addr: T) => boolean;
  isMultiselected: () => boolean;
  resetSelected: () => void;
  resetOtherSelected: () => void;
  select: (addr: T) => void;

  getConfigItems: () => MenuConfig;
  getConfigItem: (addr: T) => MenuConfig;
}

const useItemContextMenu = <T>({
  isSelected,
  resetSelected,
  resetOtherSelected,
  select,
  isMultiselected,
  getConfigItems,
  getConfigItem,
}: Props<T>) => {

  const isKeyDown = useIsKeyDown();
  const systemKeys = useSystemKeys();
  const actionKeys = systemKeys.actionKeys;
  const { showMenu } = useContextMenu();


  const handleClick = (event: React.MouseEvent, itemAddr: T) => {
    event.stopPropagation();

    if ( ! isSelected(itemAddr)) {
      const enabledMultiSelected = isKeyDown(actionKeys.multiItemSelect);
      if ( ! enabledMultiSelected ) {
        resetSelected();
      }
      else {
        resetOtherSelected();
      }

      select(itemAddr);
    }

    const config = ( 
      isMultiselected() ?
      getConfigItems() :
      getConfigItem(itemAddr)
    );

    showMenu({ event, config });
  }

  return handleClick;
}

export default useItemContextMenu; 