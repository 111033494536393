import { MenuItemType } from 'lego-v2/menu';

import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { Icon } from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useFunctions from './__use-functions';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();

  const getCreateMarker = (markerAddr: RepoMarkersTypes.MarkerAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('marker, new'),
      iconComponent: Icon.Create,
      onClick: (event: React.MouseEvent) => fns.createMarker(markerAddr)
    }
  });

  const getEditMarker = (markerAddr: RepoMarkersTypes.MarkerAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('marker, edit'),
      iconComponent: Icon.Edit.Plain,
      onClick: (event: React.MouseEvent) => fns.editMarker(markerAddr)
    }
  });

  const getDuplicateMarker = (markerAddr: RepoMarkersTypes.MarkerAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('marker, duplicate'),
      iconComponent: Icon.Duplicate,
      onClick: (event: React.MouseEvent) => fns.duplicateMarker(markerAddr)
    }
  });

  const getDeleteMarker = (markerAddr: RepoMarkersTypes.MarkerAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('marker, delete'),
      variant: 'delete',
      iconComponent: Icon.Delete,
      onClick: (event: React.MouseEvent) => fns.deleteMarker(markerAddr)
    }
  });


  return {
    getCreateMarker,
    getEditMarker,
    getDuplicateMarker,
    getDeleteMarker,
  }
}


export default useItems;