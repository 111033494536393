import { useRef } from 'react';
import { PageFormatType, PageOrientationType } from 'app/arch/types/types';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import useCustomFieldsColumnsAutoAdjust from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/columns/use-columns-auto-adjust';
import useContentColumnsAutoAdjust      from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/columns/use-columns-auto-adjust';
import useReleaselogsColumnsAutoAdjust  from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/columns/use-releaselogs-columns-auto-adjust';


const useFunctions = () => {
  const document = useDocState();
  const docReleaselogsColumnsAutoAdjust = useReleaselogsColumnsAutoAdjust()
  const docContent2ColumnsAutoAdjust    = useContentColumnsAutoAdjust();
  const contentCustomFieldsAutoAdjust   = useCustomFieldsColumnsAutoAdjust();

  const prevPageMarginsLockedRef = useRef<boolean | null>(null);

  const autoAdjustColumns = () => {
    docReleaselogsColumnsAutoAdjust();
    docContent2ColumnsAutoAdjust();
    contentCustomFieldsAutoAdjust();
  }

  const {
    setViewsCommon,
  } = useEditorStatesSetters();

  
  const setPageOrientation = (orientation: PageOrientationType) => {
    document.viewsCommon.updatePageLayout({orientation});
    setViewsCommon();
    autoAdjustColumns();
    document.saveUndo();
  }

  const setPageFormat = (format: PageFormatType) => {
    document.viewsCommon.updatePageLayout({format});
    setViewsCommon();
    autoAdjustColumns();
    document.saveUndo();
  }

  const setMarginsVisible = (visible: boolean) => {
    document.viewsCommon.updatePageMargins({locked: visible});
    document.saveUndo();
    setViewsCommon();
  }

  const changeMarginStart = () => {
    prevPageMarginsLockedRef.current = document.viewsCommon.getPageMargins().locked;
    document.viewsCommon.updatePageMargins({locked: false});
    setViewsCommon();
  }

  const changeMargin = (marginName: string, marginValue: number) => {
    const update = {[marginName]: marginValue};
    document.viewsCommon.updatePageMargins(update);
    setViewsCommon();
    autoAdjustColumns();
  }

  const changeMarginDone = (marginName: string, marginValue: number) => {
    changeMargin(marginName, marginValue);

    if (prevPageMarginsLockedRef.current !== null) {
      if (prevPageMarginsLockedRef.current !== document.viewsCommon.getPageMargins().locked) {
        const locked = prevPageMarginsLockedRef.current;
        document.viewsCommon.updatePageMargins({locked});
        setViewsCommon();
      }
    }

    prevPageMarginsLockedRef.current = null;
    document.saveUndo();
  }


  return {
    setPageOrientation,
    setPageFormat,

    setMarginsVisible,
    changeMarginStart,
    changeMargin,
    changeMarginDone,
  }
}


export default useFunctions;