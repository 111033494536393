import React from 'react';

import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useDocMetaMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-meta/use-doc-meta-menu';
import { MainWrapper } from './styles';


interface Props {
  children: React.ReactNode,
}


export const HeaderInfoPanelContextComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
  } = props;

  const { showMenu  } = useContextMenu();
  const getConfig = useDocMetaMenu();

  const handleContext = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const config = getConfig();

    showMenu({
      event,
      config
    });
  }

  return (
    <MainWrapper onContextMenu={handleContext}>
      { children }
    </MainWrapper>
  );
}

