import { RateControlType } from 'lego/types';
import { PanelControlsItemSectionItemType } from 'lego/components/panel-controls/config';

import { Page } from 'app/arch/print/page';
import PanelItemGenerator  from 'app/ui-v2/editor-instruction/panel-items/generator';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useFunctions from './__use-functions';
import useStates from './__use-states';
import Icon from 'app/ui/icons/icons';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();
  const states = useStates();

  const getPageOrientation = () => 
    PanelItemGenerator.getPageOrientation({
      pageOrientation: states.pageLayout.orientation,
      onClick: fns.setPageOrientation,
  });

  const getPageSize = () => 
    PanelItemGenerator.getPageSize({
      pageFormat: states.pageLayout.format,
      onClick: fns.setPageFormat,
  });

  const getMarginsVisible = () => (    {
    title: t('visible'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        value: true,
        Icon: Icon.Hide,
        selected: states.pageMargins.locked
      }, 
      {
        value: false,
        Icon: Icon.Show,
        selected: ! states.pageMargins.locked
      },                 
    ],
    onClick: fns.setMarginsVisible
  });

  const __getMarginTitle = (title: string) => {
    const unit = Page.getUnitsString(states.pageMargins.units);
    return `${title} (${unit})`;
  }

  const __getMarginSizeBase = (marginName: 'top' | 'bottom' | 'left' | 'right') => ({
    title: __getMarginTitle(t(marginName)),
    type: PanelControlsItemSectionItemType.SLIDER,
    onChangeStart: fns.changeMarginStart,
    onChange: (value: number) => fns.changeMargin(marginName, value),
    onChangeDone: (value: number) => fns.changeMarginDone(marginName, value),
    rateControl: {
      controlType: RateControlType.DEBOUNCED
    },
    min: 0,
    max: 100,
    value: states.pageMargins[marginName],
  })

  const getMarginTop    = () => __getMarginSizeBase('top');
  const getMarginBottom = () => __getMarginSizeBase('bottom');
  const getMarginLeft   = () => __getMarginSizeBase('left');
  const getMarginRight  = () => __getMarginSizeBase('right');


  return {
    getPageOrientation,
    getPageSize,

    getMarginsVisible,
    getMarginTop,
    getMarginBottom,
    getMarginLeft,
    getMarginRight,
  }
}


export default useItems;