import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { Size } from 'app/arch/types';
import { MenuConfig } from '../config';
import { MenuComponent } from '../Menu';
import { SubmenuDetacher }   from './styles';
import { SubmenuPositioner } from './styles';


interface Props {
  config: MenuConfig;
  parentMenu?:   any;
  onSelected?:   any;
  onDeselected?: any;
}

export const SubmenuComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
    parentMenu,
    onSelected,
    onDeselected,
  } = props;

    
  const wrapperRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [delta, setDelta] = useState<Size | null>(null);

  useEffect(() => {
    const el = menuRef.current;
    if ( ! el ) {
      return;
    }
    
    if ( delta ) {
      return;
    }

    const bbox = el.getBoundingClientRect();
    const deltaX = window.innerWidth  -  bbox.right;
    const deltaY = window.innerHeight -  bbox.bottom;
    const x = deltaX < 0 ? deltaX : 0;
    const y = deltaY < 0 ? deltaY : 0;

    setDelta([x, y]);
  }, [menuRef.current]);


  return (
    <SubmenuDetacher>
      <SubmenuPositioner 
        ref={wrapperRef}
        left={delta?.at(0) ?? 0}
        top={delta?.at(1)?? 0}
      >
        <MenuComponent
          ref={menuRef}
          config={config}
          parentMenu={parentMenu}
          onSelected={onSelected}
          onDeselected={onDeselected}
        />
      </SubmenuPositioner>
    </SubmenuDetacher>
  );
}
