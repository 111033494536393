import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useItems from './use-items';


const useColumnMarkersConfig = () => {
  const t = useTranslations();
  const items = useItems();

  const getConfig = (): PanelControlsConfig => ({
    items: [
      {
        // title: t("column markers"),
        sections: [
          {
            // title: t("layout"),
            subsections: [
              {
                title: t("layout, column header"),
                items: [
                  items.getHeader_layout(),     
                ]
              },
              {
                title: t("layout, column"),
                items: [
                  items.getColumn_padding(),
                  items.getColumn_flexGap(),

                  items.getColumn_flexLayout(),
                  items.getColumn_flexRowConfigHorizontal(),
                  items.getColumn_flexRowConfigVertical(),
                  items.getColumn_flexColumnConfigHorizontal(),
                  items.getColumn_flexColumnConfigVertical(),
                ]
              }
            ],
          },
        ]
      },
    ]
  }); 


  return getConfig;
}

export default useColumnMarkersConfig;
