import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from './use-editor-states-setters';


const useDNDContextDisable = () => {
  const document = useDocState();
  const { setEditorSession } = useEditorStatesSetters();

  const disable = () => {
    document.editorSession.setDNDData(null);
    document.editorSession.setDNDContext(null);
    setEditorSession();
  }

  return disable;
}


export default useDNDContextDisable;