import { MenuConfig   } from 'lego-v2/menu/config';
import useItems from './__use-items';


const useColumnsMenu = () => {
  const items = useItems();

  const getConfig = () => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getColumnsClear(),
          ],
        },
        {
          items: [
            items.getColumnsDelete(),
          ],
        },
      ]
    }

    return config;
  }


  const hook = () => {
    const config = getConfig();
    return config;
  }

  return hook;
}


export default useColumnsMenu;