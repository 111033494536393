import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { RepoMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';


const useFunctions = () => {
  const docState = useDocState();

  const {
    setContent,
    setRepoMarkers,
    setGalleryMarkersSelected,
    setHeaderMarkers,
  } = useEditorStatesSetters();
  
  const deleteMarkers = (event: KeyboardEvent) => {
    const markersSelected = docState.galleryMarkersSelected.getSelected();
    const markersKeys = Object.keys(markersSelected);
    if ( ! markersKeys.length ) {
      return false;
    }

    const markersAddrs = markersKeys.map(markerKey => RepoMarkersTools.markerKeyToAddr(markerKey));
    markersAddrs.map((markerAddr) => {
      docState.docMarker_delete(markerAddr);
    });

    docState.saveUndo();
    setContent();
    setRepoMarkers();
    setHeaderMarkers();

    docState.galleryMarkersSelected.reset();
    setGalleryMarkersSelected();

    return true;
  }

  return {
    deleteMarkers,
  }
}

export default useFunctions;