import { useEffect, useState } from "react";

import useKeyBindings from "./use-key-bindings";
import { KeyBindingPriority } from "../arch/types";


interface Props {
  key: string;
}


const useIsKeyDownStated = (props: Props) => {
  const {
    key, 
  } = props;

  const [isKeyDown, setIsKeyDown] = useState(false);

  const  { 
    addKeyDownBinding,
    removeKeyDownBinding,
    
    addKeyUpBinding,
    removeKeyUpBinding,
  } = useKeyBindings();

  useEffect(() => {
    const keyDownId = addKeyDownBinding({
      check: (event: any) => event.key === key, 
      callback: () => { 
        // console.warn('set true');
        setIsKeyDown(true);
        return false;
      },
      priority: KeyBindingPriority.LOWEST,
    });

    const keyUpId = addKeyUpBinding({
      check: (event: any) => event.key === key, 
      callback: () => { 
        // console.warn('set false');
        setIsKeyDown(false);
        return false;
      },
      priority: KeyBindingPriority.LOWEST,
    });

    return () => {
      removeKeyDownBinding(keyDownId);
      removeKeyUpBinding(keyUpId);
    }
  }, []);
  

  return isKeyDown;
}


export default useIsKeyDownStated;