
import { MenuConfig } from 'lego-v2/menu/config';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import useItems from './__use-items';


const useGalleryMarkersMarkerMenu = () => {
  const items = useItems();

  const getConfig = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getCreateMarker(markerAddr),
          ],
        },
        {
          items: [
            items.getEditMarker(markerAddr),
            items.getDuplicateMarker(markerAddr),
          ],
        },  
        {
          items: [
            items.getDeleteMarker(markerAddr),
          ],
        },  
      ]
    }
  
    return config;
  }

  
  const hook = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    const config = getConfig(markerAddr);
    return config;
  }

  return hook;
}


export default useGalleryMarkersMarkerMenu;