import { PanelControlsConfig } from 'lego/components/panel-controls/config';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useItems from './__use-items';


const usePanelConfig = () => {
  const t = useTranslations();
  const items = useItems();

  const getConfig = (): PanelControlsConfig => {
    const config: PanelControlsConfig = {
      items: [
        {
          sections: [
            {
              subsections: [
                {
                  items: [
                    items.getVisible(),
                    items.getLogoVisibile(),
                    items.getRevisionInfoVisible(),
                  ]
                },
              ],
            }
          ]
        },
      ]
    } // config

    return config;
  }

  return getConfig;
}


export default usePanelConfig;