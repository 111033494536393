import { PanelControlsConfig } from 'lego/components/panel-controls/config';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useItems from './__use-items';


const usePanelConfig = () => {
  const t = useTranslations();
  const items = useItems();

  const getConfig = () => {

    const config: PanelControlsConfig = {
      items: [
        {
          // title: t('table header'),
          sections: [
            {
              // title: t('table header'),
              subsections: [
                {
                  title: t("layout"),
                  items: [
                    items.getTableHeaderPadding(),
                  ]
                },
                {
                  title: t("text"),
                  items: [
                    items.getTableHeaderFontSize(),
                    items.getTableHeaderFontWeight(),
                    items.getTableHeaderFontCapsLock(),
                    items.getTableHeaderFontColor(),
                    items.getTableHeaderBackground(),
                  ]
                },
              ],              
            },
          ]
        },
      ]
    } // config

    return config;
  }


  const hook = () => {
    const config = getConfig();
    return config;
  }

  return hook;
}


export default usePanelConfig;