import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = (cellAddr: Types.CellAddr) => {
  const docState = useDocState();

  const {
    setContent,
  } = useEditorStatesSetters();

  const resetCell = (event: KeyboardEvent) => {
    // docState.content.resetCell(cellAddr);
    // docState.saveUndo();
    // setContent();
    return false;
  }


  return {
    resetCell,
  }
}

export default useFunctions;