import React from 'react';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import { useDocState } from 'app/ui/contexts/document';
import useCellMarkersMarkersMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-markers/use-cell-markers-markers-menu';
import useCellMarkersMarkerMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-markers/use-cell-markers-marker-menu';
import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useItemContextMenu from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-context-menu';
import { MainWrapper } from './styles';


interface Props {
  markerAddr: ContentTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children
  } = props;

  const docState = useDocState();

  const getConfigMarkers = useCellMarkersMarkersMenu();
  const getConfigMarker = useCellMarkersMarkerMenu();
  const resetAllSelected = useAllSelectedReset();
  
  const {
    setContentCellMarkersSelected,
  } = useEditorStatesSetters();

  type Addr = ContentTypes.MarkerAddr;

  const handleContextMenu_ = useItemContextMenu<Addr>({
    isSelected: (addr: Addr) => docState.contentCellMarkersSelected.isSelected(addr),
    isMultiselected: () => docState.contentCellMarkersSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    resetOtherSelected: () => resetAllSelected({skipContentCellMarkersReset: true}),
    select: (addr: Addr) => {
      docState.contentCellMarkersSelected.select(addr);
      setContentCellMarkersSelected();
    },
    getConfigItems: () => getConfigMarkers(),
    getConfigItem: (addr:Addr) => getConfigMarker(addr)
  });


  const handleContextMenu = (event: React.MouseEvent) => {
    handleContextMenu_(event, markerAddr);
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu} >
      { children }
    </MainWrapper>
  );
}
  
