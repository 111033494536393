import { useRecoilValue } from 'recoil';
import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import { UIState_ContentView } from 'app/ui/states/editor-instruction';


const useStates = () => {
  const visible = useRecoilValue(UIState_ContentView.headerInternalVisible);
  const visibleNone = (visible === TypesShared.PageItemVisibility.NONE);
  
  return {
    visible,
    visibleNone,
  }
}


export default useStates;