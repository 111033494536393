import React from 'react';

import { ItemsSelectedBaseTypes } from 'app/arch/editor-instruction/document/states/sessions/_parts/items-selected-base';
import TopToolbarComponent from 'app/ui-v2/editor-instruction/blocks/top-toolbar';
import useImageMenu from '../../../side-panels/panel-assets/controls/control-images/images-panel/images/hooks/use-image-menu';
import useImagesMenu from '../../../side-panels/panel-assets/controls/control-images/images-panel/images/hooks/use-images-menu';


interface Props {
  imagesSelected: ItemsSelectedBaseTypes.SelectedItems;
}


export const ImagesMenusComponent: React.FC<Props> = (props: Props) => {
  const {
    imagesSelected,
  } = props;

  const imagesSelectedKeys = Object.keys(imagesSelected);
  const imagesSelectedCount = imagesSelectedKeys.length;
  
  const getConfigImage  = useImageMenu();
  const getConfigImages = useImagesMenu();
  
  const imgAddr = (
    imagesSelectedCount === 1 ? 
    +imagesSelectedKeys[0] :
    null
  );

  const menuConfig = (
      imagesSelectedCount === 1  ?
      getConfigImage(imgAddr !) :
      getConfigImages() // multi select
  );

  return (
    <TopToolbarComponent sections={menuConfig.sections} />
  );
}
  
