import { useState } from 'react';
import { useEffect } from 'react';

import { useProgressModal } from 'lego-v2/progress-modal';

import EditorInstruction        from "app/arch/editor-instruction";
import EditorInstructionContext from "app/ui/contexts/editor-instruction";
import useEditorLeave           from "app/ui-v2/editor-instruction/hooks/use-editor-leave";
import { useDocState }          from 'app/ui/contexts/document';
import { useEditor } from 'app/ui-v2/editor-instruction/hooks/use-editor';
import useScreenKeyboardAdjusterEnable from 'app/ui-v2/app/__modules/screen/hooks/use-screen-keyboard-adjuster-enable';

import useKeyBindings        from './key-bindings';
import { useIsPrintout }     from './use-is-printout';
import LoadersComponent      from './loaders';
import LexicalCheckComponent from './lexical-check';

import ViewPrintoutComponent from './views/view-printout';
import ViewEditorComponent   from './views/view-editor';


interface Props {
  editorInstruction: EditorInstruction;
}


export const EditorInstructionComponent: React.FC<Props> = (props: Props) => {
  const {
    editorInstruction 
  } = props;

  const contextProps = {
    editorInstruction,
  };
  
  
  return (
    <EditorInstructionContext.Provider 
      value={contextProps}
    >
      <LexicalCheckComponent />
      <EditorView />
      
    </EditorInstructionContext.Provider>
  );
}


interface EditorViewProps {
}


export const EditorView: React.FC<EditorViewProps> = (props: EditorViewProps) => {
  const editor = useEditor();
  const enabledAdjuster = useScreenKeyboardAdjusterEnable();
  useKeyBindings(editor);
  
  useEffect(() => {
    enabledAdjuster(true);

    return () => {
      enabledAdjuster(false);
    }
  }, []);

  if ( editor.recoveryMode ) {
    return  <ViewEditorComponent />;
  }

  return <EditorViewCore {...props} />;
}


export const EditorViewCore: React.FC<EditorViewProps> = (props: EditorViewProps) => {
  const [statesLoaded, setStatesLoaded] = useState<boolean>(false);
  const document = useDocState();
  const isPrintout = useIsPrintout();

  useEditorLeave(document);

  const { 
    showProgressModal, 
    hideProgressModal
  } = useProgressModal();

  useEffect(() => {
    showProgressModal();
  }, []);

  const handleStatesLoaded = () => {
    setStatesLoaded(true);
    hideProgressModal();
  }

  return (
    <>
      { 
        ! statesLoaded &&
        <LoadersComponent 
          onDone={handleStatesLoaded}
        />
      }

      {
        statesLoaded && 
        ! isPrintout &&
        <ViewEditorComponent />
      }

      {
        statesLoaded && 
        isPrintout   &&
        <ViewPrintoutComponent />
      }
    </>
  )
}