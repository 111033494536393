import { MenuItemType } from 'lego-v2/menu';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { Icon } from "app/ui/icons/icons";
import useFunctions from './__use-functions';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();

  const getDuplicateImages = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('images, duplicate'),
      iconComponent: Icon.Duplicate,
      onClick: (event: React.MouseEvent) => fns.duplicateImages(),
    }
  });

  const getRemoveImages = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('images, remove'),
      iconComponent: Icon.Delete,
      onClick: (event: React.MouseEvent) => fns.removeImages(),
      variant: 'delete'
    }
  });


  return {
    getDuplicateImages,
    getRemoveImages,
  }
}


export default useItems;