import React from 'react';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from './use-editor-states-setters';


export const useContextPanel = () => {
  const docState = useDocState();

  const {
    setContextPanelSession,
  } = useEditorStatesSetters();

  const showPanel = (props: {
    event?: React.MouseEvent,
    title: string,
    content: React.ReactNode,
  }) => {
    const {
      event, 
      title,
      content,
    } = props;

    event?.preventDefault();
    event?.stopPropagation();

    const update = {
      visible: true,
      content,
      title,
    };

    docState.contextPanelSession.updateWindow(update);
    setContextPanelSession();
  }

  const hidePanel = () => {
    const update = {
      visible: false,
      content: <React.Fragment />,
      title: "",
    };

    docState.contextPanelSession.updateWindow(update);
    setContextPanelSession();
  }

  return {
    showPanel,
    hidePanel,
  };
}


export default useContextPanel;
