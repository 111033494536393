import React from 'react';

import { ItemsSelectedBaseTypes } from 'app/arch/editor-instruction/document/states/sessions/_parts/items-selected-base';
import TopToolbarComponent from 'app/ui-v2/editor-instruction/blocks/top-toolbar';
import useHeaderMarkersMarkerMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/header-markers/use-header-markers-marker-menu';
import useHeaderMarkersMarkersMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/header-markers/use-header-markers-markers-menu';
import { HeaderMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/header-markers';


interface Props {
  markersSelected: ItemsSelectedBaseTypes.SelectedItems;
}


export const HeaderMarkersMenusComponent: React.FC<Props> = (props: Props) => {
  const {
    markersSelected,
  } = props;

  const markersSelectedKeys = Object.keys(markersSelected);
  const markersSelectedCount = markersSelectedKeys.length;
  
  const getConfigMarker  = useHeaderMarkersMarkerMenu();
  const getConfigMarkers = useHeaderMarkersMarkersMenu();
  
  const markerAddr = (
    markersSelectedCount === 1 ? 
    HeaderMarkersTools.markerKeyToAddr(markersSelectedKeys[0]) :
    null
  );

  const menuConfig = (
      markersSelectedCount === 1  ?
      getConfigMarker(markerAddr !) :
      getConfigMarkers() // multi select
  );

  return (
    <TopToolbarComponent sections={menuConfig.sections} />
  );
}
  
