import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';

import { useDocState } from 'app/ui/contexts/document';
import { UIState_EditorSession, UIState_HeaderFields } from 'app/ui/states/editor-instruction';
import { UIState_HeaderFieldsSignals } from 'app/ui/states/editor-instruction';
import { Column }             from 'app/ui-v2/editor-instruction/views/shared/components/columns-resizers/types';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useColumnsWidthSignal  from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/columns/use-columns-width-signal';
import HLS_ColumnsResizersComponent from 'app/ui-v2/editor-instruction/views/shared/components/columns-resizers';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';


interface Props {
  debug?: boolean;
}


export const ColumnsResizersComponent: React.FC<Props> = (props: Props) => {
  const dndContext = DNDContext.viewContent.customFields.column;
  const isDNDEnabled = useRecoilValue(UIState_EditorSession.isDNDContextEnabled(dndContext));
  
  if ( isDNDEnabled ) {
    return null;
  }

  
  return (
    <EnableComponent {...props} />
  );
}
 

const EnableComponent: React.FC<Props> = (props: Props) => {
  const { 
    debug
  } = props;

  const document = useDocState();
  
  const { 
    setHeaderFields,
  } = useEditorStatesSetters();

  // This send signal that columns widths have changed.
  const signalColumnsWidths = useColumnsWidthSignal();
  
  // This rerender is needed for remaining pages.
  useRecoilValue(UIState_HeaderFieldsSignals.columnsWidths);

  // Track ColumnsAddrs
  const columnsAddrs = useRecoilValue(UIState_HeaderFields.columnsAddrs);

  const columnsResizers: Column[] = columnsAddrs.map((columnAddr) => {
    const columnProps = document.headerFields.getColumnProps(columnAddr);
    const columnResizer = {
      id: columnAddr.columnId,
      width: columnProps.width,
    }

    return columnResizer;
  });
  
  const handleSetColumnsWidth = (columns: Column[]) => {
    columns.forEach((column) => {
      const columnId = column.id;
      const columnAddr: Types.ColumnAddr = { columnId };
      const width = column.width;
      
      document.headerFields.updateColumn(columnAddr, { width });
    });

    setHeaderFields();
    signalColumnsWidths();
  }

  const handleResizeEnd = () => {
    document.saveUndo();
  }

  
  return (
    <HLS_ColumnsResizersComponent
      columns={columnsResizers}

      onSetColumnsWidth={handleSetColumnsWidth}
      onResizeEnd={handleResizeEnd}

      debug={debug}
      dataTest={'editor__view-content__header-fields'}
    />
  );
}
 