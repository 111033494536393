import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { DNDContext }   from 'app/ui-v2/editor-instruction/dnd-context';
import DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import ImagesDraggedViewComponent from './images-dragged-view';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';



interface Props {
  image: RepoImagesTypes.Image;
  children: React.ReactNode;
}


export const ImageDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    image, 
    children, 
  } = props;
  
  const docState = useDocState();
  const setDNDData = useDNDDataSet();
  const { hideMenu } = useContextMenu();

  const {
    setGalleryImagesSelected,
  } = useEditorStatesSetters();

  const handleDragStart = () => {
  }


  const handleDragMoveFirst = () => {
    hideMenu();

    const imagesSelected = docState.galleryImageSelected.getSelected();
    const imagesKeys = Object.keys(imagesSelected);
    const imagesIds = imagesKeys.map(imageKey => +imageKey);
    
    if ( ! imagesIds.includes(image.bid) ) {
      imagesIds.splice(0, 0, image.bid);
      docState.galleryImageSelected.select(image.bid);
      setGalleryImagesSelected();
    }

    const data = {imagesGids: imagesIds}
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  return (
    <DraggerComponent
      dndContext={DNDContext.image.gallery}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      draggedView={
        <ImagesDraggedViewComponent />
      }
    >
      { children }
    </DraggerComponent>
  );
}
