import { MenuConfig   } from 'lego-v2/menu/config';
import { HeaderFieldsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import useItems from './__use-items';


const useColumnMenu = () => {
  const items = useItems();

  const getConfig = (pageColumnAddr: HeaderFieldsTypes.PageColumnAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getColumnCreate(pageColumnAddr),
          ],
        },
        {
          items: [
            items.getColumnProperties(pageColumnAddr),
          ],
        },
        {
          items: [
            items.getColumnDelete(pageColumnAddr),
          ],
        },        
      ]
    }

    return config;
  }


  const hook = (pageColumnAddr: HeaderFieldsTypes.PageColumnAddr) => {
    const config = getConfig(pageColumnAddr);
    return config;
  }

  return hook;
}


export default useColumnMenu;