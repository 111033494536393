import { PanelControlsConfig } from "lego/components/panel-controls/config";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useItems from "./__use-items";


const usePanelDocHeaderConfig = () => {
  const t = useTranslations();
  const items = useItems();

  const getConfig = (): PanelControlsConfig => ({
    items: [
      {
        sections: [
          {
            title: t('page'),
             items: [
               items.getPageOrientation(),
               items.getPageSize(),
             ]
           },          {
           title: t('page header'),
            items: [
              items.getDocMetaVisible(),
              items.getReleaselogVisible(),
            ]
          },
        ]
      },
    ]
  });

  return getConfig;
}


export default usePanelDocHeaderConfig;
