import React from 'react';

import LogoViewComponent from './logo/logo-view';

import DocumentTitleComponent       from './document-title';
import DocumentReleaseInfoComponent from './document-release-info';

import { MainWrapper }                 from './styles';
import { MainRow }                     from './styles';
import { LogoWrapper }                 from './styles';
import { DocumentInfoWrapper }         from './styles';
import { DocumentRevisionInfoWrapper } from './styles';
import { SeparatorVertical }           from './styles';


interface Props {
  logoVisible: boolean;
  revisionInfoVisible: boolean;
}


export const HeaderInfoContentComponent: React.FC<Props> = (props: Props) => {
  const {
    logoVisible,
    revisionInfoVisible,
  } = props;

  
  return (
    <MainWrapper>

      <MainRow>
        { /*  Doc logo */ }
        {
          logoVisible &&
          <React.Fragment>
            <LogoWrapper >
              <LogoViewComponent />
            </LogoWrapper>

            <SeparatorVertical />
          </React.Fragment>
        }

        { /*  Doc info */ }
        <DocumentInfoWrapper>
          <DocumentTitleComponent />
        </DocumentInfoWrapper>

        { /* Doc revision */ }
        {
          revisionInfoVisible &&
          <React.Fragment>
            <SeparatorVertical />

            <DocumentRevisionInfoWrapper>
              <DocumentReleaseInfoComponent />
            </DocumentRevisionInfoWrapper>

          </React.Fragment>
        }

      </MainRow>
    </MainWrapper>
  );
}
  
