import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import { PageFormatType, PageOrientationType } from 'app/arch/types/types';
import { useDocState }  from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import useCustomFieldsColumnsAutoAdjust from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/columns/use-columns-auto-adjust';
import useContentColumnsAutoAdjust      from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/columns/use-columns-auto-adjust';
import useReleaselogsColumnsAutoAdjust  from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/columns/use-releaselogs-columns-auto-adjust';


const useFunctions = () => {
  const docState = useDocState();
  const docReleaselogsColumnsAutoAdjust = useReleaselogsColumnsAutoAdjust()
  const docContent2ColumnsAutoAdjust    = useContentColumnsAutoAdjust();
  const contentCustomFieldsAutoAdjust   = useCustomFieldsColumnsAutoAdjust();

  const autoAdjustColumns = () => {
    docReleaselogsColumnsAutoAdjust();
    docContent2ColumnsAutoAdjust();
    contentCustomFieldsAutoAdjust();
  }

  const {
    setViewsCommon,
    setReleaselogsView,
  } = useEditorStatesSetters();

  const setPageOrientation = (orientation: PageOrientationType) => {
    docState.viewsCommon.updatePageLayout({orientation});
    setViewsCommon();
    autoAdjustColumns();
    docState.saveUndo();
  }

  const setPageFormat = (format: PageFormatType) => {
    docState.viewsCommon.updatePageLayout({format});
    setViewsCommon();
    autoAdjustColumns();
    docState.saveUndo();
  }

  const setMetaVisible = (visible: TypesShared.PageItemVisibility) => {
    docState.releaselogsView.updateHeaderMeta({visible});
    docState.saveUndo();
    setReleaselogsView();
  }

  const setReleaselogVisible = (visible: TypesShared.PageItemVisibility) => {
    docState.releaselogsView.updateHeaderTitle({visible});
    docState.saveUndo();
    setReleaselogsView();
  }


  return {
    setPageOrientation,
    setPageFormat,
    setMetaVisible,
    setReleaselogVisible,
  }
}


export default useFunctions;