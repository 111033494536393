import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';
import useKeyBindingsAdd from 'lego/components/key-bindings/hooks/use-key-bindings-add';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useFunctions from './__use-functions';

/**
 * Cell kb
 */

const useKeyBindings = (cellAddr: ContentTypes.CellAddr) => {
  const fns = useFunctions(cellAddr);

  const {
    addBindings,
    removeBindings
  } = useKeyBindingsAdd([
    {
      check: (event: any) => {
        return [
          'Delete',
          'Backspace'
        ].includes(event.key);
      },
      callback: fns.resetCell,
      priority: KeyBindingPriority.EDITOR_INSTRUCTION
    },
  ]);

  return {
    addBindings,
    removeBindings
  }
}

export default useKeyBindings;
