import { MenuConfig } from 'lego-v2/menu/config';
import useItems from './__use-items';


const useCellImagesImagesMenu = () => {
  const items = useItems();


  const getConfig = (): MenuConfig => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getDuplicateImages(),
          ],
        },        
        {
          items: [
            items.getRemoveImages(),
          ],
        },
      ]
    }

    return config;
  }


  const hook = (): MenuConfig => {
    const config = getConfig();
    return config;
  }

  return hook;
}


export default useCellImagesImagesMenu;