import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";
import useSelectedReset from "../use-selected-reset";
import useWidgetLogger  from "./use-widget-logger";


const useWidgetPartSelect = () => {
  const document = useDocState();
  const logger   = useWidgetLogger();
  const resetSelected = useSelectedReset();

  const {
    setEditorImageSession
  } = useEditorStatesSetters();


  const selectWidgetPart = (props: {
    widgetAddr: ContentTypes.WidgetAddr,
    widgetPart: ContentTypes.WidgetPart,
    editDisabled: boolean,
  }) => {
    const {
      widgetAddr,
      widgetPart,
      editDisabled,
    } = props;

    logger.debug(`Widget part ${widgetPart}, select`);

    if ( editDisabled ) {
      logger.debug(`Editing disable, skip select`);
      return;
    }

    const selectedPart = document.editorImageSession.getWidgetPartSelected(widgetAddr);
    if (selectedPart === widgetPart) {
      logger.debug(`Widget part ${widgetPart}, already selected`);
      return;
    }

    const tailSelectRequested = (widgetPart === ContentTypes.WidgetPart.ARROW_TAIL);
    const edited = document.editorImageSession.isWidgetEdited(widgetAddr);
    if ( edited && tailSelectRequested ) {
      logger.debug(`Widget is edited, skip select`);
      return;
    }

    resetSelected({skipStateUpdate: true});
    document.editorImageSession.setWidgetSelected(widgetAddr);
    document.editorImageSession.setWidgetPartSelected(widgetPart);

    setEditorImageSession();
  }

  return selectWidgetPart;
}

export default useWidgetPartSelect;