import { useTranslations }   from 'app/ui/hooks/app/use-translation';
import useDocumentImageDeleteBase from './use-document-image-delete-base';


export const useDocumentImageDelete = () => {
  const t = useTranslations();

  const deleteImage = useDocumentImageDeleteBase({
    title: t("image delete msgbox, title"),
    message: t("image delete msgbox, message"),
  }, {
    startMsg: t("image, deleting"),
    stopMsg:  t("image, deleted"),
  });
  

  return deleteImage;
}


export default useDocumentImageDelete;
