import React from 'react';
import { useRecoilValue } from 'recoil';
import { UIState_HeaderyMarkersSelected } from 'app/ui/states/editor-instruction';
import HeaderMarkersMenusComponent from './header-markers-menu';


interface Props {
}


export const HeaderItemsMenuComponent: React.FC<Props> = (props: Props) => {
  const markersSelected = useRecoilValue(UIState_HeaderyMarkersSelected.selected);
  const showMarkers = Object.keys(markersSelected).length > 0;

  return (
    <>
      {
        showMarkers && 
        <HeaderMarkersMenusComponent markersSelected={markersSelected} />
      }
    </>
  );
}
  

