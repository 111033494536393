import styled from "styled-components";


interface Props {
  left: number,
  top: number,
}


export const SubmenuPositioner = styled.div<Props>`
  height: 0;
  width: 0;
  position: relative;

  top: ${props => props.top - 30 }px;
  left: ${props => props.left}px;
`;

export const SubmenuDetacher = styled.div`
  position: absolute;
  left: calc(100% - 20px);
  z-index: 1;
`;
