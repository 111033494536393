import React from 'react';
import { useEffect } from 'react';
import { useRef }    from 'react';
import { useState }  from 'react';
import { IconContext } from 'react-icons';

import ListComponent from 'lego-v2/list';
import Separator     from 'lego/styles/separator';
import { useIsMobile } from 'lego-hooks/use-is-mobile';

import { settings } from 'app/configs';

import { MenuConfig   } from './config';
import { MenuItem     } from './config';
import { MenuItemType } from './config';
import { MenuSection  } from './config';
import { MenuItemSubmenuData } from './config';
import MenuItemComponent  from './menu-item';
import MenuTitleComponent from './menu-title';
import SubmenuComponent from './submenu';

import { MainWrapper }       from './styles';
import { StyledList }        from './styles';
import { MenuPanelBase }     from './styles';


const HIDEOUT_DELAY = 5;
const AUTO_HIDEOUT_DELAY = settings.context.autohide.delay;


let timeoutHandler: NodeJS.Timeout | null = null;


const resetTimeout = () => {
  if (timeoutHandler) {
    clearTimeout(timeoutHandler);
    timeoutHandler = null;
  }
}

interface Props {
  dataTest?: string,
  config: MenuConfig;
  onSelected?:   any;
  onDeselected?: any;
  parentMenu?:   any;
  onClose?: () => void;

  Panel?: any;
}


export const MenuComponent = React.forwardRef((
  props: Props, 
  ref: React.Ref<HTMLDivElement>
) => {
  const { 
    dataTest,
    config,
    onSelected,
    onDeselected,
    onClose,
    parentMenu,
    
    Panel,
  } = props;

  const hideTimeoutHandler = useRef<any | null>(null);
  const [selectedSubmenu, setSelectedSubmenu] = useState<MenuItem | null>(null);

  const MenuPanel = Panel || MenuPanelBase;
  const isMobile = useIsMobile();

  useEffect(() => {
    resetTimeout();

    timeoutHandler = setTimeout(() => {
      onClose?.();
      timeoutHandler = null;
    }, AUTO_HIDEOUT_DELAY);

    return () => {
      resetTimeout();
    }
  }, []);

  const renderMenuItems = (items: MenuItem[]) => {
    const itemsComponent = items.map((item, idx) => {
      if (item.skip) {
        return null;
      }

      switch (item.type) {
        case MenuItemType.BUTTON:
          return renderMenuItem(item, idx);

        case MenuItemType.SUB_MENU: 
          return renderSubmenu(item, idx);

        default: {
          const msg = `Unknown menu item type: ${item.type}`;
          throw new Error(msg);
        }
      }
    });

    return itemsComponent;
  }

  const clearTimeoutHandler = () => {
    if ( hideTimeoutHandler.current !== null ) {
      clearTimeout(hideTimeoutHandler.current);
      hideTimeoutHandler.current = null;
    }
  }

  const handleSubmenuSelected = (item: MenuItem | null) => {
    if ( item !== null ) {
      clearTimeoutHandler();
      setSelectedSubmenu(item);
    }
    else {
      if ( hideTimeoutHandler.current !== null ) clearTimeoutHandler();
      if ( selectedSubmenu === null ) return;

      hideTimeoutHandler.current = setTimeout(() => {
        hideTimeoutHandler.current = null;
        setSelectedSubmenu(null);
      }, HIDEOUT_DELAY);

      return;
    }
  }

  const handleSubmenuPointerLeave = () => {
    if ( isMobile ) {
      return;
    }

    handleSubmenuSelected(null);
  }

  const notifyParentSelected = () => {
    resetTimeout();

    if (parentMenu !== undefined && onSelected !== undefined) {
      onSelected(parentMenu);
    }
  }

  const notifyParentDeselected = () => {
    if (isMobile) {
      return;
    }
    
    if (parentMenu !== undefined && onDeselected !== undefined) {
      onDeselected(null);
    }
    else if (parentMenu === undefined && onClose !== undefined) {
      // It is root menu
      onClose();
    }
  }

  const renderSubmenu = (item: MenuItem, idx: number) => {
    const data: MenuItemSubmenuData = (item.data as any);

    const IconComponent = (
      item.data.iconComponent ?
      item.data.iconComponent :
      React.Fragment
    );
  
    return (
      <div
        key={idx}
        onPointerEnter={() => handleSubmenuSelected(item) }
        onPointerLeave={() => handleSubmenuPointerLeave() }
      >
        <MenuItemComponent 
          variant={item.data.variant}
          IconComponent={IconComponent}
          title={item.data.title}
          onSelected={item.data.onClick}
        />
      
      { 
        selectedSubmenu === item &&
        <SubmenuComponent
          config={data}
          parentMenu={item}
          onSelected={handleSubmenuSelected}
          onDeselected={handleSubmenuSelected}
        />
      }
      </div>
    );    
  }

  const renderMenuItem = (item: MenuItem, idx: number) => {
    const IconComponent = (
      item.data.iconComponent ?
      item.data.iconComponent :
      React.Fragment
    );

    return (
      <div
        key={idx}
        onPointerLeave={() => handleSubmenuPointerLeave() }
      >
        <MenuItemComponent 
          variant={item.data.variant}
          IconComponent={IconComponent}
          title={item.data.title}
          onSelected={item.data.onClick}
        />
      </div>
    );
  };

  const renderSections = (sections: MenuSection[]) => {
    const sectionsComponent = sections.map((section, idx) => {
      if (section.skip) {
        return null;
      }

      const moreSections = () => {
        for (let i = idx + 1; i < sections.length; i++) {
          if ( ! sections[i].skip ) {
            return true;
          }
        }

        return false;
      }

      return (
        <React.Fragment key={`${idx}`}>

          <ListComponent component={StyledList}>
          { renderMenuItems(section.items) }
          </ListComponent>
          {
            moreSections() && 
            <Separator key={idx} />
          }
        </React.Fragment>
      );
    });

    return sectionsComponent;
  }

  return (
    <IconContext.Provider 
      value={{ 
        size: `${settings.icons.menu.size}px`, 
        style: { 
          // margin: '0.5em' 
        }
      }}
    >
      <MainWrapper
        data-test={dataTest}
        onPointerEnter={notifyParentSelected}
        onPointerLeave={notifyParentDeselected}
      >
        <MenuPanel ref={ref}>
          {/* <MenuTitleComponent title={config.title} /> */}
          { renderSections(config.sections) }
        </MenuPanel>
      </MainWrapper>
    </IconContext.Provider>
  );
});
