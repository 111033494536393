import React from 'react';
import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';
import HLS_DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import Tools from '../tools';
import CellIndexDraggedViewComponent from './cell-index-dragged-view';
import { DraggerWrapper } from './styles';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
  children: React.ReactNode;
}


export const CellIndexDraggerComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children
  } = props;

  const setDNDData = useDNDDataSet();
  const { hideMenu } = useContextMenu();

  const handleDragStart = () => {
    const data = { cellAddr };
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  const handleDragMoveFirst = () => {
    hideMenu();
  }

  const dndContext = Tools.getDNDContext(cellAddr.releaselogId);

  return (
    <HLS_DraggerComponent
      dndContext={dndContext}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      Wrapper={DraggerWrapper}
      draggedView={
        <CellIndexDraggedViewComponent cellAddr={cellAddr} />
      }
    >
      { children }
    </HLS_DraggerComponent>
  );
}
