import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useReleaselogPropsPanel from '../use-releaselog-props-panel';


const useFunctions = () => {
  const showReleaselogPropsPanel = useReleaselogPropsPanel();

  const {
    hideMenu
  } = useContextMenu();


  const showProperties = () => {
    showReleaselogPropsPanel();
    hideMenu();
  }


  return {
    showProperties,
  };
}


export default useFunctions;