import styled, { keyframes } from "styled-components";

// Keyframes
const l9_0 = keyframes`
  0%, 10%   {transform: translateY(0%) scaleY(1);}
  49.99%    {transform: translateY(-50%) scaleY(1);}
  50%       {transform: translateY(-50%) scaleY(-1);}
  90%, 100% {transform: translateY(-100%) scaleY(-1);}
`;

const l9_1 = keyframes`
  10%, 90%  {transform: rotate(0deg);}
  50%       {transform: rotate(calc(var(--s, 1) * 180deg));}
`;

// Styled Component
const AnimSquareSplit = styled.div`
  width: 20px;
  height: 10px;
  background: ${props => props.theme.defs.accentColor.primary};
  position: relative;
  animation: ${l9_0} 1.5s infinite linear;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background: inherit;
    bottom: 100%;
    width: 50%;
    height: 100%;
    animation: inherit;
    animation-name: ${l9_1};
  }

  &::before {
    left: 0;
    transform-origin: bottom left;
    --s: -1;
  }

  &::after {
    right: 0;
    transform-origin: bottom right;
  }
`;

export default AnimSquareSplit;
