import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useDocumentImageDelete from 'app/ui-v2/editor-instruction/hooks/use-document-image-delete';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = () => {
  const docState = useDocState();
  const deleteDocumentImage = useDocumentImageDelete();

  const {
    setGalleryImagesSelected,
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();


  const deleteImage = (imageId: RepoImagesTypes.ImageId) => {
    deleteDocumentImage({
      imagesIds: [imageId],
      onDone: () => {
        docState.galleryImageSelected.reset();
        setGalleryImagesSelected();
      }
    });

    hideMenu();
  }

  return {
    deleteImage,
  };
}


export default useFunctions;