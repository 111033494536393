import React from 'react';
import { useRecoilValue } from 'recoil';
import { UIState_GalleryImagesSelected } from 'app/ui/states/editor-instruction';
import { UIState_GalleryMarkersSelected } from 'app/ui/states/editor-instruction';

import ImagesMenusComponent from './images-menus';
import MarkersMenusComponent from './markers-menus';


interface Props {
}


export const GalleryItemsMenuComponent: React.FC<Props> = (props: Props) => {
  const imagesSelected = useRecoilValue(UIState_GalleryImagesSelected.selected);
  const showImages = Object.keys(imagesSelected).length > 0;

  const markersSelected = useRecoilValue(UIState_GalleryMarkersSelected.selected);
  const showMarkers = Object.keys(markersSelected).length > 0;

  return (
    <>
      {
        showImages && 
        <ImagesMenusComponent imagesSelected={imagesSelected} />
      }
      {
        showMarkers && 
        <MarkersMenusComponent markersSelected={markersSelected} />
      }
    </>
  );
}
  

