import React         from 'react';
import { useEffect } from 'react';

import useKeyBindings         from 'lego/components/key-bindings/hooks/use-key-bindings';
import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';
import { useTranslations }    from 'app/ui/hooks/app/use-translation';
import useGClick from 'app/ui-v2/editor-instruction/hooks/use-gclick';

import { Button }    from './styles';
import { Content }   from './styles';
import { Panel }     from './styles';
import { Separator } from './styles';


interface Props {
  onCancel?: () => void;
  onCreate?: () => void;
  children: React.ReactNode;
}


export const ElementCreatePanelComponent: React.FC<Props> = (props: Props) => {
  const {
    onCreate,
    onCancel,
    children,
  } = props;

  const t = useTranslations();

  const { 
    addKeyDownBinding, 
    removeKeyDownBinding,
  } = useKeyBindings();

  useGClick({
    enabled: true,
    onClick: (event: any) => {
      onCancel?.();
    }
  });

  useEffect(() => {
    const idEscape =  addKeyDownBinding({
      check: (event: React.KeyboardEvent) => event.key === "Escape",
      callback: () => { 
        onCancel?.(); 
        return true;
      },
      priority: KeyBindingPriority.CONTEXT_LAYER
    });

    const idEnter =  addKeyDownBinding({
      check: (event: React.KeyboardEvent) => event.key === "Enter",
      callback: () => { 
        onCreate?.(); 
        return true;
      },
      priority: KeyBindingPriority.CONTEXT_LAYER
    });

    return () => {
      removeKeyDownBinding(idEscape);
      removeKeyDownBinding(idEnter);
    }
  });

  const handleCreate = () => {
    onCreate?.();
  }

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  }

  return (
    <Panel 
      onClick={handleClick}
    >
      <Content>
        { children }
      </Content>

      <Separator />

      <Button onClick={handleCreate} >
        { t("create") }
      </Button>
    </Panel>
  );
}
  
