import styled from "styled-components";

export const DELTA = 10;

export const MainWrapper = styled.div`
  position: relative;
`;

export const ItemWrapper = styled.div<{idx: number}>`
  position: absolute;
  top: ${props => props.idx * DELTA}px;
  left: ${props => props.idx * DELTA}px;
`;
