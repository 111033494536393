import React from 'react';
import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';

import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import HLS_DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';

import HeaderCellDraggedViewComponent from './header-cell-dragged-view';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { useDocState } from 'app/ui/contexts/document';


interface Props {
  pageColumnAddr: Types.PageColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageColumnAddr,
    children
  } = props;

  const setDNDData = useDNDDataSet();
  const docState = useDocState();
  const { hideMenu } = useContextMenu();

  const {
    setCustomFieldsColumnsSelected,
  } = useEditorStatesSetters();

  const handleDragStart = () => {
    const data = { columnAddr: pageColumnAddr };
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  const handleDragMoveFirst = () => {
    hideMenu();
    docState.customFieldsColumnsSelected.reset();
    docState.customFieldsColumnsSelected.select(pageColumnAddr);
    setCustomFieldsColumnsSelected();
  }

  return (
    <HLS_DraggerComponent
      dndContext={DNDContext.viewContent.customFields.column}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      draggedView={
        <HeaderCellDraggedViewComponent
          pageColumnAddr={pageColumnAddr}
        />
      }
    >
      { children }
    </HLS_DraggerComponent>
  );
}
