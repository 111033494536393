import React from 'react';
import { useRecoilValue } from 'recoil';
import { UIState_ContentCellImagesSelected } from 'app/ui/states/editor-instruction';
import { UIState_ContentCellMarkersSelected } from 'app/ui/states/editor-instruction';

import CellImagesItemsMenusComponent from './cell-images-items-menus';
import CellMarkersItemsMenusComponent from './cell-markers-items-menus';


interface Props {
}


export const ContentCellItemsMenuComponent: React.FC<Props> = (props: Props) => {
  const imagesSelected = useRecoilValue(UIState_ContentCellImagesSelected.selected);
  const showCellImages = Object.keys(imagesSelected).length > 0;

  const markersSelected = useRecoilValue(UIState_ContentCellMarkersSelected.selected);
  const showCellMarkers = Object.keys(markersSelected).length > 0;

  return (
    <>
      {
        showCellImages && 
        <CellImagesItemsMenusComponent imagesSelected={imagesSelected} />
      }
      {
        showCellMarkers && 
        <CellMarkersItemsMenusComponent markersSelected={markersSelected} />
      }
    </>
  );
}
  

