import React from 'react';
import ProgressModalViewComponent from 'lego-v2/progress-modal/progress-modal-view';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import AnimCirclePie from 'libs/anim/circle-pie';
import AnimCogWheel from 'libs/anim/cog-wheel';


interface Props {
}


export const ProgressViewComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  return (
    <ProgressModalViewComponent 
      text={t("export xls, progress, msg")}
      Loader={AnimCogWheel}
    />
  );
}

