import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { HeaderMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/header-markers';


const useFunctions = () => {
  const docState = useDocState();

  const {
    setContent,
    setRepoMarkers,
    setHeaderMarkersSelected,
    setHeaderMarkers,
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();

  const duplicateMarkers = () => {
    const markersSelected = docState.headerMarkersSelected.getSelected();
    const markersAddrs = Object.keys(markersSelected).map(markerKey => HeaderMarkersTools.markerKeyToAddr(markerKey));

    const newMarkersAddrs = markersAddrs.map(markerAddr => {
      const markerProps = docState.headerMarkers.getMarkerProps(markerAddr);
      const newMarkerAddr = docState.repoMarkers.duplicateMarker(markerProps.repoMarkerAddr);
      const newHeaderMarkerAddr = docState.headerMarkers.addMarker(newMarkerAddr);

      return newHeaderMarkerAddr;
    });

    docState.saveUndo();
      
    setRepoMarkers();
    setHeaderMarkers();

    docState.headerMarkersSelected.reset();
    newMarkersAddrs.forEach(markerAddr => docState.headerMarkersSelected.select(markerAddr));
    setHeaderMarkersSelected();

    hideMenu();
  }

  const deleteMarkers = () => {
    const markersSelected = docState.headerMarkersSelected.getSelected();
    const markersAddrs = Object.keys(markersSelected).map(markerKey => HeaderMarkersTools.markerKeyToAddr(markerKey));

    markersAddrs.forEach(markerAddr => {
      const markerProps = docState.headerMarkers.getMarkerProps(markerAddr);
      docState.docMarker_delete(markerProps.repoMarkerAddr);
    });

    setContent();
    setRepoMarkers();
    setHeaderMarkers();

    docState.headerMarkersSelected.reset();
    setHeaderMarkersSelected();

    hideMenu();
  }

  return {
    duplicateMarkers,
    deleteMarkers,
  };
}


export default useFunctions;