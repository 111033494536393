import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { HeaderFieldsTools } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import useColumnsAutoAdjust from '../use-columns-auto-adjust';
import useSelectedReset     from '../../use-selected-reset';


const useFunctions = () => {
  const docState = useDocState();
  const columnsAutoAdjust = useColumnsAutoAdjust();
  const customFieldsResetSelected = useSelectedReset();

  const {
    setHeaderFields,
    setHeaderFieldsSession,
    setCustomFieldsColumnsSelected,
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();


  const clearColumns = () => {
    const columns = docState.customFieldsColumnsSelected.getSelected();
    const columnsKeys = Object.keys(columns);
    columnsKeys.forEach(columnKey => {
      const selected = columns[columnKey];
      if ( ! selected) {
        return;
      }

      const columnAddr = HeaderFieldsTools.pageColumnKeyToAddr(columnKey);
      docState.headerFields.clearColumn(columnAddr);
    });

    docState.saveUndo();
    setHeaderFields();

    hideMenu();
  }


  const deleteColumns = () => {
    const columns = docState.customFieldsColumnsSelected.getSelected();
    const columnsKeys = Object.keys(columns);
    columnsKeys.forEach(columnKey => {
      const selected = columns[columnKey];
      if ( ! selected) {
        return;
      }

      const columnAddr = HeaderFieldsTools.pageColumnKeyToAddr(columnKey);
      docState.headerFields.deleteColumn(columnAddr);
    });

    customFieldsResetSelected({skipStateUpdate: true});
    columnsAutoAdjust({ debounced: false });

    docState.saveUndo();
    setHeaderFields();
    setHeaderFieldsSession();
    setCustomFieldsColumnsSelected();

    hideMenu();
  }


  return {
    clearColumns,
    deleteColumns,
  };
}


export default useFunctions;