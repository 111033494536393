import { UploadSource } from 'app/arch/types/types-v2';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";

import useCellImageUpload from '../use-cell-image-upload';


const useFunctions = () => {
  const { hideMenu } = useContextMenu();
  const uploadCellImage = useCellImageUpload();

  const uploadImageFromDisk = (cellAddr: ContentTypes.CellAddr) => {
    const source   = UploadSource.DISK;
    uploadCellImage({ cellAddr, source });
    hideMenu();
  }
  
  const uploadImageFromCamera = (cellAddr: ContentTypes.CellAddr) => {
    const source   = UploadSource.CAMERA_BACK;
    uploadCellImage({cellAddr, source});
    hideMenu();
  }


  return {
    uploadImageFromDisk,
    uploadImageFromCamera,
  }
}


export default useFunctions;