import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useMarkerEdit from 'app/ui-v2/editor-markers/hooks/use-marker-edit';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';



const useFunctions = () => {
  const docState = useDocState();
  const {
    setRepoMarkers,
    setHeaderMarkers,
    setHeaderMarkersSelected,
  } = useEditorStatesSetters();

  const editMarker__ = useMarkerEdit();

  const {
    hideMenu
  } = useContextMenu();

  const createMarker = (markerAddr: HeaderMarkersTypes.MarkerAddr) => {
    const newMarkerAddr = docState.repoMarkers.addMarker();
    const newHeaderMarkerAddr = docState.headerMarkers.addMarkerRight(newMarkerAddr, markerAddr);
    docState.saveUndo();
    
    setRepoMarkers();
    setHeaderMarkers();

    docState.headerMarkersSelected.reset();
    docState.headerMarkersSelected.select(newHeaderMarkerAddr);
    setHeaderMarkersSelected();

    hideMenu();
  }

  const duplicateMarker = (markerAddr: HeaderMarkersTypes.MarkerAddr) => {
    const markerProps = docState.headerMarkers.getMarkerProps(markerAddr);
    const newMarkerAddr = docState.repoMarkers.duplicateMarker(markerProps.repoMarkerAddr);

    const newHeaderMarkerAddr = docState.headerMarkers.addMarkerRight(newMarkerAddr, markerAddr);
    docState.saveUndo();
    
    setRepoMarkers();
    setHeaderMarkers();

    docState.headerMarkersSelected.reset();
    docState.headerMarkersSelected.select(newHeaderMarkerAddr);
    setHeaderMarkersSelected();

    // editMarker__(newMarkerAddr);
    hideMenu();
  }

  const editMarker = (markerAddr: HeaderMarkersTypes.MarkerAddr) => {
    const markerProps = docState.headerMarkers.getMarkerProps(markerAddr);
    editMarker__(markerProps.repoMarkerAddr);
    hideMenu();
  }

  const removeMarker = (markerAddr: HeaderMarkersTypes.MarkerAddr) => {
    docState.headerMarkers.removeMarker(markerAddr);
    docState.saveUndo();
    setHeaderMarkers();

    docState.headerMarkersSelected.reset();
    setHeaderMarkersSelected();
    
    hideMenu();
  }


  return {
    createMarker,
    duplicateMarker,
    editMarker,
    removeMarker,
  };
}


export default useFunctions;