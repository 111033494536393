import { MenuConfig } from 'lego-v2/menu/config';
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from "app/ui/contexts/document";
import useItems from './__use-items';


const useColumnMenu = (columnAddr: Types.ColumnAddr) => {
  const document = useDocState();
  const items = useItems(columnAddr);


  //---------------
  // Config

  const getConfig = () => {
    const isIdxColumn = document.releaselogs.isColumnIndexType(columnAddr);

    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getColumnAdd(columnAddr),
          ],
        },
        {
          items: [
            items.getProperties(),
          ],
        },
        {
          skip: isIdxColumn,
          items: [
            items.getColumnDelete(columnAddr),
          ],
        },
      ]
    }

    return config;
  }


  const hook = () => {
    const config = getConfig();
    return config;
  }

  return hook;
}


export default useColumnMenu;