import { useRecoilValue } from 'recoil';

import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"

import { ContentTypes }        from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { useTranslations }     from "app/ui/hooks/app/use-translation";
import ControlWidgetStyleSaveComponent  from '../controls/control-widget-style-save';
import ControlWidgetsStylesLibComponent from '../controls/control-widgets-styles-lib';


const useConfigWidgetStyles = (widgetAddr: ContentTypes.WidgetAddr | null) => {
  const t = useTranslations();
  const widgetStyle = useRecoilValue(UIState_Content.cellImages_image_widgetStyle(widgetAddr)) || {};


  const getConfig = (widgetAddr: ContentTypes.WidgetAddr | null) => {
    const disabled = widgetAddr === null;

    const __styles_system = () => (
      {
        title: t('widget new style'),
        type: PanelControlsItemSectionItemType.USER_COMPONENT,
        component: ControlWidgetStyleSaveComponent,
        componentProps: { widgetStyle },
      }
    );

    const __styles_user = () => (
      {
        title: t('widgets styles, lib'),
        type: PanelControlsItemSectionItemType.USER_COMPONENT,
        component: ControlWidgetsStylesLibComponent,
        componentProps: { widgetAddr },
      }
    );


    const config: PanelControlsConfig = {
      disabled: disabled,
      items: [
        {
          title: t('styles'),
          sections: [
            {
              items: [
                __styles_system(),
                __styles_user(),
              ]
            },
          ]
        }
      ]
    } // config

    return config;
  }

  const hook = () => {
    return getConfig(widgetAddr)
  }

  return hook;
}


export default useConfigWidgetStyles;