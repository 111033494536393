import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_ContentCellMarkersSelected } from 'app/ui/states/editor-instruction';
import useCellMarkersMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-markers/use-cell-markers-menu';
import TopToolbarComponent from 'app/ui-v2/editor-instruction/blocks/top-toolbar';

import { CellMenuProps } from '../types';


interface Props extends CellMenuProps {
}


export const CellMarkersMenuComponent: React.FC<Props> = (props: Props) => {
  const markersSelected = useRecoilValue(UIState_ContentCellMarkersSelected.selected);
  const markersSelectedKeys = Object.keys(markersSelected);
  const showMarkersSelectedMenu = markersSelectedKeys.length > 0;
  
  if ( showMarkersSelectedMenu ) {
    return null;
  }

  return (
    <Component {...props} />
  );
}
  

export const Component: React.FC<Props> = (props: Props) => {
  const {
    cellAddr
  } = props;

  const getConfigMarkers = useCellMarkersMenu();
  const menuConfig = getConfigMarkers(cellAddr);

  return (
    <TopToolbarComponent
      sections={menuConfig.sections}
    />
  );
}
  
