import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useItemContextMenu from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-context-menu';
import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useCellImagesImageMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-images/use-cell-images-image-menu';
import useCellImagesImagesMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-images/use-cell-images-images-menu';

import { MainWrapper } from './styles';


interface Props {
  imageAddr: ContentTypes.ImageAddr;
  children: React.ReactNode;
}


export const ImageContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children
  } = props;

  const docState = useDocState();
  const resetAllSelected = useAllSelectedReset();
  const getConfigImage  = useCellImagesImageMenu();
  const getConfigImages = useCellImagesImagesMenu();

  const {
    setContentCellImagesSelected,
  } = useEditorStatesSetters();

  type Addr = ContentTypes.ImageAddr;

  const handleContextMenu_ = useItemContextMenu<Addr>({
    isSelected: (addr: Addr) => docState.contentCellImagesSelected.isSelected(addr),
    isMultiselected: () => docState.contentCellImagesSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    resetOtherSelected: () => resetAllSelected({skipContentCellImagesReset: true}),
    select: (addr: Addr) => {
      docState.contentCellImagesSelected.select(addr);
      setContentCellImagesSelected();
    },
    getConfigItems: () => getConfigImages(),
    getConfigItem: (addr:Addr) => getConfigImage(addr)
  });

  const handleContextMenu = (event: React.MouseEvent) => {
    handleContextMenu_(event, imageAddr);
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
  
