import React from 'react';

import { PrintoutViewTypes } from 'app/arch/editor-instruction/document/states/printout/printout-view';
import { useIsPrintout } from 'app/ui/components/editor-instruction/use-is-printout';

import usePageLayout from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-layout';
import usePageMargins from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-margins';

import BottomToolbarComponent from './components/bottom-toolbar';
import DeskComponent  from './components/desk';
import PagesComponent from './components/pages';
import ViewPageContextComponent from './components/view-page-context';

import { MainColumn }      from './styles';
import { TopMenuWrapper }  from './styles';
import { MainRow }         from './styles';
import { SideMenuWrapper } from './styles';
import { DeskWrapper }     from './styles';
import { ContentWrapper }  from './styles';
import { BottomToolbarWrapper } from './styles';


interface Props {
  TopMenu: React.ElementType;
  SideLeftPanel?: React.ElementType;
  SideRightPanel?: React.ElementType;

  renderPagesContent: () => React.ReactNode[];
  resetSelectedItems: () => void;

  printoutView: PrintoutViewTypes.ViewType;
}


export const ViewPagesComponent: React.FC<Props> = (props: Props) => {
  const { 
    TopMenu,
    SideLeftPanel,
    SideRightPanel,

    renderPagesContent,
    resetSelectedItems,

    printoutView,
  } = props;

  const isPrintout = useIsPrintout();

  const pageLayout = usePageLayout();
  const pageMargins = usePageMargins();

  const handleCancelSelected = () => {
    resetSelectedItems();
  }

  const renderPages = () => (
    <PagesComponent
      pagesDefs={renderPagesContent()}
      pageFormat={pageLayout.format}
      pageOrientation={pageLayout.orientation}
      pageMargins={pageMargins}
      isPrintout={isPrintout}
    />
  );

  const renderView = () => {
    return (
      <MainColumn>
        
        <TopMenuWrapper>
          <TopMenu />
        </TopMenuWrapper>
        
        <MainRow>
          {
            SideLeftPanel &&
            <SideMenuWrapper onPointerDown={handleCancelSelected}>
              <SideLeftPanel />
            </SideMenuWrapper>
          }

          <ContentWrapper>
            <DeskWrapper onPointerDown={handleCancelSelected}> 
              <DeskComponent
                pageFormat={pageLayout.format}
                pageOrientation={pageLayout.orientation}
              >
                { renderPages() }
              </DeskComponent> 
            </DeskWrapper> 
            
            
            <BottomToolbarWrapper>
              <BottomToolbarComponent />
            </BottomToolbarWrapper>
          
          </ContentWrapper>


          {
            SideRightPanel &&
            <SideMenuWrapper>
              <SideRightPanel />
            </SideMenuWrapper> 
          }

        </MainRow>
      </MainColumn>
    );
  }

  const renderRaw = () => {
    return renderPages();
  }


  return (
    <ViewPageContextComponent
      printoutView={printoutView}
    >
      {   isPrintout && renderRaw()  }
      { ! isPrintout && renderView() }
    </ViewPageContextComponent>
  )
}
