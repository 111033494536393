import React, { useEffect, useRef, useState } from 'react';
import { DELTA, MainWrapper } from './styles';
import { ItemWrapper } from './styles';
import { Size } from 'app/arch/types';


interface Props {
  elements: React.ReactNode[];
}


export const CascadeComponent: React.FC<Props> = (props: Props) => {
  const {
    elements,
  } = props;


  const itemsRefs = useRef<(HTMLDivElement | null)[]>([]); // Array of refs
  const [size, setSize] = useState<Size>([0, 0]);


  useEffect(() => {
    if (itemsRefs.current.length === 0) {
      return;
    }

    let maxWidth = 0;
    let maxHeight = 0;

    const items = itemsRefs.current;
    items.map((item, idx) => {
      if ( ! item ) {
        return;
      }
      const bbox = item.getBoundingClientRect();
      const width = bbox.width + idx * DELTA;
      const height = bbox.height + idx * DELTA;

      maxWidth = Math.max(width, maxWidth);
      maxHeight = Math.max(height, maxHeight);
    });
    
    if (maxWidth === size[0] && maxHeight === size[1] ) {
      return;
    }
    
    setSize([maxWidth, maxHeight]);
  }, []);

  return (
    <MainWrapper 
      style={{
        left: `${-1 * size[0] / 2}px`,
        top: `${-1 * size[1] / 2}px`
      }}
    >
      {elements.map((element, idx) => (
        <ItemWrapper
          key={idx}
          idx={idx}
          ref={(el) => (itemsRefs.current[idx] = el)}
        >
          { element }
        </ItemWrapper>
      ))}
    </MainWrapper>
  );
}

