import React from 'react';

import { FlexEditApp } from './styles';
import { FlexEditBig } from './styles';
import { FlexEditSmall } from './styles';
import useScreenSizeVariantWatch from 'app/ui-v2/app/__modules/screen/hooks/use-screen-size-variant-watch';
import { SizeVariant } from 'app/arch/app/screen/size/types';


interface Props {
  textStyle?: "book" | "normal";
  textSize?: number;
  FlexEditText?: any;
}


export const FlexEditNameComponent: React.FC<Props> = (props: Props) => {
  const screen = useScreenSizeVariantWatch();
  const SizeDefaultMap: {[key in SizeVariant]: any} = {
    [SizeVariant.WIDTH_320]: 32,
    [SizeVariant.WIDTH_360]: 36,
    [SizeVariant.WIDTH_390]: 39,
    [SizeVariant.WIDTH_500]: 39,
    [SizeVariant.WIDTH_600]: 39,
  }

  const sizeDefault = SizeDefaultMap[screen];

  const textSize = (
    props.textSize !== undefined ? 
    props.textSize :
    sizeDefault
  );

  const textStyle = props.textStyle || "normal";
  const FlexEditText = props.FlexEditText || FlexEditApp;

  return (
    <FlexEditText>
      <FlexEditBig 
        fontSize={textSize}
        fontFamily={textStyle}
      >F
      </FlexEditBig>

      <FlexEditSmall
        fontSize={textSize}
        fontFamily={textStyle}
      >LEX
      </FlexEditSmall>

      <FlexEditBig 
        fontSize={textSize}
        fontFamily={textStyle}
      >E
      </FlexEditBig>
      
      <FlexEditSmall 
        fontSize={textSize}
        fontFamily={textStyle}
      >DIT.APP
      </FlexEditSmall>
    </FlexEditText>
  );
}
