import { MenuConfig   } from 'lego-v2/menu/config';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useItems from './__use-items';


const useCellMarkersMarkerMenu = () => {
  const items = useItems();


  const getConfig = (markerAddr: ContentTypes.MarkerAddr): MenuConfig => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getCreateMarker(markerAddr),
          ],
        },
        {
          items: [
            items.getEditMarker(markerAddr),
            items.getDuplicateMarker(markerAddr),
          ],
        },
        {
          items: [
            items.getRemoveMarker(markerAddr),
          ],
        },
      ]
    }

    return config;
  }


  const hook = (markerAddr: ContentTypes.MarkerAddr): MenuConfig => {
    const config = getConfig(markerAddr);
    return config;
  }

  return hook;
}


export default useCellMarkersMarkerMenu;