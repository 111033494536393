import produce from 'immer';

import * as Defaults from './defaults';
import * as State from './state';
import * as Tools from './tools';
import * as Types from './types';
import Timeouts from './main/timeouts';
import DeadActions from './main/dead-actions';

const STOP_DELAY = 8000;

class Actions {
  private _state: State.State;
  private _listener: Types.Listener;
  private _timeouts: Timeouts;
  private _deadActions: DeadActions;

  constructor() {
    this._state = State.createInitialState();
    this._timeouts = new Timeouts();
    this._deadActions = new DeadActions();

    this._listener = null;
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */

  addAction(props: Types.ActionCreateProps) {
    const actionAddr = Tools.createActionAddr();

    this._state = produce(this._state, draft => {
      const actionsAddrs = State.getActionsAddrs(draft);
      const actionsProps = State.getActionsProps(draft);
      const actionProps = Defaults.getActionProps(props);
      const actionKey = Tools.getActionKey(actionAddr);

      actionsAddrs.push(actionAddr);
      actionsProps[actionKey] = actionProps;
    });

    this.scheduleTimeout(actionAddr);
    return actionAddr;
  }

  private removeAction(actionAddr: Types.ActionAddr) {
    this._state = produce(this._state, draft => {
      const actionState = State.getActionState(draft, actionAddr);
      const actionsAddrs = State.getActionsAddrs(draft);
      const actionsProps = State.getActionsProps(draft);

      const actionIdx = State.getActionIdx(draft, actionAddr);
      const actionKey = Tools.getActionKey(actionAddr);
      
      actionsAddrs.splice(actionIdx, 1);
      delete actionsProps[actionKey];

      this._timeouts.clearHandler(actionAddr);
      if (actionState === Types.ActionState.STOPPING_TIMEOUT) {
        this._deadActions.addDead(actionAddr);
      }
    });
  }

  updateActionProps(actionAddr: Types.ActionAddr, update: Types.ActionPropsUpdate) {
    this._state = produce(this._state, draft => {
      const actionProps = State.getActionProps(draft, actionAddr);
      Object.assign(actionProps, update);
    });
  }

  addListener(listener: Types.Listener) {
    this._listener = listener;
  }

  removeListener() {
    this._listener = null;
  }

  private runListener() {
    if (this._listener === null) {
      return;
    }

    this._listener(this._state);
  }

  private scheduleTimeout(actionAddr: Types.ActionAddr) {
    const actionProps = this.getActionProps(actionAddr);

    const timeoutHn = setTimeout(() => {
      const actionPresent = this.hasAction(actionAddr);
      if ( ! actionPresent ) {
        console.warn("Action is missing");
        return;
      }

      const actionState = this.getActionState(actionAddr);
      if (actionState !== Types.ActionState.STARTED) {
        const msg = `Invalid action state, expected state STARTED, actual is ${actionState}`;
        console.warn(msg);
        return;
      }

      this.finishAction(actionAddr, Types.ActionState.STOPPING_TIMEOUT);
      this.runListener();
    }, actionProps.timeout * 1000);

    this._timeouts.addHandler(actionAddr, timeoutHn);
  }

  finishAction(
    actionAddr: Types.ActionAddr,
    actionState_?: Types.ActionState
  ) {

    const actionState = (
      actionState_ !== undefined ?
      actionState_ :
      Types.ActionState.STOPPING_FINISHED
    );

    const isDead = this._checkIfDead(actionAddr);
    if ( isDead ) {
      return;
    }

    const timeoutHn = setTimeout(() => {
      const actionPresent = this.hasAction(actionAddr);
      if ( ! actionPresent ) {
        console.warn("Action is missing");
        return;
      }

      this.removeAction(actionAddr);
      this.runListener();
    }, STOP_DELAY);


    this.updateActionProps(actionAddr, {state: actionState});

    // Has to be clear not just remove.
    // As scheduleDelete can be called by 
    // action which has timeout and waiting for deletion.
    this._timeouts.clearHandler(actionAddr);
    this._timeouts.addHandler(actionAddr, timeoutHn);
  }

  deleteAction(actionAddr: Types.ActionAddr) {
    const isDead = this._checkIfDead(actionAddr);
    if ( isDead ) {
      return;
    }

    const actionPresent = this.hasAction(actionAddr);
    if ( ! actionPresent ) {
      console.warn("Action is missing");
      return;
    }

    this.removeAction(actionAddr);
    this.runListener();
  }

  private _checkIfDead(actionAddr: Types.ActionAddr) {
    const isDead = this._deadActions.hasAction(actionAddr)
    if ( ! isDead ) {
      return false;
    }

    // Action notification has timeout and be deleted.
    // However action finished after, and called schedule delete.
    // 
    // TODO we could actually restore this action to display
    // finish action msg. However action property has been deleted by
    // now. 

    console.warn("Action is dead");
    this._deadActions.removeDead(actionAddr)
    return true;
  }

  resetActions() {
    const actionsAddrs = this.getActionsAddrs();
    actionsAddrs.forEach((actionAddr) => {
      this.deleteAction(actionAddr);
    });
  }

  /**
   * 
   * Getters
   * 
   */

  /**
   * Actions
   */
  getActionsAddrs(): Types.ActionsAddrs { 
    return State.getActionsAddrs(this._state);
  }
  
  getActionsProps(): Types.ActionsProps { 
    return State.getActionsProps(this._state);
  }


  /**
   * Action
   */

  getActionProps(
    actionAddr: Types.ActionAddr,
  ): Types.ActionProps { 
    return State.getActionProps(this._state, actionAddr);
  }

  getActionIdx(
    actionAddr: Types.ActionAddr,
  ): number { 
    return State.getActionIdx(this._state, actionAddr);
  }

  getActionState(
    actionAddr: Types.ActionAddr,
  ): Types.ActionState { 
    return State.getActionState(this._state, actionAddr);
  }

  hasAction(
    actionAddr: Types.ActionAddr,
  ): boolean { 
    return State.hasAction(this._state, actionAddr);
  }
}

export default Actions;