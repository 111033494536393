import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';

import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import { useDocState } from 'app/ui/contexts/document';
import HLSDraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import ImagesDraggedViewComponent from './images-dragged-view';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';


interface Props {
  imageAddr: ContentTypes.ImageAddr;
  children: React.ReactNode;
}


export const ImageDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    imageAddr, 
    children
  } = props;

  const docState = useDocState();
  const setDNDData = useDNDDataSet();
  const { hideMenu } = useContextMenu();

  const {
    setContentCellImagesSelected,
  } = useEditorStatesSetters();

  const handleDragStart = () => {
  }

  const handleDragMoveFirst = () => {
    hideMenu();
    const imagesSelected = docState.contentCellImagesSelected.getSelected();
    const imagesKeys = Object.keys(imagesSelected);
    const imagesAddrs = imagesKeys.map(imageKey => ContentTools.imageKeyToAddr(imageKey));
    
    if ( ! docState.contentCellImagesSelected.isSelected(imageAddr) ) {
      imagesAddrs.splice(0, 0, imageAddr);
      docState.contentCellImagesSelected.select(imageAddr);
      setContentCellImagesSelected();
    }

    const data = {imagesAddrs: imagesAddrs}
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  return (
    <HLSDraggerComponent
      debug={false}
      dndContext={DNDContext.image.cell}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      draggedView={
        <ImagesDraggedViewComponent />
      }
    >
      { children }
    </HLSDraggerComponent>
  );
}
