import * as Defaults from './defaults';
import * as Types from './types';


/**
 * State
 */

export type State = {
  window: Types.Window,
};


/**
 * Getters
 */

export const getWindow = (state: State) => state.window;


/**
 * Initial state
 */

export const createInitialState = () => {
  const initState: State = {
    window: Defaults.getWindow(),
  }

  return initState;
}
