import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = () => {
  const docState = useDocState();
  const { hideMenu } = useContextMenu();

  const {
    setContent,
    setContentCellMarkersSelected,
    setRepoMarkers,
  } = useEditorStatesSetters();


  const duplicateMarkers = () => {
    const selected = docState.contentCellMarkersSelected.getSelected();
    const markersKeys = Object.keys(selected);
    const markersAddrs = markersKeys.map(markerKey => ContentTools.markerKeyToAddr(markerKey));

    const newMarkersAddrs: ContentTypes.MarkerAddr[] = [];

    markersAddrs.forEach((markerAddr) => {
      const markerProps = docState.content.cellMarkers_getMarkerProps(markerAddr);
      const newMarkerAddr = docState.repoMarkers.duplicateMarker(markerProps.repoMarkerAddr);
      const cellAddr = markerAddr;

      const newCellMarkerAddr = docState.content.cellMarkers_addMarker(newMarkerAddr, cellAddr);
      newMarkersAddrs.push(newCellMarkerAddr);
    });

    docState.contentCellMarkersSelected.reset();
    newMarkersAddrs.forEach(newMarkerAddr => docState.contentCellMarkersSelected.select(newMarkerAddr));
    setContentCellMarkersSelected();

    setRepoMarkers();

    docState.saveUndo();
    setContent();
    hideMenu();
  }

  const removeMarkers = () => {
    const selected = docState.contentCellMarkersSelected.getSelected();
    const markersKeys = Object.keys(selected);
    const markersAddrs = markersKeys.map(markerKey => ContentTools.markerKeyToAddr(markerKey));

    markersAddrs.forEach((markerAddr) => {
      docState.content.cellMarkers_removeMarker(markerAddr);
    });

    docState.contentCellMarkersSelected.reset();
    setContentCellMarkersSelected();

    docState.saveUndo();
    setContent();
    hideMenu();
  }

  return {
    duplicateMarkers,
    removeMarkers,
  }
}


export default useFunctions;