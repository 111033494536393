import styled from "styled-components";
import ItemSelectedBorder from "lego/styles/item-selected-border";
import MarkerDraggedView from 'app/ui-v2/editor-instruction/views/shared/styles/marker-dragged-view';


export const MainWrapper = styled(ItemSelectedBorder)`
`;

export const Marker = styled(MarkerDraggedView)`
`;
