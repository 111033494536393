import React from 'react';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';

import { useDocState }        from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset       from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useItemMultiSelect from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-multi-select';


import { MarkerWrapper } from './styles';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children,
  } = props;

  const docState = useDocState();
  const resetAllSelected = useSelectedReset();

  const {
    setGalleryMarkersSelected,
  } = useEditorStatesSetters();


  type Addr = RepoMarkersTypes.MarkerAddr;

  const handleSelectItem = useItemMultiSelect<Addr>({
    isSelected: (addr: Addr) => docState.galleryMarkersSelected.isSelected(addr),
    isMultiselected: () => docState.galleryMarkersSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    select: (addr: Addr) => docState.galleryMarkersSelected.select(addr),
    multiSelect: (addr: Addr) => {
      resetAllSelected({skipGalleryMarkersReset: true});
      docState.galleryMarkersSelected.toggleSelect(addr);
    },
    toggleSelect: (addr: Addr) => docState.galleryMarkersSelected.toggleSelect(addr),
    setState: () => {
      setGalleryMarkersSelected();
    }
  });


  const handleSelectMarker = (event: React.MouseEvent) => {
    handleSelectItem(event, markerAddr);
  }

  return (
    <MarkerWrapper onPointerDown={handleSelectMarker}>
      { children }
    </MarkerWrapper>
  );
}
  
