import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import usePagePropsPanel from "../use-page-props-panel";


const useFunctions = () => {
  const {
    hideMenu
  } = useContextMenu();
  
  const showPagePropsPanel = usePagePropsPanel();

  const showPropsPanel = () => {
    showPagePropsPanel()
    hideMenu();
  }

  return {
    showPropsPanel,
  };
}


export default useFunctions;