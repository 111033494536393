import { useEffect } from 'react';

import { mutation }        from 'app/arch/backend';
import { useMutation }     from 'app/arch/backend/use-mutation';
import { MsgDuration }     from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useDocState }     from 'app/ui/contexts/document';
import { useInstruction  } from 'app/ui/hooks/editor-instruction/use-instruction';
import { useTranslations } from 'app/ui/hooks/app/use-translation';


export interface Props {
  silent?: boolean;
  onSaved?: () => void;
}


export const useDocumentSave = (props?: Props) => {
  const onSaved = props?.onSaved ?? null;

  const t = useTranslations();
  const document    = useDocState();
  const instruction = useInstruction();

  const msgsDef = {
    onStart: { msg:  t("saving document"), },
    onEnd: 
    {
      msg: t("document saved"),
      duration: MsgDuration.MIDDLE
    }
  }

  const msgs = (props?.silent ?  {} : msgsDef);

  const { 
    mutation: updateInstruction,
    data: updateInstructionData
  } = useMutation(
    mutation.updateInstruction,
    msgs
  );

  useEffect(() => {
    if ( ! updateInstructionData ) return;
    const ok = updateInstructionData.instructionUpdate?.ok;
    if ( ok ) {
      document.dirty = false;
      onSaved && onSaved();
    }
  }, [updateInstructionData]);
 


  const hookRoutine = () => {
    const content = document.serialize();
    
    updateInstruction({ 
      variables: { 
        id: instruction.id, 
        content,
      } 
    });
  }

  return hookRoutine;
}


export default useDocumentSave;
