import styled from "styled-components";


export const FlexEditApp = styled.div`
  color: #ddd;
  /* background-color: rgba(0, 255, 0, 0.5); */
`;


interface FlexProps {
  fontSize: number;
  fontFamily: string;
}

export const FlexEditBig = styled.span<FlexProps>`
  font-family: ${(props) => {
    const font = `flexedit-${props.fontFamily}`;
    return font;
    }
  };

  font-size: ${props => props.fontSize}px;
`;


export const FlexEditSmall = styled(FlexEditBig)`
  font-size: ${props => props.fontSize / 1.8}px;
`;
