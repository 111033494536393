import React from 'react';
import DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import MarkerDraggedViewComponent from './marker-dragged-view';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  dndContext: string;
  onDragStart: () => void;
  onDragMoveFirst: () => void;

  children: React.ReactNode;
}


export const MarkerDraggerComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    dndContext,
    onDragStart,
    onDragMoveFirst,
    children,
  } = props;


  return (
    <DraggerComponent
      dndContext={dndContext}
      onDragStart={onDragStart}
      onDragMoveFirst={onDragMoveFirst}
      draggedView={
        <MarkerDraggedViewComponent markerAddr={markerAddr}/>
      }
    >
      { children }
    </DraggerComponent>
  );
}
