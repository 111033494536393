import React from 'react';
import usePageContextMenu from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-context-menu';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import { MainWrapper } from './styles';


interface Props {
  children: React.ReactNode,
}


export const PageEditorContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
  } = props;

  const getConfig = usePageContextMenu();

  const {
    showMenu,
    hideMenu,
  } = useContextMenu();

  const handleContext = (event: React.MouseEvent) => {
    showMenu({
      config: getConfig(),
      event,
    });
  }

  return (
    <MainWrapper onContextMenu={handleContext}>
      { children }
    </MainWrapper>
  );
}

