import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useImageDuplicate = () => {
  const docState = useDocState();

  const {
    setContent,
  } = useEditorStatesSetters();

  const hook = (
    imageAddr: ContentTypes.ImageAddr,
  ) => {
    docState.cellImages_duplicateImage(imageAddr);
    docState.saveUndo();
    setContent();
  }

  return hook;
}


export default useImageDuplicate;