import { useState }  from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import ReactDOM from 'react-dom';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import { UIState_Content } from 'app/ui/states/editor-instruction';
import { UIState_ContentCellImagesSelected } from 'app/ui/states/editor-instruction';
import { useDocState } from 'app/ui/contexts/document';
import { useIsPrintout } from 'app/ui/components/editor-instruction/use-is-printout';
import WidgetsViewScaledComponent from 'app/ui-v2/editor-image/hls/widgets-view-scaled';
import useEditorStatesSetters     from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDemoActive              from 'app/ui-v2/app/__modules/demo/hooks/use-demo-active';

import ResizerComponent     from './resizer/resizer';
import { ImageViewUpdate }  from './resizer/types';
import { ImageScaleUpdate } from './resizer/types';


interface Props {
  imageAddr: Types.ImageAddr;
  resizerContainerRef: React.RefObject<HTMLDivElement>;
}


export const ImagePreviewComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    resizerContainerRef,
  } = props;

  const document = useDocState();
  const isPrintout = useIsPrintout();
  const isDemo = useDemoActive();

  const viewAreaGlobal  = useRecoilValue(UIState_Content.cellImages_image_viewArea(imageAddr));
  const viewScaleGlobal = useRecoilValue(UIState_Content.cellImages_image_viewScale(imageAddr));
  
  const selected = useRecoilValue(UIState_ContentCellImagesSelected.isSelected(imageAddr));

  const [viewAreaLocal,  setViewAreaLocal]  = useState<Types.ImageViewArea>(viewAreaGlobal);
  const [viewScaleLocal, setViewScaleLocal] = useState(viewScaleGlobal);
  const [resizerContainer, setResizerContainer] = useState<HTMLDivElement | null>(null);

  const viewResizer = (
    selected
    && ! isPrintout
    && ! isDemo
  );

  const {
    setContent,
    setContentSession,
  } = useEditorStatesSetters();


  useEffect(() => {
    if (resizerContainerRef.current) {
      setResizerContainer(resizerContainerRef.current);
    }
  }, [resizerContainerRef]);


  useEffect(() => {
    if ( 
         viewAreaLocal.x1 !== viewAreaGlobal.x1 
      || viewAreaLocal.x2 !== viewAreaGlobal.x2 
      || viewAreaLocal.y1 !== viewAreaGlobal.y1 
      || viewAreaLocal.y2 !== viewAreaGlobal.y2      
    ) { 
      setViewAreaLocal(viewAreaGlobal); 
    }
  }, [viewAreaGlobal]);


  useEffect(() => {
    if ( viewScaleLocal !== viewScaleGlobal ) {
      setViewScaleLocal(viewScaleGlobal); 
    }
  }, [viewScaleGlobal]);


  const handleUpdateStart = () => {
    document.contentSession.setCellSelected(imageAddr);
    setContentSession();
  }

  //--------------------------------
  // View area update
  //
  const handleViewAreaUpdate = (update: ImageViewUpdate) => {
    setViewAreaLocal(update.viewArea);
  }
 
  const handleViewAreaUpdateDone = (update: ImageViewUpdate) => {
    handleViewAreaUpdate(update);

    document.content.cellImages_updateImage(imageAddr, {
      viewArea: update.viewArea
    });

    document.saveUndo();
    setContent();
  }

  
  //--------------------------------
  // Image scale update
  //
  const handleViewScaleUpdate = (update: ImageScaleUpdate) => {
    setViewScaleLocal(update.scale);
  }
  
  const handleViewScaleUpdateDone = (update: ImageScaleUpdate) => {
    handleViewScaleUpdate(update);

    document.content.cellImages_updateImage(imageAddr, {
      viewScale: update.scale
    });

    document.saveUndo();
    setContent();
  }

  const renderResizer = () => (
    resizerContainer ?
    ReactDOM.createPortal(
      <ResizerComponent 
        imageAddr={imageAddr}
        onUpdateStart={handleUpdateStart}

        viewArea={viewAreaLocal}
        onViewAreaUpdate={handleViewAreaUpdate}
        onViewAreaUpdateDone={handleViewAreaUpdateDone}

        viewScale={viewScaleLocal}
        onViewScaleUpdate={handleViewScaleUpdate}
        onViewScaleUpdateDone={handleViewScaleUpdateDone}
      />,
      resizerContainer
    ) : null
  );

  return (
    <>
      <WidgetsViewScaledComponent
        imageAddr={imageAddr}
        viewScale={viewScaleLocal}
        viewArea={viewAreaLocal}
      />
      { viewResizer && renderResizer() }
    </>
  );
}
