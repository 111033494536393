import { MenuItemType } from 'lego-v2/menu';
import { Icon } from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import useFunctions from './__use-functions';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();

  const getProperties = (sectionAddr: Types.SectionAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('properties'),
      iconComponent: Icon.Settings,
      onClick: (event: React.MouseEvent) => fns.showSectionProperties(sectionAddr),
    }
  });

  const getAddSectionAbove = (sectionAddr: Types.SectionAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('section, add above'),
      iconComponent: Icon.Create,
      onClick: (event: React.PointerEvent) => fns.addSectionAbove(sectionAddr, event),
    }
  });
  
  const getAddSectionBelowe = (sectionAddr: Types.SectionAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('section, add below'),
      iconComponent: Icon.Create,
      onClick: (event: React.PointerEvent) => fns.addSectionBelow(sectionAddr, event),
    }
  });

  const getDeleteSection = (sectionAddr: Types.SectionAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('delete'),
      variant: 'delete',
      iconComponent: Icon.Delete,
      onClick: () =>  fns.deleteSection(sectionAddr),
    }
  });

  return {
    getProperties,
    getAddSectionAbove,
    getAddSectionBelowe,
    getDeleteSection,
  }
}


export default useItems;