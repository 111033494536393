import React from 'react';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useHeaderMarkersMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/header-markers/use-header-markers-menu';
import { MainWrapper } from './styles';


interface Props {
  children: React.ReactNode;
}


export const MarkersContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const { showMenu } = useContextMenu();
  const getConfig = useHeaderMarkersMenu();
  
  const handleContextMenu = (event: React.MouseEvent) => {
    const config = getConfig();
    showMenu({ event, config });
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
