import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDocumentImageDelete from 'app/ui-v2/editor-instruction/hooks/use-document-image-delete';
import useDocumentImagesDelete from 'app/ui-v2/editor-instruction/hooks/use-document-images-delete';


const useFunctions = () => {
  const docState = useDocState();

  const deleteDocumentImage  = useDocumentImageDelete();
  const deleteDocumentImages = useDocumentImagesDelete();


  const {
    setGalleryImagesSelected,
  } = useEditorStatesSetters();
  
  const deleteImages = (event: KeyboardEvent) => {
    const imagesSelected = docState.galleryImageSelected.getSelected();
    const imagesKeys = Object.keys(imagesSelected);
    if ( ! imagesKeys.length ) {
      return false;
    }

    const imagesIds = imagesKeys.map(markerKey => +markerKey);
    if (imagesIds.length === 1) {
      deleteDocumentImage({ 
        imagesIds,
        onDone: () => {
          docState.galleryImageSelected.reset();
          setGalleryImagesSelected();
        }
      });  
    }
    else {
      deleteDocumentImages({ 
        imagesIds,
        onDone: () => {
          docState.galleryImageSelected.reset();
          setGalleryImagesSelected();
        }
      });
    }

    return true;
  }

  return {
    deleteImages,
  }
}

export default useFunctions;