import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';
import { useDocState } from 'app/ui/contexts/document';

import CellImagesMenuComponent  from './cell-images-menu';
import CellIndexMenuComponent   from './cell-index-menu';
import CellMarkersMenuComponent from './cell-markers-menu';
import CellTextMenuComponent    from './cell-text-menu';
import { CellMenuProps } from './types';


interface Props {
}


export const ContentCellMenuComponent: React.FC<Props> = (props: Props) => {
  const cellSelected = useRecoilValue(UIState_ContentSession.cellSelected);

  return (
    <>
      { 
        cellSelected && <__Component cellAddr={cellSelected} />
      }
    </>
  );
}
  


interface CellMenuRenderProps {
  cellAddr: TypesContent.CellAddr;
}

const __Component: React.FC<CellMenuRenderProps> = (props: CellMenuRenderProps) => {
  const {
    cellAddr,
  } = props;

  const document = useDocState();

  const columnProps = document.content.getColumnProps(cellAddr);
  const CellsMenus: {[key in TypesContent.ColumnType]: React.FC<CellMenuProps>} = {
    [TypesContent.ColumnType.IMAGES ]: CellImagesMenuComponent,
    [TypesContent.ColumnType.INDEX  ]: CellIndexMenuComponent,
    [TypesContent.ColumnType.MARKERS]: CellMarkersMenuComponent,
    [TypesContent.ColumnType.TEXT   ]: CellTextMenuComponent,
  };

  const CellMenu = CellsMenus[columnProps.type];

  return (
    <CellMenu cellAddr={cellAddr} />
  );
}
  