import { useRecoilValue } from 'recoil';

import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import { UIState_ContentView } from 'app/ui/states/editor-instruction';
import { UIState_HeaderMeta } from 'app/ui/states/editor-instruction';
import usePageLayout from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-layout';
import usePageMargins from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-margins';


const useStates = () => {
  const visible     = useRecoilValue(UIState_ContentView.headerMetaVisible);
  const visibleNone = (visible === TypesShared.PageItemVisibility.NONE);

  const logoVisible     = useRecoilValue(UIState_HeaderMeta.logoVisible);
  const revisionVisible = useRecoilValue(UIState_HeaderMeta.releaseInfoVisible);
  
  const pageLayout = usePageLayout();
  const pageMargins = usePageMargins();

  return {
    visible,
    visibleNone,

    logoVisible,
    revisionVisible,

    pageLayout,
    pageMargins,
  }
}


export default useStates;