import React from 'react';

import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ItemsSelectedBaseTypes } from 'app/arch/editor-instruction/document/states/sessions/_parts/items-selected-base';
import TopToolbarComponent from 'app/ui-v2/editor-instruction/blocks/top-toolbar';
import useCellImagesImageMenu  from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-images/use-cell-images-image-menu';
import useCellImagesImagesMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-images/use-cell-images-images-menu';


interface Props {
  imagesSelected: ItemsSelectedBaseTypes.SelectedItems;
}


export const CellImagesItemsMenusComponent: React.FC<Props> = (props: Props) => {
  const {
    imagesSelected,
  } = props;

  const imagesSelectedKeys = Object.keys(imagesSelected);
  const imagesSelectedCount = imagesSelectedKeys.length;
  
  const getConfigImage  = useCellImagesImageMenu();
  const getConfigImages = useCellImagesImagesMenu();
  
  const imgAddr = (
    imagesSelectedCount === 1 ? 
    ContentTools.imageKeyToAddr(imagesSelectedKeys[0]) :
    null
  );

  const menuConfig = (
      imagesSelectedCount === 1  ?
      getConfigImage(imgAddr !) :
      getConfigImages() // multi select
  );

  return (
    <TopToolbarComponent sections={menuConfig.sections} />
  );
}
  
