import React from 'react';
import useIsKeyDown from 'lego/components/key-bindings/hooks/use-is-key-down';
import useSystemKeys from 'lego/components/key-bindings/hooks/use-system-keys';


export interface Props<T> {
  isSelected: (addr: T) => boolean;
  isMultiselected: () => boolean;
  resetSelected: () => void;
  select: (addr: T) => void;
  multiSelect: (addr: T) => void;
  toggleSelect: (addr: T) => void;

  setState: (addr?: T) => void;
}

const useItemMultiSelect = <T>({
  isSelected,
  isMultiselected,
  resetSelected,
  select,
  multiSelect,
  toggleSelect,

  setState,
}: Props<T>) => {

  const isKeyDown = useIsKeyDown();
  const systemKeys = useSystemKeys();
  const actionKeys = systemKeys.actionKeys;


  const handleClick = (event: React.MouseEvent, itemAddr: T) => {
    event.stopPropagation();

    if ( event.button !== 0 ) {
      return;
    }

    //
    // User holds down multi select key
    //
    const enabledMultiSelected = isKeyDown(actionKeys.multiItemSelect);
    if ( enabledMultiSelected ) {
      multiSelect(itemAddr);
      setState(itemAddr);
      return;
    }


    // 
    // Are multi items selected
    //
    const isMultiSelected = isMultiselected();
    if (isMultiSelected) {
      resetSelected();
      select(itemAddr);
    }
    else {
      if ( ! isSelected(itemAddr)) {
        resetSelected();
      }
      toggleSelect(itemAddr);
    }

    setState(itemAddr);
  }

  return handleClick;
}


export default useItemMultiSelect; 