import { useEffect } from 'react';
import { useProgressModal } from 'lego-v2/progress-modal';

import environment         from 'app/environment';
import { mutation }        from "app/arch/backend";
import { useMutation }     from "app/arch/backend/use-mutation";
import { MsgDuration, useMsgBox }     from "app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import { useInstruction }  from 'app/ui/hooks/editor-instruction/use-instruction';
import { useDocState }     from 'app/ui/contexts/document';
import usePrintoutReleaseInfoGet from 'app/ui-v2/editor-image/hooks/use-printout-release-info-update';

import { usePDFDownload }      from "../use-pdf-download";
import usePDFGenerateLockCheck from '../use-pdf-generate-lock-check';
import ProgressViewComponent from './progress-view';


const useDocumentPreview = () => {
  const t = useTranslations();
  const document = useDocState();
  const instruction = useInstruction();
  const getPrintoutReleaseInfo = usePrintoutReleaseInfoGet();
  const {
    showProgressModal, 
    hideProgressModal
  } = useProgressModal();

  const { showMsgBox } = useMsgBox();
  
  const downloadPDF = usePDFDownload();
  const getPDFGenerateLock = usePDFGenerateLockCheck();


  // 
  // Download preview PDF
  //
  const donwloadDocumentPreview = (instructionId: number) => {
    const url = environment.backend.getInstructionPreviewUrl(instructionId);
    const showProgress = false;
    
    downloadPDF({url, showProgress}); 
  }

  //
  // Generate document preview PDF
  //
  const { 
    mutation: previewInstructionReleaseMutation,
    error: mutationError,
  } = useMutation(
    mutation.previewInstructionRelease, {
      onEnd: {
        duration: MsgDuration.MIDDLE,
        callback: () => { 
          hideProgressModal();
          donwloadDocumentPreview(instruction.id !);
          showMsgBox(t("document, pdf preview, done"));
        }
      }
    },
  );
  
  useEffect(() => {
    if ( ! mutationError ) {
      return;
    }

    hideProgressModal();
  }, [mutationError]);

  const createInstructionPreview = () => {
    const instructionId     = instruction.id !;
    const contentSerial     = document.serialize();
    const releaseInfoSerial = getPrintoutReleaseInfo();

    previewInstructionReleaseMutation({
      variables: { 
        instructionId, 
        content: contentSerial,
        releaseInfo: releaseInfoSerial,
      }    
    });

    showProgressModal({Component: <ProgressViewComponent />})
  }

  //
  // Whole routine - generate & download
  //
  const hook = () => {
    getPDFGenerateLock({
      onNotLocked: () => {
        createInstructionPreview();
      }
    });
  }

  return hook;
}


export default useDocumentPreview;
