import React from 'react';
import { IconContext } from 'react-icons';

import { settings } from 'app/configs';
import { Icon }     from 'app/ui/icons/icons';
import { Buttons }  from './styles';
import { Button }   from './styles';


interface Props {
  dataTest: string | null;
  isMaximized: boolean;
  maximizable: boolean;
  onToggleMaximize: () => void;
  onClose: () => void;
}


export const WindowButtonsComponent: React.FC<Props> = (props: Props) => {
  const {
    dataTest,
    isMaximized,
    maximizable,
    onToggleMaximize,
    onClose,
  } = props;

  const MinMaxIcon = (
    isMaximized ? 
    Icon.Window.Minimize :
    Icon.Window.Maximize
  );

  const iconSize = settings.icons.window.controls.size;
  
  const getDataTest = (postfix: string) => {
    if (dataTest === null) {
      return '';
    }

    return (
      `${dataTest}`
      + `__${postfix}`
    );
  }

  return (
    <IconContext.Provider 
      value={{ size: `${iconSize}px`, }}
    >
      <Buttons>
      {
        maximizable &&
        <Button
          onClick={onToggleMaximize}
          data-test={getDataTest('btn-toggle-size')}
        >
          <MinMaxIcon />
        </Button>
      }
        <Button 
          onClick={onClose}
          data-test={getDataTest('btn-close')}
        >
          <Icon.Close />
        </Button>
      </Buttons>
    </IconContext.Provider>   
  );
}
  
