import React from 'react';
import { useRecoilValue } from 'recoil';

import { SideControlsTypes }  from 'lego/components/side-controls';
import SidePanelBaseComponent from 'lego/components/side-controls/side-panel';

import { ContentSessionTypes as Types } from 'app/arch/editor-instruction/document/states/sessions/content-session';
import usePanelDocHeaderConfig from 'app/ui-v2/editor-instruction/views/view-content/hooks/panel-properties/panel-doc-header/use-panel-doc-header-config';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';

import usePanelConfigColumns   from 'app/ui-v2/editor-instruction/panel-configs/use-panel-config-columns';


type Props = SideControlsTypes.PropLeftOrRight;


export const SidePanelComponent: React.FC<Props> = (props: Props) => {
  const {
    left, 
    right
  } = props;

  const panel = useRecoilValue(UIState_ContentSession.panelProperties);
  
  const getConfigPageSettings = usePanelDocHeaderConfig();
  const getConfigColumns = usePanelConfigColumns();

  const Configs: {[key in Types.PanelPropertiesItem]: any} = {
    [Types.PanelPropertiesItem.PAGE_SETTINGS]: getConfigPageSettings,
    [Types.PanelPropertiesItem.COLUMNS      ]: getConfigColumns,
  }

  const getConfig = Configs[panel.selected];
  const config = getConfig();

  return (
    <SidePanelBaseComponent
      config={config}
      visible={panel.visible}

      left={left}
      right={right}
    />
  );
}
