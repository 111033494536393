import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';

import { ContentTools }from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes }from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentCellMarkersSelectedState as State } from 'app/arch/editor-instruction/document/states/sessions/content-cell-markers-selected';


/**
 * Atom
 */

export const state = atom<State.State>({
  key: "ei_docContent_cellMarkersSelected",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const isSelected = selectorFamily({
  key: "ei_docContent_cellMarkersSelected__isSelected",
  get: (markerAddr: ContentTypes.MarkerAddr) => ({ get }) => {
    const stateObj = get(state);
    const markerKey = ContentTools.getMarkerKey(markerAddr);
    const selected = State.isSelected(stateObj, markerKey);
    return selected;
  }
});

export const selected = selector({
  key: "ei_docContent_cellMarkersSelected__selected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selected = State.getSelected(stateObj);

    return selected;
  }
});
