import React from 'react';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import { useDocState } from 'app/ui/contexts/document';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';

import { DropWrapper } from './styles';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellImagesDropComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children
  } = props;

  const docState = useDocState();
  const getDNDData = useDNDDataGet();

  const {
    setContent,
    setContentCellImagesSelected,
    setGalleryImagesSelected,
  } = useEditorStatesSetters();

  const handleDrop_fromCell = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const imagesAddrsDropped = data.imagesAddrs as Types.ImageAddr[];

    const newImagesAddrs = imagesAddrsDropped.map(imageAddrDropped => {
      const newImageAddr = docState.content.cellImages_moveImageIntoCell(
        imageAddrDropped,
        cellAddr
      );

      return newImageAddr;
    });

    docState.content.checkForLastRow(cellAddr);
    docState.saveUndo();
    setContent();

    docState.contentCellImagesSelected.reset();
    newImagesAddrs.filter(Boolean).forEach((imageAddr) => {
      docState.contentCellImagesSelected.select(imageAddr as ContentTypes.ImageAddr);
    });
    setContentCellImagesSelected();
  }

  const handleDrop_fromGallery = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }
    const data = JSON.parse(dataSerial);
    const imagesGids = data.imagesGids as RepoImagesTypes.ImageId[];
    const newImagesAddrs = imagesGids.map(imageGid => {
      const image = docState.repoImages.getImage(imageGid);
      const newImageAddr = docState.content.cellImages_addImage(cellAddr, image);
      return newImageAddr;
    });

    docState.content.checkForLastRow(cellAddr);
    docState.saveUndo();
    setContent();

    newImagesAddrs.filter(Boolean).forEach((imageAddr) => {
      docState.contentCellImagesSelected.select(imageAddr as ContentTypes.ImageAddr);
    });
    setContentCellImagesSelected();

    docState.galleryImageSelected.reset();
    setGalleryImagesSelected();
  }


  const contextsDefs: ContextDef[] = [
    {
      dndContext: DNDContext.image.cell,
      onDrop: handleDrop_fromCell,
    },
    {
      dndContext: DNDContext.image.gallery,
      onDrop: handleDrop_fromGallery
    },
  ];

  return (
    <DNDDropComponent 
      contextsDefs={contextsDefs}
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
