import { useRecoilValue } from 'recoil';
import { UIState_Content } from 'app/ui/states/editor-instruction';


const useStates = () => {
  const sectionsEnabled  = useRecoilValue(UIState_Content.sectionsEnabled);
  const sectionsIndexing = useRecoilValue(UIState_Content.sectionsIndexing);
  const sectionsNamesCSS = useRecoilValue(UIState_Content.sectionsNamesCSS);

  return {
    sectionsEnabled,
    sectionsIndexing,
    sectionsNamesCSS,
  }
}


export default useStates;