import { useRecoilValue } from 'recoil';
import { UIState_ViewsCommon } from 'app/ui/states/editor-instruction';


const useStates = () => {
  const tableHeaderCSS = useRecoilValue(UIState_ViewsCommon.tableHeaderCSS);

  return {
    tableHeaderCSS,
  }
}


export default useStates;