import React from 'react';
import { MainWrapper } from './styles';


interface Props {
  children: React.ReactNode;
}


export const CellContextMenuCancelerComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
  } = props;

  const handleContext = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }

  return (
    <MainWrapper onContextMenu={handleContext}>
      { children }
    </MainWrapper>
  );
}

