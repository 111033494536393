import { UUID } from "app/arch/types";

export type KeyBindingCheck = (keyEvent?: any) => boolean;
export type KeyBindingCallback = (data?: any) => boolean; /* Return if stop here processing key event */

export enum KeyBindingPriority {
  LOWEST = 10,
  EDITOR_INSTRUCTION = 100,
  EDITOR_INSTRUCTION_TOP = 110,

  EDITOR_MARKER = 150,
  EDITOR_MARKER_TOP = 160,
  
  EDITOR_IMAGE = 200,
  EDITOR_IMAGE_MENU_TOP = 210,

  CONTEXT_LAYER = 220,
}


export interface KeyBinding {
  check: KeyBindingCheck;
  callback: KeyBindingCallback;
  priority?: KeyBindingPriority;
  preventDefault?: boolean;
  stopPropagation?: boolean;
}

export type KeyBindingsRepository = {
  [id: UUID]: KeyBinding
}
