import React from 'react';
import { useEffect } from 'react';
import { useProgressModal } from 'lego-v2/progress-modal';


interface Props {
}


export const RedirectExpoComponent: React.FC<Props> = (props: Props) => {
  const { 
    showProgressModal, 
  } = useProgressModal();

  useEffect(() => {
    setTimeout(() => {
      const message = "Maintenance Expo Poland";
      showProgressModal({message});

      setTimeout(() => {
        const url = `https://www4.pwe-expoplanner.com/sklep/index.php?id_category=1139&controller=category&id_lang=2`;
        window.location.href = url;
      });
    });
  }, []);

  return null;
}

