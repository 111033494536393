import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';

import { ContentTools }from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes }from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentCellImagesSelectedState as State } from 'app/arch/editor-instruction/document/states/sessions/content-cell-images-selected';


/**
 * Atom
 */

export const state = atom<State.State>({
  key: "ei_docContent_cellImagesSelected",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const isSelected = selectorFamily({
  key: "ei_docContent_cellImagesSelected__isSelected",
  get: (imageAddr: ContentTypes.ImageAddr) => ({ get }) => {
    const stateObj = get(state);
    const imageKey = ContentTools.getImageKey(imageAddr);
    const selected = State.isSelected(stateObj, imageKey);
    return selected;
  }
});

export const selected = selector({
  key: "ei_docContent_cellImagesSelected__selected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selected = State.getSelected(stateObj);

    return selected;
  }
});

// export const isMultiSelected = selector({
//   key: "ei_docContent_cellImagesSelected__isMultiSelected",
//   get: ({ get }) => {
//     const stateObj = get(state);
//     return State.isMultiselected(stateObj);
//   }
// });
