import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useSelectedReset = () => {
  const document = useDocState();
  const {
    setContentSession,

    setContentRowsSelected,
    setContentColumnsSelected,

    setContentCellImagesSelected,
    setContentCellMarkersSelected,
  } = useEditorStatesSetters();

  const hook = (props?: {
    skipStateUpdate?: boolean,
    skipContentColumnReset?: boolean,
    skipContentRowReset?: boolean,
    skipContentCellReset?: boolean,
    skipContentCellImagesReset?: boolean,
    skipContentCellMarkersReset?: boolean,
  }) => {
    const skipStateUpdate            = props?.skipStateUpdate ?? false;
    const skipContentColumnReset     = props?.skipContentColumnReset ?? false;
    const skipContentRowReset        = props?.skipContentRowReset ?? false;
    const skipContentCellReset       = props?.skipContentCellReset ?? false;
    const skipContentCellImagesReset = props?.skipContentCellImagesReset ?? false;
    const skipContentCellMarkersReset = props?.skipContentCellMarkersReset ?? false;

    if ( ! skipContentColumnReset ) {
      document.contentColumnsSelected.reset();
    }
    
    if ( ! skipContentRowReset ) {
      document.contentRowsSelected.reset();
    }

    if ( ! skipContentCellImagesReset ) {
      document.contentCellImagesSelected.reset();
    }

    if ( ! skipContentCellMarkersReset ) {
      document.contentCellMarkersSelected.reset();
    }

    if ( ! skipContentCellReset ) {
      document.contentSession.setCellSelected(null);
    }

    document.contentSession.setSectionSelected(null);

    if (skipStateUpdate) {
      return;
    }

    setContentSession();

    if ( ! skipContentColumnReset ) {
      setContentColumnsSelected();
    }

    if ( ! skipContentRowReset ) {
      setContentRowsSelected();
    }

    if ( ! skipContentCellImagesReset ) {
      setContentCellImagesSelected();
    }

    if ( ! skipContentCellMarkersReset ) {
      setContentCellMarkersSelected();
    }

  }

  return hook;
}


export default useSelectedReset;
