import React from 'react';
import { useRecoilValue } from 'recoil';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';
import { UIState_HeaderyMarkersSelected } from 'app/ui/states/editor-instruction';
import { UIState_GalleryImagesSelected } from 'app/ui/states/editor-instruction';
import { UIState_GalleryMarkersSelected } from 'app/ui/states/editor-instruction';
import { UIState_ContentCellMarkersSelected } from 'app/ui/states/editor-instruction';
import { UIState_ContentCellImagesSelected } from 'app/ui/states/editor-instruction';
import { UIState_HeaderRowsSession } from 'app/ui/states/editor-instruction';
import { UIState_HeaderFieldsSession } from 'app/ui/states/editor-instruction';
import TopToolbarCommonComponent from 'app/ui-v2/editor-instruction/hls/top-toolbar-common';


interface Props {
}


export const BlankMenuComponent: React.FC<Props> = (props: Props) => {
  const contentCellSelected = useRecoilValue(UIState_ContentSession.cellSelected);
  const customFieldsCellSelected = useRecoilValue(UIState_HeaderFieldsSession.pageCellSelected);
  const customRowsCellSelected = useRecoilValue(UIState_HeaderRowsSession.pageCellSelected);

  const cellImagesSelected = useRecoilValue(UIState_ContentCellImagesSelected.selected);
  const showCellImages = Object.keys(cellImagesSelected).length > 0;

  const cellMarkersSelected = useRecoilValue(UIState_ContentCellMarkersSelected.selected);
  const showCellMarkers = Object.keys(cellMarkersSelected).length > 0;

  const galleryImagesSelected = useRecoilValue(UIState_GalleryImagesSelected.selected);
  const showGalleryImages = Object.keys(galleryImagesSelected).length > 0;

  const galleryMarkersSelected = useRecoilValue(UIState_GalleryMarkersSelected.selected);
  const showGalleryMarkers = Object.keys(galleryMarkersSelected).length > 0;

  const headerMarkersSelected = useRecoilValue(UIState_HeaderyMarkersSelected.selected);
  const showHeaderMarkers = Object.keys(headerMarkersSelected).length > 0;

  const visible = ( 
    ! contentCellSelected 
    && ! customFieldsCellSelected
    && ! customRowsCellSelected
    && ! showCellImages
    && ! showCellMarkers
    && ! showGalleryImages
    && ! showGalleryMarkers
    && ! showHeaderMarkers
  );

  if ( ! visible ) {
    return null;
  }

  return (
    <TopToolbarCommonComponent />
  );
}

