import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }    from 'app/ui/contexts/document';
import useSelectedReset   from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useCellSelectedSet from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell/use-cell-selected-set';
import useItemMultiSelect from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-multi-select';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import { MainWrapper } from './styles';


interface Props {
  cellAddr: ContentTypes.CellAddr;
  children: React.ReactNode;
}


export const CellIndexSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props
  
  const docState = useDocState();

  const {
    setContentRowsSelected,
  } = useEditorStatesSetters();

  const resetAllSelected = useSelectedReset();
  const setCellSelected = useCellSelectedSet();
  
  type Addr = ContentTypes.CellAddr;

  const handleSelectItem = useItemMultiSelect<Addr>({
    isSelected: (addr: Addr) => docState.contentRowsSelected.isSelected(addr),
    isMultiselected: () => docState.contentRowsSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    select: (addr: Addr) => docState.contentRowsSelected.select(addr),
    multiSelect: (addr: Addr) => {
      resetAllSelected({skipContentRowReset: true});
      docState.contentRowsSelected.toggleSelect(addr);
    },
    toggleSelect: (addr: Addr) => docState.contentRowsSelected.toggleSelect(addr),
    setState: (addr?: Addr) => {
      setContentRowsSelected();
      if ( addr ) {
        setCellSelected({ cellAddr: addr });
      }
      else {
        const msg = `Item addr can't be null`;
        console.warn(msg);
      }
    }
  });

  const handleSelectRow = (event: React.MouseEvent) => {
    handleSelectItem(event, cellAddr);
  }

  return (
    <MainWrapper onPointerDown={handleSelectRow}>
      { children }
    </MainWrapper>
  );
}
