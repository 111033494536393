import React from 'react';
import { useRecoilValue } from 'recoil';

import CascadeComponent from 'lego-v2/cascade';

import { useDocState } from 'app/ui/contexts/document';
import { UIState_GalleryImagesSelected } from 'app/ui/states/editor-instruction';
import ImageDraggedViewComponent from '../image-dragged-view';

import { MainWrapper } from './styles';


interface Props {
}


export const ImagesDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const docState = useDocState();
  const imagesSelected  = useRecoilValue(UIState_GalleryImagesSelected.selected);

  const renderImages = () => {
    const imagesKeys = Object.keys(imagesSelected);
    
    const Images = imagesKeys.map((imageKey) => {
      const image = docState.repoImages.getImage(+imageKey);

      return (
        <React.Fragment key={`${imageKey}`} >
          <ImageDraggedViewComponent image={image}/>
        </React.Fragment>
      );
    });

    return Images;
  }


  return (
    <MainWrapper>
      <CascadeComponent elements={renderImages()} />
    </MainWrapper>
  );
}

