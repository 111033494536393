import useContextPanel from 'app/ui-v2/editor-instruction/hooks/use-context-panel';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ContextPanelContentComponent from 'app/ui-v2/editor-instruction/components/context-panel-content';
import usePropsPanelConfig from './use-props-panel-config/use-props-panel-config';


const useColumnPropsPanel = () => {
  const t = useTranslations();

  const {
    showPanel,
  }= useContextPanel();


  const hook = () => {
    showPanel({
      title: t("column"),
      content: <__PanelComponent />
    });
  }

  return hook;
}
  

interface __Props {}
 
const __PanelComponent: React.FC<__Props> = (props: __Props) => {
  const getConfig = usePropsPanelConfig();
  return <ContextPanelContentComponent config={getConfig()} />;
}



export default useColumnPropsPanel;
