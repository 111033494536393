import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';
import useKeyBindingsAdd from 'lego/components/key-bindings/hooks/use-key-bindings-add';
import useFunctions from './__use-functions';


const useKBGalleryMarkers = () => {
  const fns = useFunctions();

  const {
    addBindings,
    removeBindings,
  } = useKeyBindingsAdd([
    {
      check: (event: any) => {
        return [
          'Delete',
          'Backspace'
        ].includes(event.key);
      },
      callback: fns.deleteMarkers,
      priority: KeyBindingPriority.EDITOR_INSTRUCTION,
    },
  ]);

  return {
    addBindings,
    removeBindings
  }
}

export default useKBGalleryMarkers;