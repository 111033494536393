import * as Types from './types';
import * as Defaults from './defaults';


let __setState: Types.SetStateFn | null = null;


export const setup = (setState: Types.SetStateFn | null) => {
  __setState = setState;
}


export const useProgressModal = () => {
  const showProgressModal = (opt?: Types.ShowOpt) => {
    const opt_ = (opt || {});

    const state: Types.State = {
      visible: true,
      opt: opt_,
    }
    __setState?.(state);
  }

  const hideProgressModal = () => {
    __setState?.(Defaults.getState());
  }

  return { 
    showProgressModal, 
    hideProgressModal
  };
}
