import { HistoryTypes } from "app/arch/editor-instruction/document/states/history-state";
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


export const useReloadRecoilStates = () => {
  const document = useDocState();

  const {
    setContent,
    setContentSession,

    setContentCellImagesSelected,
    setContentCellMarkersSelected,
    setContentColumnsSelected,
    setContentRowsSelected,
    setCustomRowsRowsSelected,
    setCustomFieldsColumnsSelected,
    setGalleryImagesSelected,
    setGalleryMarkersSelected,
    setHeaderMarkersSelected,

    
    setHeaderRows,
    setHeaderRowsSession,

    setHeaderFields,
    setHeaderFieldsSession,
    
    setContentView,

    setContentSliceRequest,


    setReleaselogs,
    setReleaselogsSession,
    setReleaselogsView,
    setReleaselogsSliceRequest,

    setViewsCommon,

    setRepoMarkers,

    setHeaderMarkers,

    setHeaderMeta,
    setHeaderMetaSession,

    setEditorSession,
    setEditorImageSession,
    setEditorMarkerSession,

    setRepoImagesSession,
    setContextPanelSession,
    
    //-----------
    // Review
    setMetaData,
    setRepoImages,
    
    setInstruction,
    setEditorImageSettings,
    setEditorImageWidgetsStyles,
    setEditorLogoSession,

  } = useEditorStatesSetters();



  const reloadInstructionState = () => {
    setInstruction();
  }

  const reloadDocImagesState = () => {
    setRepoImages();
  }

  const reloadWidgetStylesState = () => {
    setEditorImageWidgetsStyles();
  }

  const reloadContentStates = () => {
    /**
     * Content
     */
    setContent();
    setContentView();
    setHeaderFields();
    setHeaderRows();

    /**
     * Releaselog
     */
    setReleaselogs();
    setReleaselogsView();

    setEditorImageWidgetsStyles();

    setHeaderMeta();
    setHeaderMarkers();
    setMetaData();
    setRepoMarkers();
    setViewsCommon();
    setEditorImageSettings();
  }

  const reloadSessionStates = () => {
    // Selected
    //
    setContentCellMarkersSelected();
    setContentCellImagesSelected();
    setContentColumnsSelected();
    setContentRowsSelected();
    setCustomRowsRowsSelected();
    setCustomFieldsColumnsSelected();
    setGalleryImagesSelected();
    setGalleryMarkersSelected();
    setHeaderMarkersSelected();


    // Sessions
    //
    setRepoImagesSession();

    setContentSession();
    
    setHeaderFieldsSession();
    setHeaderRowsSession();
    setContentSliceRequest();

    setReleaselogsSession();
    setReleaselogsSliceRequest();

    setHeaderMetaSession();
    setEditorImageSession();
    setEditorLogoSession();
    setEditorMarkerSession();
    setEditorSession();

    setContextPanelSession();
  }

  const reloadAllStates = () => {
    reloadInstructionState();
    
    reloadDocImagesState();
    reloadWidgetStylesState();
    
    reloadContentStates();
    reloadSessionStates();
  }


  const loadDocumentState = (stateDeserialized: HistoryTypes.Record) => {
    const docState = document;

    docState.content.state             = stateDeserialized.content;
    docState.contentView.state         = stateDeserialized.contentView;
    docState.editorImageSettings.state = stateDeserialized.editorImageSettings;
    docState.headerFields.state        = stateDeserialized.headerFields;
    docState.headerRows.state          = stateDeserialized.headerRows;
    docState.headerMarkers.state       = stateDeserialized.headerMarkers;
    docState.headerMeta.state          = stateDeserialized.headerMeta;
    docState.metaData.state            = stateDeserialized.metaData;
    docState.releaselogs.state         = stateDeserialized.releaselogs;
    docState.releaselogsView.state     = stateDeserialized.releaselogsView;
    docState.repoMarkers.state         = stateDeserialized.repoMarkers;
    docState.widgetsStyles.state       = stateDeserialized.widgetsStyles;
    docState.viewsCommon.state         = stateDeserialized.viewsCommon;
  }

  return { 
    reloadAllStates,

    reloadInstructionState,
    
    reloadDocImagesState,
    reloadWidgetStylesState,

    reloadContentStates,

    reloadSessionStates,

    loadDocumentState
  };
}
