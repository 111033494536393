import React, { useRef } from 'react';

import { IconWrapper } from './styles';
import { Item        } from './styles';
import { ItemDelete  } from './styles';
import { TextWrapper } from './styles';


interface Props {
  title: string;
  IconComponent: any;
  onSelected: (event?: any) => void;
  variant?: string;
}


export const MenuItemComponent: React.FC<Props> = (props: Props) => {
  const { 
    IconComponent, 
    title,

    onSelected,
  } = props;

  // We need to check like pointer down, pointer up, rather then 
  // simple onClick, because when submenu appears when user select
  // it from parent menu, its onClick would immediatly fire 
  // Acctually I don't think it should, but it does. Therefore
  // we are reading pointer up/down events.
  const wasPointerDown = useRef(false);

  const ItemVariant = (
    props.variant === 'delete' ?
    ItemDelete :
    Item
  );

  const handlePointerDown = (event: React.PointerEvent) => {
    wasPointerDown.current = true;
  }

  const handlePointerUp = (event: React.PointerEvent) => {
    if ( ! wasPointerDown.current) {
      return;
    }

    onSelected(event);
    wasPointerDown.current = false;
  }


  return (
    <ItemVariant
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
    >
      <IconWrapper >
        <IconComponent/>
      </IconWrapper>

      <TextWrapper>
      { title }
      </TextWrapper>

    </ItemVariant>
  );
}
  
