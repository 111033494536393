import {
  createBrowserRouter,
} from "react-router-dom";

import ViewAuthGuardComponent from "app/services/auth/view-auth-guard";

import ViewEditorComponent from "app-views/views-app-auth/view-editor";
import ViewErrorComponent  from "app-views/views-app-specials/view-error";

import RedirectUserPasswordResetComponent   from "app/ui-v2/home-page/home-page-redirects/components/redirect-user-password-reset";
import RedirectUserSignupComponent          from "app/ui-v2/home-page/home-page-redirects/components/redirect-user-signup";
import RedirectHomePageUserOrGuestComponent from "app/ui-v2/home-page/home-page-redirects/components/redirect-home-page-user-or-guest";
import RedirectLegalTermsOfServiceComponent from "app/ui-v2/home-page/home-page-redirects/components/redirect-legal-terms-of-service";
import RedirectLegalPrivacyPolicyComponent  from "app/ui-v2/home-page/home-page-redirects/components/redirect-legal-privacy-policy";
import RedirectPaymentDoneComponent         from "app/ui-v2/home-page/home-page-redirects/components/redirect-payment-done";
import RedirectHomePagePricingComponent     from "app/ui-v2/home-page/home-page-redirects/components/redirect-home-page-pricing";
import RedirectHomePageCampAlphaComponent   from "app/ui-v2/home-page/home-page-redirects/components/redirect-home-page-camp-alpha";
import RedirectRunDemoComponent             from "app/ui-v2/home-page/home-page-redirects/components/redirect-run-demo";
import RedirectRunDemoBlankComponent        from "app/ui-v2/home-page/home-page-redirects/components/redirect-run-demo-blank";
import RedirectExpoComponent                from "app/ui-v2/home-page/home-page-redirects/components/redirect-expo";

import TestPageComponent from "app/ui/components/dev/test-page";


const routes = [
  {
    path: "/",
    errorElement: <ViewErrorComponent />,
    children: 
    [
      {
        path: "",
        element: <RedirectHomePageUserOrGuestComponent />
      }, 


      /**
       * 
       * Redirects
       * 
       */
      {
        path: "redirect",
        children: [
          {
            path: "user/signup/:email",
            element: <RedirectUserSignupComponent />,
          },
          {
            path: "user/password/reset/:email/:token",
            element: <RedirectUserPasswordResetComponent />,
          },
          {
            path: "payment/done",
            element: <RedirectPaymentDoneComponent />,
          },
        ]
      }, 


      /**
       * Legal (redirects)
       */
      
      {
        path: "legal",
        children: [
          {
            path: "terms_of_service",
            element: <RedirectLegalTermsOfServiceComponent />,
          },
          {
            path: "privacy_policy",
            element: <RedirectLegalPrivacyPolicyComponent />,
          },
        ]
      }, 


      /**
       * Links (redirects)
       */
        
      {
        path: "links",
        children: [
          {
            path: "pricing",
            element: <RedirectHomePagePricingComponent />,
          },

          {
            path: "run-demo",
            element: <RedirectRunDemoComponent />,
          },
          {
            path: "rundemo",
            element: <RedirectRunDemoComponent />,
          },

          {
            path: "run-demo-blank",
            element: <RedirectRunDemoBlankComponent />,
          },
          {
            path: "rundemoblank",
            element: <RedirectRunDemoBlankComponent />,
          },                 

        ]
      }, 

      {
        path: "camp",
        children: [
          {
            path: "alpha",
            element: <RedirectHomePageCampAlphaComponent />,
          },
        ]
      }, 

      /**
       * 
       * Authenticated 
       * 
       */
      {
        path: "auth",
        element: <ViewAuthGuardComponent />,
        children: [

          /**
           * Editor
           */
          {
            path: "editor/document/:instructionId",
            children: [
              {
                path: "edit/:viewType?",
                element: <ViewEditorComponent />,
              }
            ]
          },
        ]
      }, 
      /**
       * 
       * Authenticated End
       * 
       */

      {
        path: "targi",
        element: <RedirectExpoComponent />,
      },   

      {
        path: "test_page",
        element: <TestPageComponent />,
      }, 
    ]
  },
];


const router = createBrowserRouter(routes, {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
  },
});


export default router;