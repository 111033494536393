import * as Types from './types';


/**
 * State
 */

export type State = {
  selected: Types.SelectedItems,
  dragOver: Types.DragOverItem,
};


/**
 * Items
 */

export const getSelected = (state: State) => state.selected;

export const getSelectedCount = (state: State) => {
  const selected = getSelected(state);
  const keys = Object.keys(selected);
  return keys.length;
}

export const isMultiselected = (state: State) => {
  const selectedCount = getSelectedCount(state);
  return selectedCount > 1;
}

export const getSelectedOrdered = (state: State) => {
  const rowsSelected = getSelected(state);
  const rowsKeys = Object.keys(rowsSelected);

  return rowsKeys;
}

export const isSelected  = (
  state: State, 
  itemAddr: Types.ItemAddr
) => {
  const selected = state.selected;

  if (itemAddr in selected) {
    return selected[itemAddr];
  }
  else {
    return false;
  }
}

export const getDragOver = (state: State) => state.dragOver;

export const isDragOver  = (
  state: State, 
  itemAddr: Types.ItemAddr
): boolean => {
  if (state.dragOver === null) {
    return false;
  }

  const isOver = (itemAddr === state.dragOver);
  return isOver;
}


/**
 * Create initial state
 */

export const createInitialState = (): State => {
  const state: State = {
    selected: {},
    dragOver: null,
  }

  return state;
}
