import styled from "styled-components";
import ContentWrapperBase from "../../styles/content-wrapper";
import ContentBase from "../../styles/content";


export const ContentWrapper = styled(ContentWrapperBase)`
`;

export const Content = styled(ContentBase)`
  gap: ${props => props.theme.defs.gap.xxsmall};
`;
