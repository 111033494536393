import React from 'react';

import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { useDocState } from 'app/ui/contexts/document';
import useItemMultiSelect from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-multi-select';
import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import { MainWrapper } from './styles';


interface Props {
  image: RepoImagesTypes.Image;
  children: React.ReactNode;
}


export const ImageSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    image,
    children,
  } = props;
  const docState = useDocState();
  const resetAllSelected = useAllSelectedReset();

  const {
    setGalleryImagesSelected,
  } = useEditorStatesSetters();

  type Addr = RepoImagesTypes.ImageId;

  const handleSelectItem = useItemMultiSelect<Addr>({
    isSelected: (addr: Addr) => docState.galleryImageSelected.isSelected(addr),
    isMultiselected: () => docState.galleryImageSelected.isMultiselected(),
    resetSelected: () => {
      resetAllSelected()
    },
    select: (addr: Addr) => docState.galleryImageSelected.select(addr),
    multiSelect: (addr: Addr) => {
      resetAllSelected({skipGalleryImagesReset: true});
      docState.galleryImageSelected.toggleSelect(addr);
    },
    toggleSelect: (addr: Addr) => docState.galleryImageSelected.toggleSelect(addr),
    setState: () => setGalleryImagesSelected(),
  });

  const handleSelectImage = (event: React.PointerEvent) => {
    handleSelectItem(event, image.bid);
  }

  return (
    <MainWrapper onPointerDown={handleSelectImage}>
      { children }
    </MainWrapper>
  );
}

