import { HeaderFieldsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset     from '../../use-selected-reset';
import useColumnsAutoAdjust from '../../columns/use-columns-auto-adjust';
import useColumnPropsPanel from '../use-column-props-panel/use-panel-config';


const useFunctions = () => {
  const t = useTranslations();
  const docState = useDocState();
  const columnsAutoAdjust = useColumnsAutoAdjust();
  const resetSelected = useSelectedReset();
  const showColumnPropsPanel = useColumnPropsPanel();

  const {
    setCustomFieldsColumnsSelected,
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();


  const createColumn = (
    srcColumnAddr: HeaderFieldsTypes.PageColumnAddr, 
  ) => {
    const newColumnName = t('custom fields, default column name, new');
    const newColumnAddr = docState.headerFields.addColumnAfter(
      srcColumnAddr, 
      HeaderFieldsTypes.ColumnType.TEXT,
      { name: newColumnName }
    );

    columnsAutoAdjust({ debounced: false });
    docState.saveUndo();
    
    const newPageColumnAddr = {
      ...newColumnAddr,
      pageIdx: srcColumnAddr.pageIdx
    }

    docState.customFieldsColumnsSelected.reset();
    docState.customFieldsColumnsSelected.select(newPageColumnAddr);
    setCustomFieldsColumnsSelected();

    hideMenu();
  }


  const showColumnProps = (
    pageColumnAddr: HeaderFieldsTypes.PageColumnAddr, 
    event: React.MouseEvent
  ) => {
    showColumnPropsPanel(event);
    hideMenu();
  }

  const deleteColumn = (columnAddr: HeaderFieldsTypes.PageColumnAddr) => {
    docState.headerFields.deleteColumn(columnAddr);
    columnsAutoAdjust({ debounced: false });
    docState.saveUndo();

    resetSelected();
    hideMenu();
  }


  return {
    createColumn,
    showColumnProps,
    deleteColumn,
  };
}


export default useFunctions;