import React from 'react';
import { useRecoilValue } from 'recoil';

import { SideControlsTypes }    from 'lego/components/side-controls';
import SideToolbarBaseComponent from 'lego/components/side-controls/side-toolbar';
import { SideToolbarConfig }    from 'lego/components/side-controls/side-toolbar/config';

import { ContentSessionTypes as Types } from 'app/arch/editor-instruction/document/states/sessions/content-session';
import { Icon } from 'app/ui/icons/icons';
import { useDocState } from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';


type Props = SideControlsTypes.PropLeftOrRight;


export const SideToolbarComponent: React.FC<Props> = (props: Props) => {
  const {
    left,
    right
  } = props;

  const t = useTranslations();
  const document = useDocState();
  const contentSession = document.contentSession;

  const panel = useRecoilValue(UIState_ContentSession.panelProperties);

  const {
    setContentSession,
  } = useEditorStatesSetters();

  const switchPanel = (selectedPanelItem: Types.PanelPropertiesItem) => {
    contentSession.updatePanelAssets({visible: false});
    
    if (panel.visible) {
      if (panel.selected !== selectedPanelItem) {
        contentSession.updatePanelProperties({
          selected: selectedPanelItem
        });
      }
      else {
        contentSession.updatePanelProperties({
          visible: false
        });        
      }
    }
    else {
      contentSession.updatePanelProperties({
        visible: true,
        selected: selectedPanelItem
      });
    }

    setContentSession();
  }

  const showPageSettings = () => switchPanel(Types.PanelPropertiesItem.PAGE_SETTINGS);
  const showColumns  = () => switchPanel(Types.PanelPropertiesItem.COLUMNS);


  const toolbarConfig: SideToolbarConfig = {
    items: [
      {
        tooltip: t('page properties'),
        selected: panel.visible && panel.selected === Types.PanelPropertiesItem.PAGE_SETTINGS,
        onClick: showPageSettings,
        Icon: Icon.Document.Config,
      },    
      {
        tooltip: t('columns'),
        selected: panel.visible && panel.selected === Types.PanelPropertiesItem.COLUMNS,
        onClick: showColumns,
        Icon: Icon.Columns
      },
    ]
  };

  return (
    <SideToolbarBaseComponent
      config={toolbarConfig} 

      left={left}
      right={right}
    />
  );
}
