import React from 'react';

import { ContentTools, ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import { useDocState } from 'app/ui/contexts/document';
import DNDDropComponent from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import { DropWrapper } from './styles';


interface Props {
  cellAddr: ContentTypes.CellAddr;
  children: React.ReactNode;
}


export const ColumnDropComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children
  } = props;

  const docState = useDocState();

  const getDNDData = useDNDDataGet();

  const {
    setContent,
    setContentColumnsSelected,
  } = useEditorStatesSetters();

  const handleDrop = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const droppedColumnAddr = data.columnAddr;
 
    const moved = docState.content.moveColumn(droppedColumnAddr, cellAddr);
    if ( ! moved ) {
      return;
    }

    docState.contentColumnsSelected.reset();
    docState.contentColumnsSelected.select(droppedColumnAddr);
    docState.saveUndo();

    setContent();
    setContentColumnsSelected()
  }

  const handleDragOver = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const isSelected = docState.contentColumnsSelected.isSelected(cellAddr);
    if (isSelected) {
      return;
    }
    
    docState.contentColumnsSelected.setDragOver(cellAddr);
    setContentColumnsSelected();
  }

  const handleDragLeave = () => {
    docState.contentColumnsSelected.setDragOver(null);
    setContentColumnsSelected();
  }


  const contextsDefs: ContextDef[] = [
    {
      dndContext: DNDContext.viewContent.table.column,
      onDrop: handleDrop,
      onDragOver: handleDragOver,
      onDragLeave: handleDragLeave,
    },
  ];

  return (
    <DNDDropComponent 
      contextsDefs={contextsDefs}
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
