import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { Size } from 'app/arch/types';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_ContextPanelSession } from 'app/ui/states/editor-instruction';

import { MainWrapper } from './styles';


interface Props {
  children: React.ReactNode;
}


export const ContextPanelWindowAdjusterComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const docState = useDocState();
  const [adjusted, setAdjusted] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const content = useRecoilValue(UIState_ContextPanelSession.windowContent);

  const {
    setContextPanelSession,
  } = useEditorStatesSetters();

  useEffect(() => {
    if ( ! wrapperRef.current ) {
      return;
    }

    const el = wrapperRef.current;
    const bbox = el.getBoundingClientRect();

    const oldSize = docState.contextPanelSession.getWindow().size;
    const newSize = [
      oldSize[0],
      bbox.height
    ] as Size;

    const update = {
      size: newSize
    }
    
    docState.contextPanelSession.updateWindow(update);
    setContextPanelSession();
    setAdjusted(true);

    return () => {
      setAdjusted(false);
    }
  }, [content]);

  
  return (
    <MainWrapper 
      ref={wrapperRef}
      adjusted={adjusted}
    >
      { children }
    </MainWrapper>
  );
}

