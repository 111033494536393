import { MenuItemType } from 'lego-v2/menu';
import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { Icon } from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useFunctions from './__use-functions';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();

  const getImageDelete = (imageId: RepoImagesTypes.ImageId) => ({
    type: MenuItemType.BUTTON,
    data: {
      variant: 'delete',
      title: t('image, delete'),
      iconComponent: Icon.Delete,
      onClick: (event: React.MouseEvent) => fns.deleteImage(imageId),
    }
  });


  return {
    getImageDelete,
  }
}


export default useItems;