import React from 'react';
import { useRecoilValue } from 'recoil';
import { UIState_ContextPanelSession } from 'app/ui/states/editor-instruction';


interface Props {
}


export const ContextPanelContentComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const content = useRecoilValue(UIState_ContextPanelSession.windowContent);

  return (
    <>
      { content }
    </>
  );
}

