import { HeaderMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = () => {
  const docState = useDocState();

  const {
    setHeaderMarkers,
    setHeaderMarkersSelected,
  } = useEditorStatesSetters();

  
  const removeMarker = (event: KeyboardEvent) => {
    const markersSelected = docState.headerMarkersSelected.getSelected();
    const markersKeys = Object.keys(markersSelected);
    if ( ! markersKeys.length ) {
      return false;
    }

    const markersAddrs = markersKeys.map(markerKey => HeaderMarkersTools.markerKeyToAddr(markerKey));

    markersAddrs.map((markerAddr) => {
      docState.headerMarkers.removeMarker(markerAddr);
    });

    docState.saveUndo();
    setHeaderMarkers();

    docState.headerMarkersSelected.reset();
    setHeaderMarkersSelected();

    return true;
  }

  return {
    removeMarker,
  }
}

export default useFunctions;