import { useRecoilValue } from 'recoil';
import { UIState_ReleaselogsView } from 'app/ui/states/editor-instruction';
import usePageLayout from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-layout';
import usePageMargins from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-margins';


const useStates = () => {
  const pageLayout = usePageLayout();
  const pageMargins = usePageMargins();

  const headerMetaVisible = useRecoilValue(UIState_ReleaselogsView.headerMetaVisible);
  const releaselogVisible = useRecoilValue(UIState_ReleaselogsView.headerTitleVisible);


  return {
    pageLayout,
    pageMargins,

    headerMetaVisible,
    releaselogVisible,
  }
}


export default useStates;