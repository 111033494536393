import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useCellIdxMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-idx/use-cell-idx-menu';
import useCellsIdxMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-idx/use-cells-idx-menu';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useItemContextMenu from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-context-menu';
import { useDocState } from 'app/ui/contexts/document';

import { MainWrapper } from './styles';


interface Props {
  cellAddr: ContentTypes.CellAddr;
  children: React.ReactNode;
}


export const CellIndexContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props;

  const docState = useDocState();
  const getConfigSingleRow = useCellIdxMenu();
  const getConfigMultiRows = useCellsIdxMenu();
  const resetAllSelected = useAllSelectedReset();

  const {
    setContentRowsSelected,
  } = useEditorStatesSetters()

  const handleContextMenu_ = useItemContextMenu<ContentTypes.CellAddr>({
    isSelected: (addr: ContentTypes.CellAddr) => docState.contentRowsSelected.isSelected(addr),
    isMultiselected: () => docState.contentRowsSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    resetOtherSelected: () => resetAllSelected({skipContentRowReset: true}),
    select: (addr: ContentTypes.CellAddr) => {
      docState.contentRowsSelected.select(addr);
      setContentRowsSelected();
    },
    getConfigItems: () => getConfigMultiRows(),
    getConfigItem: (addr: ContentTypes.CellAddr) => getConfigSingleRow(addr)
  });

  const handleContextMenu = (event: React.MouseEvent) => {
    handleContextMenu_(event, cellAddr);
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
