import { MenuConfig   } from 'lego-v2/menu/config';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useItems from './__use-items';


const useCellImagesImageMenu = () => {
  const items = useItems();


  const getConfig = (imageAddr: ContentTypes.ImageAddr): MenuConfig => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.getUploadImageFromDisk(imageAddr),
            items.getUploadImageFromCamera(imageAddr),
          ],
        },
        {
          items: [
            items.getEditImage(imageAddr),
            items.getDuplicateImage(imageAddr),
          ],
        },
        {
          items: [
            items.getRemoveImage(imageAddr),
          ],
        },
      ]
    }

    return config;
  }


  const hook = (imageAddr: ContentTypes.ImageAddr): MenuConfig => {
    const config = getConfig(imageAddr);
    return config;
  }

  return hook;
}


export default useCellImagesImageMenu;