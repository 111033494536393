import React from 'react';

import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import useHeaderMarkersMarkerMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/header-markers/use-header-markers-marker-menu';

import { useDocState } from 'app/ui/contexts/document';
import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useItemContextMenu from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-context-menu';
import useHeaderMarkersMarkersMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/header-markers/use-header-markers-markers-menu';

import { MainWrapper } from './styles';


interface Props {
  markerAddr: HeaderMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children,
  } = props;

  const docState = useDocState();

  const getConfigMarkers = useHeaderMarkersMarkersMenu();
  const getConfigMarker = useHeaderMarkersMarkerMenu();
  const resetAllSelected = useAllSelectedReset();
  
  const {
    setHeaderMarkersSelected,
  } = useEditorStatesSetters();

  type Addr = HeaderMarkersTypes.MarkerAddr;

  const handleContextMenu_ = useItemContextMenu<Addr>({
    isSelected: (addr: Addr) => docState.headerMarkersSelected.isSelected(addr),
    isMultiselected: () => docState.headerMarkersSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    resetOtherSelected: () => resetAllSelected({skipHeaderMarkersReset: true}),
    select: (addr: Addr) => {
      docState.headerMarkersSelected.select(addr);
      setHeaderMarkersSelected();
    },
    getConfigItems: () => getConfigMarkers(),
    getConfigItem: (addr:Addr) => getConfigMarker(addr)
  });

  const handleContextMenu = (event: React.MouseEvent) => {
    handleContextMenu_(event, markerAddr);
  }


  return (
    <MainWrapper onContextMenu={handleContextMenu} >
      { children }
    </MainWrapper>
  );
}
