import React from 'react';

import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { DNDContext }   from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useRowMove       from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/row/use-row-move';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';

import { DropWrapper } from './styles';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const RowDropComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children
  } = props;

  const docState = useDocState();

  const moveRow = useRowMove();
  const getDNDData = useDNDDataGet();

  const {
    setContentRowsSelected,
  } = useEditorStatesSetters();

  const handleDrop = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const droppedRowAddr = data.rowAddr;

    const same = ContentTools.compareRowAddr(cellAddr, droppedRowAddr);
    if (same) {
      return;
    }

    moveRow({
      srcRow: droppedRowAddr, 
      dstRow: cellAddr,
    });

    docState.contentRowsSelected.select(droppedRowAddr);
    setContentRowsSelected();
  }

  const handleDragOver = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const isSelected = docState.contentRowsSelected.isSelected(cellAddr);
    if (isSelected) {
      return;
    }
    
    docState.contentRowsSelected.setDragOver(cellAddr);
    setContentRowsSelected();
  }

  const handleDragLeave = () => {
    docState.contentRowsSelected.setDragOver(null);
    setContentRowsSelected();
  }


  const contextsDefs: ContextDef[] = [
    {
      dndContext: DNDContext.viewContent.table.row,
      onDrop: handleDrop,
      onDragOver: handleDragOver,
      onDragLeave: handleDragLeave,
    },
  ];

  return (
    <DNDDropComponent 
      contextsDefs={contextsDefs}
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
