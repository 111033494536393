import React from 'react';

import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';
import { PageItemsProps } from '../types';
import ContentGridComponent     from './content-grid';
import ColumnsResizersComponent from './columns-resizers';
import ContentContextComponent  from './content-context';
import useKBContent from './hooks/use-key-bindings/use-kb-content';

import { Anchor } from './styles';
import { ColumnsResizersDetacher } from './styles';


interface Props extends PageItemsProps {
}


export const PageItemContentComponent: React.FC<Props> = (props: Props) => {
  const { 
    item
  } = props;

  const content = item as SlicerTypes.PageItem_Content;

  useKBContent();

  return (
    <Anchor>
      <ColumnsResizersDetacher>
        <ColumnsResizersComponent debug={false} />
      </ColumnsResizersDetacher>

      <ContentContextComponent content={content} >
        <ContentGridComponent content={content} />
      </ContentContextComponent>
    </Anchor>
  );
}
  
