import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { useDocState }        from 'app/ui/contexts/document';
import { DNDContext }         from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent       from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';
import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';


interface Props {
  markerAddr: ContentTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerDropComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr,
    children,
  } = props;

  const docState = useDocState();
  const getDNDData = useDNDDataGet();

  const {
    setContent,
    setContentCellMarkersSelected,
    setGalleryMarkersSelected,
    setHeaderMarkersSelected,
  } = useEditorStatesSetters();

  const handleDrop_fromGallery = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markersAddrsDropped = data.markersAddrs as RepoMarkersTypes.MarkerAddr[];

    const newMarkersAddrs = markersAddrsDropped.map(markerAddrDropped => {
      const newMarkerAddr = docState.content.cellMarkers_addMarkerRight(
        markerAddrDropped,
        markerAddr
      );

      return newMarkerAddr;
    });

    docState.saveUndo();
    setContent();

    docState.galleryMarkersSelected.reset();
    setGalleryMarkersSelected();

    docState.contentCellMarkersSelected.reset();
    newMarkersAddrs.filter(Boolean).forEach(markerAddr => docState.contentCellMarkersSelected.select(markerAddr as ContentTypes.MarkerAddr));
    setContentCellMarkersSelected();
  }

  const handleDrop_fromDocHeader = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markersAddrsDropped = data.markersAddrs as HeaderMarkersTypes.MarkerAddr[];

    const newMarkersAddrs = markersAddrsDropped.map(markerAddrDropped => {
      const headerMarkerProps = docState.headerMarkers.getMarkerProps(markerAddrDropped);
      const newMarkerAddr = docState.content.cellMarkers_addMarkerRight(
        headerMarkerProps.repoMarkerAddr,
        markerAddr
      );
      
      return newMarkerAddr;
    });

    docState.saveUndo();
    setContent();

    docState.contentCellMarkersSelected.reset();
    newMarkersAddrs.filter(Boolean).forEach(markerAddr => docState.contentCellMarkersSelected.select(markerAddr as ContentTypes.MarkerAddr));
    setContentCellMarkersSelected();

    docState.headerMarkersSelected.reset();
    setHeaderMarkersSelected();
  }

  const handleDrop_fromCell = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markersAddrsDropped = data.markersAddrs as ContentTypes.MarkerAddr[];

    const newMarkersAddrs = markersAddrsDropped.map(markerAddrDropped => {
      const newMarkerAddr = docState.content.cellMarkers_moveMarkerOnMarker(
        markerAddrDropped,
        markerAddr
      );

      return newMarkerAddr;
    });

    docState.saveUndo();
    setContent();

    docState.contentCellMarkersSelected.reset();
    newMarkersAddrs.filter(Boolean).forEach(markerAddr => docState.contentCellMarkersSelected.select(markerAddr as ContentTypes.MarkerAddr));
    setContentCellMarkersSelected();
  }

  const handleDrop_fromEditor = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr as RepoMarkersTypes.MarkerAddr;

    const newMarkerAddr = docState.content.cellMarkers_addMarkerRight(
      markerAddrDropped,
      markerAddr
    );

    docState.content.checkForLastRow(markerAddr);

    docState.saveUndo();
    setContent();

    docState.contentCellMarkersSelected.reset();
    docState.contentCellMarkersSelected.select(newMarkerAddr);
    setContentCellMarkersSelected();
  }

  const contextsDefs: ContextDef[] = [
    {
      dndContext: DNDContext.marker.cell,
      onDrop: handleDrop_fromCell,
    },
    {
      dndContext: DNDContext.marker.gallery,
      onDrop: handleDrop_fromGallery,
    },
    {
      dndContext: DNDContext.marker.docHeader,
      onDrop: handleDrop_fromDocHeader,
    },
    {
      dndContext: DNDContext.marker.editor,
      onDrop: handleDrop_fromEditor,
    },
  ];


  return (
    <DNDDropComponent
      contextsDefs={contextsDefs}
    >
      { children }
    </DNDDropComponent>
  );
}
