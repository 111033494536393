import React from 'react';
import { useRecoilValue } from 'recoil';

import CascadeComponent from 'lego-v2/cascade';
import { HeaderMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { UIState_HeaderyMarkersSelected } from 'app/ui/states/editor-instruction';

import MarkerDraggedViewComponent from '../marker-dragged-view';
import { MainWrapper } from './styles';


interface Props {
}


export const MarkersDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const markersSelected  = useRecoilValue(UIState_HeaderyMarkersSelected.selected);

  const renderMarkers = () => {
    const markersKeys = Object.keys(markersSelected);

    const Markers = markersKeys.map((markerKey, idx) => {
      const markerAddr = HeaderMarkersTools.markerKeyToAddr(markerKey);
      return (
        <React.Fragment key={`${markerKey}`} >
          <MarkerDraggedViewComponent markerAddr={markerAddr}/>
        </React.Fragment>
      );
    });

    return Markers;
  }


  return (
    <MainWrapper>
      <CascadeComponent elements={renderMarkers()} />
    </MainWrapper>
  );
}
