import { Position } from 'app/arch/types';
import { Size }     from 'app/arch/types';
import { settings } from 'app/configs'

import * as Types from './types';
import React from 'react';


export const getWindow = (): Types.Window => {
  const PERCENT_MULTI = 80 / 100;
  const SIZE_MIN: Size = [300, 300];
  const SIZE_MAX: Size = [530, 420];
  const POSITION_Y_MAX = 60;

  const sizePercent = [
    window.innerWidth * PERCENT_MULTI,
    window.innerHeight * PERCENT_MULTI
  ];

  const size = [
    Math.min( Math.max( sizePercent[0], SIZE_MIN[0] ), SIZE_MAX[0] ),
    Math.min( Math.max( sizePercent[1], SIZE_MIN[1] ), SIZE_MAX[1] ),
  ] as Size;

  const positionTemp = [
    (window.innerWidth  - size[0]) / 2,
    (window.innerHeight - size[1]) / 2,
  ] as Position;

  const position = [
    positionTemp[0],
    Math.min(positionTemp[1], POSITION_Y_MAX)
  ] as Position;

  const content = <React.Fragment />;
  const title = "";

  const editorWindow: Types.Window = {
    visible:   false,
    maximized: false,
    size,
    position,
    content,
    title
  };

  return editorWindow;
}
  

export const getWindowSizeMin = (): Size => [265, 90] as Size;
export const getWindowSizeMax = (): Size => [380, 610] as Size;
