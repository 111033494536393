import { MenuItemType } from 'lego-v2/menu';

import { HeaderFieldsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { Icon } from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useFunctions from './__use-functions';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();

  const getColumnCreate = (pageColumnAddr: HeaderFieldsTypes.PageColumnAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('custom fields, add'),
      iconComponent: Icon.Create,
      onClick: (event: React.PointerEvent) => fns.createColumn(pageColumnAddr),
    }
  });

  const getColumnProperties = (pageColumnAddr: HeaderFieldsTypes.PageColumnAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('custom fields, properties'),
      iconComponent: Icon.Settings,
      onClick: (event: React.MouseEvent) => fns.showColumnProps(pageColumnAddr, event),
    }
  });

  const getColumnDelete = (pageColumnAddr: HeaderFieldsTypes.PageColumnAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      variant: 'delete',
      title: t('columns, delete'),
      iconComponent: Icon.Delete,
      onClick: (event: React.MouseEvent) => fns.deleteColumn(pageColumnAddr),
    }
  });


  return {
    getColumnCreate,
    getColumnProperties,
    getColumnDelete,
  }
}


export default useItems;