import React from 'react';

import { PanelType } from 'lego/components/side-controls/types';
import SidePanelBaseComponent from 'lego/components/side-controls/side-panel';
import { SideControlsTypes } from 'lego/components/side-controls';

import { ScreenSizeTools } from 'app/arch/app/screen';
import { ScreenSizeTypes } from 'app/arch/app/screen';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import useWindowContext from 'app/ui-v2/editor-markers/hooks/use-window-context';

import usePanelConfig from './config/use-panel-config';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
}


export const SidePanelComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr
  } = props;
  
  const windowContext = useWindowContext();
  const windowWidth   = windowContext.size[0];
  const windowVisible = (windowWidth > 0);

  const getPanelType = (width: number): SideControlsTypes.PanelType => {
    const sizeVariant = ScreenSizeTools.getSizeVariant(width);
    const screenToPanelMap: SideControlsTypes.ScreenToPanelMap = {
      [ScreenSizeTypes.SizeVariant.WIDTH_320]: PanelType.NARROW_190,
      [ScreenSizeTypes.SizeVariant.WIDTH_360]: PanelType.NARROW_230,
      [ScreenSizeTypes.SizeVariant.WIDTH_390]: PanelType.NARROW_230,
      [ScreenSizeTypes.SizeVariant.WIDTH_500]: PanelType.WIDE_320,
      [ScreenSizeTypes.SizeVariant.WIDTH_600]: PanelType.WIDE_320,
    }

    const panelType = screenToPanelMap[sizeVariant];
    return panelType;
  }

  const getConfig = usePanelConfig(markerAddr);
  const config    = getConfig();
  const panelType = getPanelType(windowWidth);

  return (
    <>
      {
        windowVisible &&
        <SidePanelBaseComponent 
          visible={true}
          right={true}
          config={config}
          panelType={panelType}
        />
      }
    </>
  );
}
  
