import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';


const useFunctions = () => {
  const docState = useDocState();

  const {
    setContent,
    setContentCellImagesSelected,
  } = useEditorStatesSetters();

  const removeImage = (event: KeyboardEvent) => {
    const imagesSelected = docState.contentCellImagesSelected.getSelected();
    const imagesKeys = Object.keys(imagesSelected);
    if ( ! imagesKeys.length ) {
      return false;
    }

    const imagesAddrs = imagesKeys.map(markerKey => ContentTools.imageKeyToAddr(markerKey));
    imagesAddrs.map((imageAddr) => {
      docState.content.cellImages_removeImage(imageAddr);
    });

    docState.saveUndo();
    setContent();

    docState.contentCellImagesSelected.reset();
    setContentCellImagesSelected();

    return true;
  }

  return {
    removeImage,
  }
}

export default useFunctions;