import styled from 'styled-components';

const MarkerView = styled.div`
  box-sizing: border-box;
  border-style:  none;
  padding: ${props => props.theme.defs.padding.small};

  /* outline: solid 10px red; */
`;

export default MarkerView;