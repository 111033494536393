import { useEffect } from 'react';

import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';
import useKeyBindingsBase     from 'lego/components/key-bindings/hooks/use-key-bindings';

import EditorInstruction from 'app/arch/editor-instruction';
import useSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';


export const useKeyBindings = (editorInstruction: EditorInstruction) => {
  const { 
    addKeyDownBinding, 
    removeKeyDownBinding,
  } = useKeyBindingsBase();

  // TODO: right now it resets selected only in view content
  // resets selected view changelog should be added here
  const resetSelected = useSelectedReset();

  useEffect(() => {
    const id =  addKeyDownBinding({
      check: (event: any) => event.key === 'Escape', 
      callback: __escape,
      priority: KeyBindingPriority.EDITOR_INSTRUCTION
    });
      
    return () => {
      removeKeyDownBinding(id);
    }
  }, []);

  const __escape = () => {
    resetSelected();
    return false;
  };
}
