import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';

import { GalleryMarkersSelectedState as State } from 'app/arch/editor-instruction/document/states/sessions/gallery-markers-selected';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { RepoMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';


/**
 * Atom
 */

export const state = atom<State.State>({
  key: "ei_galleryMarkers",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const isSelected = selectorFamily({
  key: "ei_galleryMarkers__isSelected",
  get: (markerAddr: RepoMarkersTypes.MarkerAddr) => ({ get }) => {
    const stateObj = get(state);
    const selected = State.isSelected(stateObj, RepoMarkersTools.getMarkerKey(markerAddr));
    return selected;
  }
});

export const selected = selector({
  key: "ei_galleryMarkers__selected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selected = State.getSelected(stateObj);

    return selected;
  }
});
  