import React from 'react';

import { HeaderMarkersTools, HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { useDocState } from 'app/ui/contexts/document';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import HLSDraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import MarkersDraggedViewComponent from './markers-dragged-view';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


interface Props {
  markerAddr: HeaderMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerDraggerComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr, 
    children
  } = props;

  const docState = useDocState();
  const setDNDData = useDNDDataSet();
  const { hideMenu } = useContextMenu();

  const {
    setHeaderMarkersSelected,
  } = useEditorStatesSetters();

  const handleDragStart = () => {
  }

  const handleDragMoveFirst = () => {
    hideMenu();

    const markersSelected = docState.headerMarkersSelected.getSelected();
    const markersKeys = Object.keys(markersSelected);
    const markersAddrs = markersKeys.map(markerKey => HeaderMarkersTools.markerKeyToAddr(markerKey));
    
    if ( ! docState.headerMarkersSelected.isSelected(markerAddr) ) {
      markersAddrs.splice(0, 0, markerAddr);
      docState.headerMarkersSelected.select(markerAddr);
      setHeaderMarkersSelected();
    }

    const data = {markersAddrs: markersAddrs}
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  return (
    <HLSDraggerComponent
      debug={false}
      dndContext={DNDContext.marker.docHeader}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      draggedView={ <MarkersDraggedViewComponent /> }
    >
      { children }
    </HLSDraggerComponent>
  );
}
