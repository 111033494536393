import React from 'react';

import { HeaderFieldsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { useDocState } from 'app/ui/contexts/document';
import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useItemContextMenu from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-context-menu';
import useColumnMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/column/use-column-menu';
import useColumnsMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/columns/use-columns-menu';

import { MainWrapper } from './styles';


interface Props {
  pageColumnAddr: HeaderFieldsTypes.PageColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellContextMenuComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageColumnAddr,
    children
  } = props;

  const docState = useDocState();
  const getConfigColumn = useColumnMenu();
  const getConfigColumns = useColumnsMenu();
  const resetAllSelected = useAllSelectedReset();

  const {
    setCustomFieldsColumnsSelected,
  } = useEditorStatesSetters()

  const handleContextMenu_ = useItemContextMenu<HeaderFieldsTypes.PageColumnAddr>({
    isSelected: (addr: HeaderFieldsTypes.PageColumnAddr) => docState.customFieldsColumnsSelected.isSelected(addr),
    isMultiselected: () => docState.customFieldsColumnsSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    resetOtherSelected: () => resetAllSelected({skipCustomFieldsReset: true}),
    select: (addr: HeaderFieldsTypes.PageColumnAddr) => {
      docState.customFieldsColumnsSelected.select(addr);
      setCustomFieldsColumnsSelected();
    },
    getConfigItems: () => getConfigColumns(),
    getConfigItem: (addr: HeaderFieldsTypes.PageColumnAddr) => getConfigColumn(addr)
  });

  const handleContextMenu = (event: React.MouseEvent) => {
    handleContextMenu_(event, pageColumnAddr);
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
