import { useDocState }  from "app/ui/contexts/document";
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useContextElement from "../../../../hooks/use-context-element";
import useSelectedReset from '../use-selected-reset';
import useColumnSelectedSet from '../column/use-column-selected-set';
import useReleaselogColumnsAutoAdjust from '../columns/use-releaselog-columns-auto-adjust';

import NewColumnPanelComponent from './new-column-panel';
import useTablePropsPanel from "../../../view-content/hooks/doc-content/table/use-table-props-panel";
import useColumnPropsPanel from "../use-column-props-panel/use-column-props-panel";


const useFunctions = (columnAddr: Types.ColumnAddr) => {
  const docState = useDocState();
  const releaselogAddr = columnAddr;
  const columnsAutoAdjust = useReleaselogColumnsAutoAdjust(releaselogAddr);
  const showColumnPropsPanel = useColumnPropsPanel();
  const showTablePropsPanel = useTablePropsPanel();

  const {
    hideMenu
  } = useContextMenu();

  const {
    showElement,
    hideElement,
  }  = useContextElement();

  const resetSelected = useSelectedReset();
  const setColumnSelected = useColumnSelectedSet();


  const addColumn = (columnAddr: Types.ColumnAddr, event: React.PointerEvent) => {
    event.stopPropagation();

    const onCancel = () => {
      hideElement();
    }

    const onDone = (newColumnAddr: Types.ColumnAddr) => {
      // Auto adjust was already called after adding column
      setColumnSelected(newColumnAddr);
    }

    showElement({
      Element: NewColumnPanelComponent, 
      ElementProps: {
        columnAddr,
        onCancel,
        onDone
      },
      event
    });
    hideMenu();
  }

  const showColumnProperties = () => {
    showColumnPropsPanel();
    hideMenu();
  }

  const showTableProperties = () => {
    showTablePropsPanel();
    hideMenu();
  }

  const deleteColumn = (columnAddr: Types.ColumnAddr, event: React.PointerEvent) => {
    event.stopPropagation();

    docState.releaselogs.deleteColumn(columnAddr);
    
    columnsAutoAdjust({ debounced: false });
    docState.saveUndo();

    resetSelected();
    hideMenu();
  }
  
  return {
    addColumn,
    showColumnProperties,
    showTableProperties,
    deleteColumn,
  }
}


export default useFunctions;