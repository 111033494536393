import React from 'react';

import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { useDocState } from 'app/ui/contexts/document';
import useItemContextMenu from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-context-menu';
import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import useImagesMenu from '../../hooks/use-images-menu';
import useImageMenu from '../../hooks/use-image-menu';

import { MainWrapper } from './styles';


interface Props {
  image: RepoImagesTypes.Image;
  children: React.ReactNode;
}


export const ImageContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    image,
    children,
  } = props;

  const docState = useDocState();
  const getConfigImage = useImageMenu();
  const getConfigImages = useImagesMenu();
  const resetAllSelected = useAllSelectedReset();
  
  const {
    setGalleryImagesSelected,
  } = useEditorStatesSetters()

  type ImageId = RepoImagesTypes.ImageId;

  const handleContextMenu_ = useItemContextMenu<ImageId>({
    isSelected: (imageId: ImageId) => docState.galleryImageSelected.isSelected(imageId),
    isMultiselected: () => docState.galleryImageSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    resetOtherSelected: () => resetAllSelected({skipContentColumnReset: true}),
    select: (imageId: ImageId) => {
      docState.galleryImageSelected.select(imageId);
      setGalleryImagesSelected();
    },
    getConfigItems: () => getConfigImages(),
    getConfigItem: (imageId: ImageId) => getConfigImage(imageId),
  });

  const handleContextMenu = (event: React.MouseEvent) => {
    handleContextMenu_(event, image.bid);
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
  
