import React from 'react';

import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import { DNDContext }       from 'app/ui-v2/editor-instruction/dnd-context';
import HLS_DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import SectionNameDraggedViewComponent from './section-name-dragged-view';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';


interface Props {
  sectionAddr: TypesContent.SectionAddr;
  children: React.ReactNode;
}


export const SectionNameDraggerComponent: React.FC<Props> = (props: Props) => {
  const {
    sectionAddr,
    children
  } = props;

  const setDNDData = useDNDDataSet();
  const { hideMenu } = useContextMenu();

  const handleDragStart = () => {
    const data = { sectionAddr };
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  const handleDragMoveFirst = () => {
    hideMenu();
  }

  return (
    <HLS_DraggerComponent
      dndContext={DNDContext.viewContent.table.section}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      draggedView={
        <SectionNameDraggedViewComponent sectionAddr={sectionAddr} />
      }
    >
      { children }
    </HLS_DraggerComponent>
  );
}
