import React from 'react';
import { Text } from './styles';
import { TextWrapper } from './styles';


interface Props {
  text: string;
  Loader: React.ElementType;
}


export const ProgressModalViewComponent: React.FC<Props> = (props: Props) => {
  const {
    text,
    Loader,
  } = props;

  return (
    <TextWrapper>
      <Text>
      { text }
      </Text>
      <Loader />
    </TextWrapper>
  );
}

