import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useTranslations } from "app/ui/hooks/app/use-translation";
import { useDocState }     from 'app/ui/contexts/document';
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useContextElement   from 'app/ui-v2/editor-instruction/hooks/use-context-element';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSidetoolbarPropertiesShow from '../../../use-sidetoolbar-properties-show';
import useSectionSelectedSet        from '../use-section-selected-set';
import SectionCreatePanelComponent  from './components/section-create-panel';
import { SectionCreatePanelProps }  from './components/section-create-panel';
import useSectionDelete             from '../use-section-delete';
import useSectionPropsPanel from '../use-section-props-panel';


const useSectionMenu = () => {
  const t = useTranslations();
  const document = useDocState();

  const {
    setContent
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();

  const {
    showElement,
  }  = useContextElement();

  const showSectionPropsPanel = useSectionPropsPanel();

  const deleteSectionHook = useSectionDelete();
  const setSectionSelected = useSectionSelectedSet();
  const showSideToolbarProps = useSidetoolbarPropertiesShow();

  const addSectionAbove = (sectionAddr: Types.SectionAddr, event: React.PointerEvent) => {
    const newSectionAddr = document.content.addSectionAbove(sectionAddr);
    document.saveUndo();
    setContent();

    setSectionSelected({sectionAddr: newSectionAddr});
    hideMenu();

    // __addSection({
    //   srcSectionAddr: sectionAddr,
    //   sectionAddPlacement: SectionAddPlacement.ABOVE_SRC_SECTION,
    // }, event);
  }

  const addSectionBelow = (sectionAddr: Types.SectionAddr, event: React.PointerEvent) => {
    const newSectionAddr = document.content.addSectionBelow(sectionAddr);
    document.saveUndo();
    setContent();

    setSectionSelected({sectionAddr: newSectionAddr});
    hideMenu();

    // __addSection({
    //   srcSectionAddr: sectionAddr,
    //   sectionAddPlacement: SectionAddPlacement.BELOW_SRC_SECTION,
    // }, event);
  }

  const __addSection = (
    panelProps: Omit<SectionCreatePanelProps, "onDone">,
    event: React.PointerEvent,
  ) => {
    const onDone = (newSectionAddr: Types.SectionAddr) => {
      setSectionSelected({sectionAddr: newSectionAddr});
    }

    showElement({
      Element: SectionCreatePanelComponent, 
      ElementProps: {
        ...panelProps,
        onDone,
      },
      event
    });

    hideMenu();
  }

  const deleteSection = (sectionAddr: Types.SectionAddr) => {
    deleteSectionHook(sectionAddr);
    hideMenu();
  }
  
  const showSectionProperties = (
    sectionAddr: Types.SectionAddr, 
  ) => {
    showSectionPropsPanel();
    hideMenu();
  }
  
  return {
    addSectionAbove,
    addSectionBelow,
    showSectionProperties,
    deleteSection,
  }
}


export default useSectionMenu;