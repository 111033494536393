import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';

import { HeaderMarkersSelectedState as State } from 'app/arch/editor-instruction/document/states/sessions/header-markers-selected';
import { HeaderMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';


/**
 * Atom
 */

export const state = atom<State.State>({
  key: "ei_headerMarkers",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const isSelected = selectorFamily({
  key: "ei_headerMarkers__isSelected",
  get: (markerAddr: HeaderMarkersTypes.MarkerAddr) => ({ get }) => {
    const stateObj = get(state);
    const selected = State.isSelected(stateObj, HeaderMarkersTools.getMarkerKey(markerAddr));
    return selected;
  }
});

export const selected = selector({
  key: "ei_headerMarkers__selected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selected = State.getSelected(stateObj);

    return selected;
  }
});
  