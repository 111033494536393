import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import WindowManagedComponent from 'lego-v2/window-managed';

import { Position } from 'app/arch/types';
import { Size } from 'app/arch/types';
import { ContextPanelSessionDefaults } from 'app/arch/editor-instruction/document/states/sessions/context-panel-session';

import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_ContextPanelSession } from 'app/ui/states/editor-instruction';


interface Props {
  children: React.ReactNode;
}


export const ContextPanelWindowComponent: React.FC<Props> = (props: Props) => {
  const { 
    children 
  } = props;

  const document = useDocState();
  const visible  = useRecoilValue(UIState_ContextPanelSession.windowVisible);
  const windowSize  = useRecoilValue(UIState_ContextPanelSession.windowSize);

  const {
    setContextPanelSession,
  } = useEditorStatesSetters();


  //----------------
  // No need to read it from 'dynamic' recoil
  // as this component gets removed from DOM on close
  const contextPanelSession = document.contextPanelSession;
  const windowPosition = contextPanelSession.getWindow().position;
  const windowMaximized = contextPanelSession.getWindow().maximized;
  const windowSizeMin = ContextPanelSessionDefaults.getWindowSizeMin();
  const windowSizeMax = ContextPanelSessionDefaults.getWindowSizeMax();
  
  const windowTitle = contextPanelSession.getWindow().title;

  const handleWindowSize = (size: Size) => {
    contextPanelSession.updateWindow({size});
  }

  const handleWindowPosition = (position: Position) => {
    contextPanelSession.updateWindow({position});
  }

  const handleWindowMaximized = (maximized: boolean) => {
    contextPanelSession.updateWindow({maximized});
  }

  const handleWindowUpdateDone = () => {
    setContextPanelSession();
  }

  const handleWindowClose = () => {
    document.contextPanelSession.updateWindow({visible: false});
    setContextPanelSession();
  }


  return (
    <WindowManagedComponent
      title={windowTitle}
      visible={visible}
      maximizable={false}

      windowSize={windowSize}
      windowSizeMin={windowSizeMin}
      windowSizeMax={windowSizeMax}
      windowPosition={windowPosition}
      windowMaximized={windowMaximized}

      onWindowSize={handleWindowSize}
      onWindowPosition={handleWindowPosition}
      onWindowMaximized={handleWindowMaximized}

      onWindowUpdateDone={handleWindowUpdateDone}
      onWindowClose={handleWindowClose}
    >
      { children }
    </WindowManagedComponent>
  );
}
