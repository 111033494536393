import { useDocState }        from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset       from 'app/ui-v2/editor-image/hooks/use-selected-reset';


const useFunctions = () => {
  const document = useDocState();

  const resetSelected = useSelectedReset();

  const {
    setContent,
  } = useEditorStatesSetters();

  /**
   * Fns defs
   */
  const resetSelectedWidgets = (event: KeyboardEvent) => {
    resetSelected();
    return false;
  }

  const deleteSelectedWidgets = (event: KeyboardEvent) => {
    const selectedWidgetAddr = document.editorImageSession.getWidgetSelected();
    const selectedWidgetsAddrs = document.editorImageSession.getMultiSelectionSelectedWidgets();
    const selectedImageAddr  = document.editorImageSession.getMultiSelectionImageAddr();

    //
    // Single widget selected
    //
    if (selectedWidgetAddr !== null) {
      document.content.cellImages_image_deleteWidget(selectedWidgetAddr);
      document.saveUndo();
      setContent();

      resetSelected();

      return true;
    }
    //
    // Multi widgets selected
    //
    else if (selectedWidgetsAddrs) {
      selectedWidgetsAddrs.forEach(widgetAddr => {
        document.content.cellImages_image_deleteWidget(widgetAddr);
      });

      document.saveUndo();
      setContent();

      resetSelected();

      return true;
    }

    return false;
  }


  /**
   * Hook
   */
  return {
    resetSelectedWidgets,
    deleteSelectedWidgets,
  }
}

export default useFunctions;