import React from 'react';
import { useRef } from 'react';

import CellTextEditorComponent from './cell-text-editor';
import CellCSSCustomComponent  from './cell-css-custom';

import { CellProps }               from '../types';
import CellSelectComponent         from '../parts/cell-select';
import CellSelectedBorderComponent from '../parts/cell-selected-border';
import CellLineSelectedComponent   from '../parts/cell-line-selected';
import CellsLineDragOverComponent  from '../parts/cells-line-drag-over';
import ColumnDropComponent         from '../parts/column-drop';
import CellContextMenuCancelerComponent from './cell-context-menu-canceler';


interface Props extends CellProps {
}


export const CellTextComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr
  } = props;

  const editorTextRef = useRef<HTMLDivElement | null>(null);

  return (
    <CellContextMenuCancelerComponent>
      <CellSelectedBorderComponent pageCellAddr={pageCellAddr} >
        <CellLineSelectedComponent pageCellAddr={pageCellAddr} >
          <CellsLineDragOverComponent pageCellAddr={pageCellAddr} >
            <CellSelectComponent pageCellAddr={pageCellAddr} >
              <ColumnDropComponent pageCellAddr={pageCellAddr} >
                <CellCSSCustomComponent 
                  pageCellAddr={pageCellAddr}
                  editorTextRef={editorTextRef}
                >
                  <CellTextEditorComponent
                    pageCellAddr={pageCellAddr}
                    editorTextRef={editorTextRef}
                  /> 
                </CellCSSCustomComponent>
              </ColumnDropComponent>
            </CellSelectComponent>
          </CellsLineDragOverComponent>
        </CellLineSelectedComponent>
      </CellSelectedBorderComponent>
    </CellContextMenuCancelerComponent>
  );
}
  
