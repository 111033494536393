import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useMarkerEdit   from 'app/ui-v2/editor-markers/hooks/use-marker-edit';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import useCellMarkerCreate from '../use-cell-marker-create';


const useFunctions = () => {
  const docState = useDocState();

  const {
    hideMenu
  } = useContextMenu();

  const {
    setContent,
    setRepoMarkers,
  } = useEditorStatesSetters();

  const createCellMarker = useCellMarkerCreate();
  const editMarker__ = useMarkerEdit();

  const createMarker = (cellAddr: ContentTypes.CellAddr) => {
    createCellMarker(cellAddr);
    hideMenu();
  }

  const editMarker = (markerAddr: ContentTypes.MarkerAddr) => {
    const markerProps = docState.content.cellMarkers_getMarkerProps(markerAddr);
    editMarker__(markerProps.repoMarkerAddr);
    hideMenu();
  }

  const removeMarker = (markerAddr: ContentTypes.MarkerAddr) => {
    docState.content.cellMarkers_removeMarker(markerAddr);
    docState.saveUndo();
    setContent();

    hideMenu();
  }

  const duplicateMarker = (markerAddr: ContentTypes.MarkerAddr) => {
    const cellAddr = markerAddr;
    const markerProps = docState.content.cellMarkers_getMarkerProps(markerAddr);
    const newMarkerAddr = docState.repoMarkers.duplicateMarker(markerProps.repoMarkerAddr);

    docState.content.cellMarkers_addMarker(newMarkerAddr, cellAddr);

    docState.saveUndo();
    setContent();
    setRepoMarkers();
    
    editMarker__(newMarkerAddr);
    hideMenu();
  }


  return {
    createMarker,
    editMarker,
    removeMarker,
    duplicateMarker
  }
}


export default useFunctions;