import produce from 'immer';
import { UUID } from 'app/arch/types';
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import * as TypesSession from './types';



//----------------------------
// State
//

export type State = {
  cell: {
    selected: Types.CellAddr | null,
  },

  column: {
    selected: Types.ColumnAddr | null,
  },

  row: {
    selected: Types.RowAddr | null,
  },

  signal: {
    columns: {
      width: {[releaselogId: UUID]: any}
    }
  },

  panel: {
    document: TypesSession.PanelDocumentState,
  },

};



//----------------------------
// Producers
//

export const setCellSelected = produce((
  draft: State,
  cellAddr: Types.CellAddr | null
) => {
  draft.cell.selected = cellAddr;
});

export const setColumnSelected = produce((
  draft: State,
  columnAddr: Types.ColumnAddr | null
) => {
  draft.column.selected = columnAddr;
});

export const setRowSelected = produce((
  draft: State,
  rowAddr: Types.RowAddr | null
) => {
  draft.row.selected = rowAddr;
});

export const setSignalColumnsWidth = produce((
  draft: State,
  releaselogId: UUID
) => {
  draft.signal.columns.width[releaselogId] = {}
});

export const setPanelDocument = produce((
  draft: State,
  update: Partial<TypesSession.PanelDocumentState>
) => {
  const panels = draft.panel;
  panels.document = {
    ...panels.document,
    ...update
  }
});

//----------------------------
// Getters
//

export const getColumnSelected = (state: State) => state.column.selected;
export const getRowSelected    = (state: State) => state.row.selected;
export const getCellSelected   = (state: State) => state.cell.selected;

export const getSignalColumnsWidth = (state: State, releaselogId: UUID) => {
  const singal = state.signal.columns.width;
  if (releaselogId in singal) {
    return singal[releaselogId];
  }
  return null;
}

export const getPanelDocument = (state: State) => state.panel.document;


//----------------------------
// Create initial state
//

export const createInitialState = () => {
  const state: State = {
    cell: {
      selected: null,
    },

    column: {
      selected: null,
    },

    row: {
      selected: null,
    },

    signal: {
      columns: {
        width: {}
      }
    },

    panel: {
      document: {
        visible: false,
        selected: TypesSession.PanelDocumentItem.PAGE_SETTINGS
      },
    },

  
  }

  return state;
}
