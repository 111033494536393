import { MenuItemType } from 'lego-v2/menu';
import { Icon } from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import useFunctions from './__use-functions';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();

  const getColumnAdd = (columnAddr: Types.ColumnAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('column, add'),
      iconComponent: Icon.Create,
      onClick: (event: React.PointerEvent) =>  fns.addColumn(columnAddr, event),
    }
  });

  const getProperties = () => ({
    type: MenuItemType.SUB_MENU,
    data: {
      title: t('properties'),
      iconComponent: Icon.Settings,
      onClick: () => {
        // Nothing to do here
      },
      sections: [
        {
          items: [
            getColumnProps(),
            getTableProps(),
          ]
        }
      ]
    }
  });

  const getColumnProps = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('column'),
      iconComponent: Icon.Column,
      onClick: (event: React.PointerEvent) =>  fns.showColumnProperties(),
    }
  });

  const getTableProps = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('table'),
      iconComponent: Icon.Table,
      onClick: (event: React.PointerEvent) =>  fns.showTableProperties(),
    }
  });

  const getColumnHide = (columnAddr: Types.ColumnAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('hide'),
      iconComponent: Icon.Hide,
      onClick: () =>  fns.hideColumn(columnAddr),
    }
  });

  const getColumnDelete = (columnAddr: Types.ColumnAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('delete'),
      variant: 'delete',
      iconComponent: Icon.Delete,
      onClick: () =>  fns.deleteColumn(columnAddr),
    }
  });

  
  return {
    getColumnAdd,
    getProperties,
    getColumnProps,
    getTableProps,
    getColumnHide,
    getColumnDelete,
  }
}


export default useItems;