import PanelItemGenerator  from 'app/ui-v2/editor-instruction/panel-items/generator';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useFunctions from './__use-functions';
import useStates from './__use-states';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();
  const states = useStates();

  const getPageOrientation = () => 
    PanelItemGenerator.getPageOrientation({
      pageOrientation: states.pageLayout.orientation,
      onClick: fns.setPageOrientation,
  });

  const getPageSize = () => 
    PanelItemGenerator.getPageSize({
      pageFormat: states.pageLayout.format,
      onClick: fns.setPageFormat,
  });

  const getDocMetaVisible = () => 
    PanelItemGenerator.getPanelVisibility({
      visible: states.headerMetaVisible,
      onClick: fns.setMetaVisible,
      title: t("title"),
    });


  const getReleaselogVisible = () => 
    PanelItemGenerator.getPanelVisibility({
      visible: states.releaselogVisible,
      onClick: fns.setReleaselogVisible,
      title: t("releaselog"),
    });

  return {
    getPageOrientation,
    getPageSize,

    getDocMetaVisible,
    getReleaselogVisible,
  }
}


export default useItems;