import React from 'react';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }        from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset       from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useItemMultiSelect from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-multi-select';

import { MarkerWrapper } from './styles';


interface Props {
  markerAddr: ContentTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children,
  } = props;

  const docState = useDocState();
  const resetAllSelected = useSelectedReset();

  const {
    setContentSession,
    setContentCellMarkersSelected,
  } = useEditorStatesSetters();

  type Addr = ContentTypes.MarkerAddr;

  const handleSelectItem = useItemMultiSelect<Addr>({
    isSelected: (addr: Addr) => docState.contentCellMarkersSelected.isSelected(addr),
    isMultiselected: () => docState.contentCellMarkersSelected.isMultiselected(),
    resetSelected: () => {
      resetAllSelected({
        skipContentCellReset: true,
      });
    },
    select: (addr: Addr) => {
      docState.contentCellMarkersSelected.select(addr);
      docState.contentSession.setCellSelected(addr);
    },
    multiSelect: (addr: Addr) => {
      resetAllSelected({
        skipContentCellMarkersReset: true
      });
      docState.contentCellMarkersSelected.toggleSelect(addr);
    },
    toggleSelect: (addr: Addr) => {
      docState.contentCellMarkersSelected.toggleSelect(addr);
      docState.contentSession.setCellSelected(addr);
    },
    setState: () => {
      setContentSession();
      setContentCellMarkersSelected();
    }
  });


  const handleSelectMarker = (event: React.MouseEvent) => {
    handleSelectItem(event, markerAddr);
  }

  return (
    <MarkerWrapper onPointerDown={handleSelectMarker}>
      { children }
    </MarkerWrapper>
  );
}
  
