import { atom }     from 'recoil';
import { selector } from 'recoil';
import { ContextPanelSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/context-panel-session';


//----------------
//
// State
//
export const state = atom<State.State>({
  key: "ei_contextPanelSession",
  default: State.createInitialState()
});


//--------------------
//
// Selectors
//


export const windowVisible = selector({
  key: 'ei_contextPanelSession_windowVisible',
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getWindow(stateObj).visible;
  }
});

export const windowContent = selector({
  key: 'ei_contextPanelSession_windowContent',
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getWindow(stateObj).content;
  }
});

export const windowSize = selector({
  key: 'ei_contextPanelSession_windowSize',
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getWindow(stateObj).size;
  }
});
