import styled from 'styled-components';


export const Panel = styled.div`
  display: flex;
  gap: ${props => props.theme.defs.gap.large};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 50px;
  /* background-color: red; */
`;
