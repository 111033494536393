import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import Logger from 'libs/debug';

import { Position } from 'app/arch/types';
import { Size } from 'app/arch/types';
import { useDocState } from 'app/ui/contexts/document';
import useDocumentScaleRef from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-ref';
import useEditorStatesSetters from '../../../hooks/use-editor-states-setters';

import * as Types from '../types';

import { Scaler }  from './styles';
import { Virtual } from './styles';
import { Dragged } from './styles';
import { Wrapper as WrapperDefault } from './styles';


interface Props extends Types.DraggerProps {
}


export const DraggerTouchComponent: React.FC<Props> = (props: Props) => {
  const {
    dndContext,
    onDragStart,
    onDragMoveFirst,
    onDragEnd,
    children, 
  } = props;

  const document = useDocState();
  const Wrapper = props.Wrapper || WrapperDefault;
  const scaleRef = useDocumentScaleRef();
  const [position, setPosition] = useState<Position>([-99999, -999999]);
  const moveFirstDoneRef = useRef(false);
  
  const logger = Logger.getEditorDraggerTouch();
  
  const debug = (
    props.debug !== undefined ?
    props.debug :
    false
  );

  const draggedView = props.draggedView || children;

  const {
    setEditorSession 
  } = useEditorStatesSetters();
  
  const [
    state, 
    setState
  ] = useState<Types.State>(Types.State.STOPPED);
  
  const [
    draggedViewSize, 
    setDraggedViewSize
  ] = useState<Size>([0, 0]);

  const draggedViewRawRef = useRef<HTMLDivElement>(null);
  const draggedViewRef    = useRef<HTMLDivElement>(null);

  const touchInit = useRef<any | null>(null);

  
  /**
   * SETUP => STARTED
   */
  useEffect(()=> {
    if (state !== Types.State.SETUP) {
      return;
    }

    logger.log("Performing setup")

    if ( ! draggedViewRawRef.current ) {
      console.warn(`Dragger, draggedViewRawRef is null`);
      stop();
      return;
    }

    if ( ! draggedViewRef.current ) {
      console.warn(`Dragger, draggedViewRef is null`);
      stop();
      return;
    }

    if ( !  touchInit.current ) {
      console.warn(`Dragger, touchInit is null`);
      stop();
      return;
    }


    // Maybe onDragStart should be done on 
    // first move rather then here
    onDragStart?.();

    const scale = scaleRef.current !;
    const element = draggedViewRawRef.current;
    const bbox    = element.getBoundingClientRect();
    
    const width  = bbox.width  * scale;
    const height = bbox.height * scale;

    // const touch = touchInit.current;
    // const x = touch.clientX;
    // const y = touch.clientY;

    setDraggedViewSize([width, height]);
    setState(Types.State.STARTED);

    moveFirstDoneRef.current = false;
  }, [state])


  /**
   * STOPING => STOPING_CONTEXT
   */
  useEffect(()=> {
    if ( state !== Types.State.STOPING) {
      return;
    }

    logger.log("Performing stopping")

    const x = -9999999;
    const y = -9999999;
    setPosition([x, y]);
    setState(Types.State.STOPING_CONTEXT);
  }, [state]);


  /**
   * STOPING_CONTEXT => STOPPED
   */
  useEffect(()=> {
    // Stoping context can't be done within of stoping
    // step, as it would cause disabling dnd context before
    // drag drop has got its event    
    if ( state !== Types.State.STOPING_CONTEXT) {
      return;
    }

    logger.log("Performing stopping context")
    disableDNDContext();
    setState(Types.State.STOPPED);
    onDragEnd?.();
  }, [state]);


  //----------
  //
  const enableDNDContext = () => {
    document.editorSession.setDNDContext(dndContext);
    setEditorSession();
  }

  const disableDNDContext = () => {
    document.editorSession.setDNDData(null);
    document.editorSession.setDNDContext(null);
    setEditorSession();
  }

  const stop = () => {
    logger.log("Stop called")
    setState(Types.State.STOPING);
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    setState(Types.State.SETUP);
    enableDNDContext();

    const x = -9999999;
    const y = -9999999;
    setPosition([x, y]);

    touchInit.current = event.touches[0];
  }

  const handleTouchMove = (event: React.TouchEvent) => {
    const touches = event.touches;
    if (touches.length > 1) {
      stop();
      return;
    }

    if (state !== Types.State.STARTED) {
      console.warn("Drag move invalid state",state);
      return;
    }

    const touch = touches[0];
    const x = touch.clientX;
    const y = touch.clientY;
    
    setPosition([x, y]);

    if ( ! moveFirstDoneRef.current ) {
      moveFirstDoneRef.current = true;
      onDragMoveFirst?.();
    }
  }

  const handleTouchEnd = (event: React.TouchEvent) => {
    if ( state !== Types.State.STARTED ) {
      // This happens when multitouch has been pressed, and 
      // then touch listining has been stopped
      console.warn(`Dragger, drag end, invalid state: ${state}`);
      return;
    }

    logger.log("Drag handleTouchEnd");
    stop();
  }

  //----------
  // Renders

  const renderSetup = () => ReactDOM.createPortal(
    <Virtual ref={draggedViewRawRef} >
      { draggedView }
    </Virtual>,
    window.document.body
  );

  const renderPreview = () => {
    const [width, height] = draggedViewSize;
    const scale = scaleRef.current !;

    const left = position[0] - width / 2;
    const top = position[1] - height + height * 20 / 100;
    
    // const left = position[0] - 20 ;
    // const top = position[1] - 70 ;

    return ReactDOM.createPortal(
      <Dragged 
        ref={draggedViewRef}
        left={left}
        top={top}
        width={width}
        height={height}
      >
        <Scaler scale={scale} >
          { draggedView }
        </Scaler>
      </Dragged>,
      window.document.getElementById('drag-preview') as HTMLElement
      // TODO this should eithe be created on fly,
      // or even better be part of editor insturcion component
      // and passed here within editor context as ref -
      // so it gets created once, and there is no need for searching

    );
  }


  return (
    <>
      <Wrapper
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        debug={debug}
      >
        { children }
      </Wrapper>
      { state === Types.State.SETUP   && renderSetup() }
      { 
        state !== Types.State.STOPPED && 
        state !== Types.State.STOPING && 
        renderPreview() 
      }
    </>
  );
}

