import React from 'react';
import { useRef } from 'react';

import { CellPropsBase } from '../types';

import CellSelectComponent          from '../parts/cell-select';
import CellSelectedBorderComponent  from '../parts/cell-selected-border';
import CellsLineSelectedComponent   from '../parts/cells-line-selected';
import CellsLineDragOverComponent   from '../parts/cells-line-drag-over';
import ColumnDropComponent          from '../parts/column-drop';
import RowDropComponent             from '../parts/row-drop';
import CellTextEditorComponent      from './cell-text-editor';
import ColumnCSSCustomComponent     from './column-css-custom';
import CellTextContextMenuComponent from './cell-text-context-menu';
import CellContextMenuCancelerComponent from '../parts/cell-context-menu-canceler';


interface Props extends CellPropsBase {
}


export const CellTextComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;
  
  const editorTextRef = useRef<HTMLDivElement | null>(null);


  return (
    <CellSelectedBorderComponent cellAddr={cellAddr} >
      <CellsLineSelectedComponent cellAddr={cellAddr} >
        <CellsLineDragOverComponent cellAddr={cellAddr} >
          <CellSelectComponent cellAddr={cellAddr} >
            <CellContextMenuCancelerComponent>
            {/* <CellTextContextMenuComponent cellAddr={cellAddr} > */}
              <RowDropComponent cellAddr={cellAddr} >
                <ColumnDropComponent cellAddr={cellAddr} >
                  <ColumnCSSCustomComponent 
                    cellAddr={cellAddr}
                    editorTextRef={editorTextRef}
                  >
                    <CellTextEditorComponent
                      cellAddr={cellAddr}
                      editorTextRef={editorTextRef}
                    /> 
                  </ColumnCSSCustomComponent>
                </ColumnDropComponent>
              </RowDropComponent>
            {/* </CellTextContextMenuComponent> */}
            </CellContextMenuCancelerComponent>
          </CellSelectComponent>
        </CellsLineDragOverComponent>
      </CellsLineSelectedComponent>
    </CellSelectedBorderComponent>
  );
}
