import React from 'react';
import { useRecoilValue } from 'recoil';

import useCellImagesMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-images/use-cell-images-menu';
import TopToolbarComponent from 'app/ui-v2/editor-instruction/blocks/top-toolbar';
import { UIState_ContentCellImagesSelected } from 'app/ui/states/editor-instruction';
import { CellMenuProps } from '../types';


interface Props extends CellMenuProps {
}


export const CellImagesMenuComponent: React.FC<Props> = (props: Props) => {
  const imagesSelected = useRecoilValue(UIState_ContentCellImagesSelected.selected);
  const imagesSelectedKeys = Object.keys(imagesSelected);
  const showImagesSelectedMenu = imagesSelectedKeys.length > 0;
  
  if ( showImagesSelectedMenu ) {
    return null;
  }

  return (
    <Component {...props} />
  );
}
  

const Component: React.FC<Props> = (props: Props) => {
  const {
    cellAddr
  } = props;

  const getConfigCellMenu = useCellImagesMenu();
  const menuConfig = getConfigCellMenu(cellAddr);

  return (
    <TopToolbarComponent
      sections={menuConfig.sections}
    />
  );
}
