import React from 'react';

import { PanelType } from 'lego/components/side-controls/types';
import { PanelControlsConfig } from 'lego/components/panel-controls/config';
import SidePanelComponent from 'lego/components/side-controls/side-panel';


interface Props {
  config: PanelControlsConfig;
}


export const ContextPanelContentComponent: React.FC<Props> = (props: Props) => {
  const {
    config
  } = props;


  return (
    <SidePanelComponent
      config={config}
      panelType={PanelType.WIDE_MAX}
      visible={true}
    />
  );
}

