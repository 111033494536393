import { useState } from 'react';
import { useEffect } from 'react';

import ProgressComponent from 'app/ui-v2/hls/progress';
import { setup as setupProgressModal } from './use-progress-modal';

import { MainWrapper } from './styles';
import { TextWrapper } from './styles';
import { Text } from './styles';

import * as Types from './types';
import * as Defaults from './defaults';


interface Props {
}


export const ProgressModalComponent: React.FC<Props> = (props: Props) => {
  const [state, setState] = useState<Types.State>(Defaults.getState())
  
  useEffect(() => {
    setupProgressModal(setState);
    
    return () => {
      setupProgressModal(null);
    }
  }, [setState]);

  const viewRaw = state.opt === null;
  const viewText = (state.opt !== null && state.opt.message !== undefined);
  const viewComponent = (state.opt !== null && state.opt.Component !== undefined);

  return (
    <MainWrapper $visible={state.visible}>
      { viewRaw && <ProgressComponent /> }

      {
        viewText &&
        <TextWrapper>
          <Text>{ state.opt!.message }</Text>
          <ProgressComponent />
        </TextWrapper>
      }

      {
        viewComponent &&
        <>
          { state.opt!.Component }
        </>
      }
    </MainWrapper>
  );
};
