import PanelItemGenerator  from 'app/ui-v2/editor-instruction/panel-items/generator';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useFunctions from './__use-functions';
import useStates from './__use-states';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();
  const states = useStates();

  const getDocMetaVisible = () => 
    PanelItemGenerator.getPanelVisibility({
      visible: states.headerMetaVisible,
      onClick: fns.setMetaVisible,
      title: t("title"),
    });

  const getCustomRowsVisible = () => 
    PanelItemGenerator.getPanelVisibility({
      visible: states.headerInternalVisible,
      onClick: fns.setCutomRowsVisible,
      title: t("custom rows"),
    });

  const getCustomFieldsVisible = () => 
    PanelItemGenerator.getPanelVisibility({
      visible: states.headerFieldsVisible,
      onClick: fns.setCustomFieldsVisible,
      title: t("page header, custom fields"),
    });

  const getMarkersVisible = () => 
    PanelItemGenerator.getPanelVisibility({
      visible: states.markersVisible,
      onClick: fns.setMarkersVisible,
      title: t("page header, markers"),
    });

  const getSectionEnabled = () => 
    PanelItemGenerator.getToggleOnOff({
      title: t('sections'),
      selected: states.sectionsEnabled,
      onClick: fns.setSectionsEnabled
    });

  const getPageOrientation = () => 
    PanelItemGenerator.getPageOrientation({
      pageOrientation: states.pageLayout.orientation,
      onClick: fns.setPageOrientation,
  });

  const getPageSize = () => 
    PanelItemGenerator.getPageSize({
      pageFormat: states.pageLayout.format,
      onClick: fns.setPageFormat,
  });
  
  
  return {
    getDocMetaVisible,
    getCustomRowsVisible,
    getCustomFieldsVisible,
    getMarkersVisible,

    getSectionEnabled,

    getPageOrientation,
    getPageSize,
  }
}


export default useItems;