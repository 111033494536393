import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';

import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import { MainWrapper } from './styles';
import useItemMultiSelect from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-multi-select';


interface Props {
  columnAddr: ContentTypes.ColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children,
  } = props;

  const docState = useDocState();
  const resetAllSelected = useAllSelectedReset();

  const {
    setContentColumnsSelected,
  } = useEditorStatesSetters();

  type Addr = ContentTypes.ColumnAddr;

  const handleSelectItem = useItemMultiSelect<Addr>({
    isSelected: (addr: Addr) => docState.contentColumnsSelected.isSelected(addr),
    isMultiselected: () => docState.contentColumnsSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    select: (addr: Addr) => docState.contentColumnsSelected.select(addr),
    multiSelect: (addr: Addr) => {
      resetAllSelected({skipContentColumnReset: true});
      docState.contentColumnsSelected.toggleSelect(addr);
    },
    toggleSelect: (addr: Addr) => docState.contentColumnsSelected.toggleSelect(addr),
    setState: () => setContentColumnsSelected(),
  });

  const handleSelectColumn = (event: React.MouseEvent) => {
    handleSelectItem(event, columnAddr);
  }

  return (
    <MainWrapper onPointerDown={handleSelectColumn}>
      { children }
    </MainWrapper>
  );
}
  
