import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig }   from 'lego-v2/menu/config';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import Icon from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useItems from './__use-items';


const useWidgetContextMenuConfig = (widgetAddr: ContentTypes.WidgetAddr) => {
  const t = useTranslations();
  const items = useItems(widgetAddr);

  const getConfig = () => {
    const menuConfig: MenuConfig = {
      sections: [
        {
          items: [
            items.getWidgetEdit(),
            items.getWidgetDuplicate(),
          ]
        },
        {
          items: [
            {
              type: MenuItemType.SUB_MENU,
              data: {
                title: t('widgets styles'),
                iconComponent: Icon.Styles,
                onClick: () => {
                  // Nothing to do here
                },
                sections: [
                  {
                    items: [
                      items.getWidgetStyleCopy(),
                      items.getWidgetStylePaste(),
                    ]
                  },
                  {
                    items: [
                      items.getWidgetStyleSetDefault(),
                    ]
                  }
                ]
              }
            },    
          ]
        },

        {
          items: [
            items.getWidgetMoveUp(),
            items.getWidgetMoveDown(),
          ]
        },        
        {
          items: [
            items.getWidgetDelete(),
          ]
        },
      ]
    }
  
    return menuConfig;
  }


  const hook = () => {
    return getConfig();
  }

  return hook;
}

export default useWidgetContextMenuConfig;