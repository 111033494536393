import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

export const cipa = () => {}

const useSelectedReset = () => {
  const docState = useDocState();

  const {
    setCustomFieldsColumnsSelected,
    setHeaderFieldsSession,
  } = useEditorStatesSetters();

  const hook = (props?: {
    skipStateUpdate?: boolean,
  }) => {
    const skipStateUpdate = props?.skipStateUpdate ?? false;
    
    docState.customFieldsColumnsSelected.reset();
    // TODO: should it be here?
    docState.headerFieldsSession.setPageCellSelected(null);

    if (skipStateUpdate) {
      return;
    }

    setCustomFieldsColumnsSelected();
    setHeaderFieldsSession();
  }

  return hook;
}


export default useSelectedReset;
