import React from 'react';
import { useRecoilValue } from 'recoil';

import CascadeComponent from 'lego-v2/cascade';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_ContentCellImagesSelected } from 'app/ui/states/editor-instruction';

import ImageDraggedViewComponent from '../image-dragged-view';
import { MainWrapper } from './styles';


interface Props {
}


export const ImagesDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const imagesSelected  = useRecoilValue(UIState_ContentCellImagesSelected.selected);

  const renderImages = () => {
    const imagesKeys = Object.keys(imagesSelected);

    const Images = imagesKeys.map((imageKey, idx) => {
      const imageAddr = ContentTools.imageKeyToAddr(imageKey);
      return (
        <React.Fragment key={`${imageKey}`} >
          <ImageDraggedViewComponent imageAddr={imageAddr}/>
        </React.Fragment>
      );
    });

    return Images;
  }


  return (
    <MainWrapper>
      <CascadeComponent elements={renderImages()} />
    </MainWrapper>
  );
}
