import { useEffect } from 'react';

import { useProgressModal } from 'lego-v2/progress-modal';

import { mutation }    from "app/arch/backend";
import { useMutation } from "app/arch/backend/use-mutation";

import { useTranslations }   from "app/ui/hooks/app/use-translation";
import { useInstruction }    from 'app/ui/hooks/editor-instruction/use-instruction';
import { useDocState }       from 'app/ui/contexts/document';
import { DialogCustomTypes } from 'app/ui/components/editor-instruction/dialogs/dialog-custom';
import { useDialogContinueCancel } from 'app/ui/components/editor-instruction/dialogs/dialog-continue-cancel/use-dialog-continue-cancel';
import { useMsgBox }              from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import useDocumentSave            from 'app/ui-v2/editor-instruction/hooks/use-document-save';
import useDocumentReleaseDownload from 'app/ui-v2/app/hooks/use-document-release-download';
import useEditorStatesSetters     from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import usePrintoutReleaseInfoGet  from 'app/ui-v2/editor-image/hooks/use-printout-release-info-update';

import usePDFGenerateLockCheck from '../use-pdf-generate-lock-check';
import ProgressViewComponent from './progress-view';


export interface OnDoneProps {
  ok: boolean, 
  instructionId: number;
  revisionReleased: number,
  revisionNext: number,
  releaseDate: string,
}


const useDocumentRelease = () => {
  const t = useTranslations();
  const docState = useDocState();
  const instruction = useInstruction();
  const getPrintoutReleaseInfo = usePrintoutReleaseInfoGet();
  const {
    showProgressModal, 
    hideProgressModal
  } = useProgressModal();

  const { showMsgBox } = useMsgBox();

  const dialogContinueCancel = useDialogContinueCancel();
  const getPDFGenerateLock   = usePDFGenerateLockCheck();
  const saveDocument         = useDocumentSave({silent: true});
  const downloadDocumentRelease = useDocumentReleaseDownload();

  const {
    setInstruction,
    setReleaselogs
  } = useEditorStatesSetters();


  //
  // Generate document preview PDF
  //
  const { 
    error: mutationError,
    mutation: createInstructionRelease,
    data: createInstructionReleaseData, 
  } = useMutation(
    mutation.createInstructionRelease, 
  );
  
  useEffect(() => {
    if ( ! mutationError ) {
      return;
    }

    hideProgressModal();
  }, [mutationError]);


  useEffect(() => {
    if (! createInstructionReleaseData) {
      return;
    }

    const data = createInstructionReleaseData.instructionReleaseCreate;
    if ( ! data.ok ) {
      console.error(`Can't create document release`);
      return;
    }

    onReleaseDone(data);
  }, [createInstructionReleaseData]);


  const onReleaseDone = (props: OnDoneProps) => {
    const {
      instructionId,
      releaseDate,
      revisionReleased,
      revisionNext,
    } = props;

    hideProgressModal();
    showMsgBox(t("document, release, done"));

    const showProgress = false;

    downloadDocumentRelease({
      documentId: instructionId,
      revision: revisionReleased,
      showProgress,
    });

    docState.instruction.setRevision(revisionNext);
    setInstruction();

    commitReleaselog(revisionReleased, releaseDate);
  }

  const commitReleaselog = (
    revisionReleased: number, 
    releaseDate: string,
  ) => {   
    docState.releaselogs.commitReleaselog(revisionReleased, releaseDate);
    setReleaselogs();
    saveDocument();

    // UNDO problem - if someone will
    // keep pressing undo now - changelog
    // will get to its previous form - and
    // it should not do that after release.
    // Therefore we reset undo here.
    docState.resetUndo();
  }

  const releaseDocument = () => {
    showProgressModal({Component: <ProgressViewComponent />});
    
    // addAction({
    //   textStart: t("document, release, do"), 
    //   textStop: t("document, release, done")
    // });

    const instructionId     = instruction.id !;
    const contentSerial     = docState.serialize();
    const releaseInfoSerial = getPrintoutReleaseInfo();

    createInstructionRelease({
      variables: { 
        instructionId, 
        content: contentSerial,
        releaseInfo: releaseInfoSerial,
      }    
    });
  }

  const showConfirmationDialog = () => {
    dialogContinueCancel.show({
      dataTest: 'editor__dialog__doc-release',
      title:   t('document release, title'), 
      message: t('document release, message'),
      icon: DialogCustomTypes.IconType.QUESTION,
      onContinueHandler: () => {
        releaseDocument();
      }
    });
  }


  const showReleaselogIsEmptyDialog = () => {
    dialogContinueCancel.show({
      dataTest: 'editor__dialog__doc-releaselog-is-empty',
      title:   t('document empty releaselog, title'), 
      message: t('document empty releaselog, message'),
      icon: DialogCustomTypes.IconType.ERROR,
      onContinueHandler: () => {
        releaseDocument();
      }
    });
  }

  //
  // Whole routine - generate & download
  //
  const hook = () => {
    getPDFGenerateLock({
      onNotLocked: () => {
        const releaselogAddr = docState.releaselogs.getReleaselogActive();
        const isReleaselogEmpty = docState.releaselogs.isReleaselogEmpty(releaselogAddr);

        if ( isReleaselogEmpty ) {
          showReleaselogIsEmptyDialog();
          return;
        }

        showConfirmationDialog();
      }
    });
  }

  return hook;
}


export default useDocumentRelease;
