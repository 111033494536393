import { useDocState } from 'app/ui/contexts/document';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDocumentImagesDelete from 'app/ui-v2/editor-instruction/hooks/use-document-images-delete';


const useFunctions = () => {
  const docState = useDocState();
  const deleteDocumentImages = useDocumentImagesDelete();

  const {
    setGalleryImagesSelected,
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();

  const deleteImages = () => {
    const images = docState.galleryImageSelected.getSelected();
    const imagesKeys = Object.keys(images);
    const imagesIds = imagesKeys.map(imageKey => +imageKey);

    deleteDocumentImages({ 
      imagesIds,
      onDone: () => {
        docState.galleryImageSelected.reset();
        setGalleryImagesSelected();
      }
    });

    hideMenu();
  }


  return {
    deleteImages,
  };
}


export default useFunctions;