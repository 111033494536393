import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { ContentTools } from "app/arch/editor-instruction/document/states/persistent/content";
import { useDocState } from 'app/ui/contexts/document';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import useDNDDataSet from "app/ui-v2/editor-instruction/hooks/use-dnd-data-set";
import HLSDraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import MarkersDraggedViewComponent from "./markers-dragged-view";
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";


interface Props {
  markerAddr: ContentTypes.MarkerAddr
  children: React.ReactNode;
}


export const MarkerDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr, 
    children, 
  } = props;
  
  const docState  = useDocState();
  const setDNDData = useDNDDataSet();
  const { hideMenu } = useContextMenu();

  const {
    setContentCellMarkersSelected,
  } = useEditorStatesSetters();

  const handleDragStart = () => {
  }

  const handleDragMoveFirst = () => {
    hideMenu();

    const markersSelected = docState.contentCellMarkersSelected.getSelected();
    const markersKeys = Object.keys(markersSelected);
    const markersAddrs = markersKeys.map(markerKey => ContentTools.markerKeyToAddr(markerKey));
    
    if ( ! docState.contentCellMarkersSelected.isSelected(markerAddr) ) {
      markersAddrs.splice(0, 0, markerAddr);
      docState.contentCellMarkersSelected.select(markerAddr);
      setContentCellMarkersSelected();
    }

    const data = {markersAddrs: markersAddrs}
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  return (
    <HLSDraggerComponent
      debug={false}
      dndContext={DNDContext.marker.cell}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      draggedView={
        <MarkersDraggedViewComponent />
      }
    >
      { children }
    </HLSDraggerComponent>
  );
}
