import { MenuItemType } from 'lego-v2/menu';
import { Icon } from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useFunctions from './__use-functions';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();

  const getProperties = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('properties'),
      iconComponent: Icon.Settings,
      onClick: (event: React.PointerEvent) => fns.showProperties(),
    }
  });


  return {
    getProperties,
  }
}


export default useItems;