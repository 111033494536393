import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useColumnMenu from "app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/column/use-column-menu";
import useColumnsMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/column/use-columns-menu';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useItemContextMenu from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-context-menu';
import { useDocState } from 'app/ui/contexts/document';

import { MainWrapper } from './styles';


interface Props {
  columnAddr: ContentTypes.ColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children,
  } = props;

  const docState = useDocState();
  const getConfigSingleColumn = useColumnMenu();
  const getConfigMultiColumns = useColumnsMenu();
  const resetAllSelected = useAllSelectedReset();

  const {
    setContentColumnsSelected,
  } = useEditorStatesSetters()

  const handleContextMenu_ = useItemContextMenu<ContentTypes.ColumnAddr>({
    isSelected: (addr: ContentTypes.ColumnAddr) => docState.contentColumnsSelected.isSelected(addr),
    isMultiselected: () => docState.contentColumnsSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    resetOtherSelected: () => resetAllSelected({skipContentColumnReset: true}),
    select: (addr: ContentTypes.ColumnAddr) => {
      docState.contentColumnsSelected.select(addr);
      setContentColumnsSelected();
    },
    getConfigItems: () => getConfigMultiColumns(),
    getConfigItem: (addr: ContentTypes.ColumnAddr) => getConfigSingleColumn(addr)
  });

  const handleContextMenu = (event: React.MouseEvent) => {
    handleContextMenu_(event, columnAddr);
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
  
