import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useAllSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useItemMultiSelect from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-multi-select';

import { MainWrapper } from './styles';


interface Props {
  imageAddr: ContentTypes.ImageAddr;
  children: React.ReactNode;
}


export const ImageSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children,
  } = props;

  const docState = useDocState();
  const resetAllSelected = useAllSelectedReset();

  const {
    setContentSession,
    setContentCellImagesSelected,
  } = useEditorStatesSetters();

  type Addr = ContentTypes.ImageAddr;

  const handleSelectItem = useItemMultiSelect<Addr>({
    isSelected: (addr: Addr) => docState.contentCellImagesSelected.isSelected(addr),
    isMultiselected: () => docState.contentCellImagesSelected.isMultiselected(),
    resetSelected: () => {
      resetAllSelected({
        skipContentCellReset: true,
      });
    },
    select: (addr: Addr) => {
      docState.contentCellImagesSelected.select(addr);
      docState.contentSession.setCellSelected(addr);
    },
    multiSelect: (addr: Addr) => {
      resetAllSelected({
        skipContentCellImagesReset: true
      });
      docState.contentCellImagesSelected.toggleSelect(addr);
    },
    toggleSelect: (addr: Addr) => {
      docState.contentCellImagesSelected.toggleSelect(addr);
      docState.contentSession.setCellSelected(addr);
    },
    setState: () => {
      setContentSession();
      setContentCellImagesSelected();
    }
  });

  const handleSelectImage = (event: React.PointerEvent) => {
    handleSelectItem(event, imageAddr);
  }

  return (
    <MainWrapper onPointerDown={handleSelectImage}>
      { children }
    </MainWrapper>
  );
}
  