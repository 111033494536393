import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useCellMarkerCreate from '../use-cell-marker-create';


const useFunctions = () => {
  const { hideMenu } = useContextMenu();
  const createCellMarker = useCellMarkerCreate();

  const createMarker = (cellAddr: ContentTypes.CellAddr) => {
    createCellMarker(cellAddr);
    hideMenu();
  }

  return {
    createMarker,
  }
}


export default useFunctions;