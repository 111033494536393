import React from 'react';
import ProgressModalViewComponent from 'lego-v2/progress-modal/progress-modal-view';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import AnimSquaresMix from 'libs/anim/squares-mix';


interface Props {
}


export const ProgressViewComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  return (
    <ProgressModalViewComponent
      text={ t("document, release, do")}
      Loader={AnimSquaresMix}
    />
  );
}
