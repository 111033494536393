import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useFunctions = () => {
  const docState = useDocState();

  const {
    setReleaselogsView,
  } = useEditorStatesSetters();

  const setVisible = (visible: TypesShared.PageItemVisibility) => {
    docState.releaselogsView.updateHeaderTitle({visible});
    docState.saveUndo();
    setReleaselogsView();
  }

  const updateCSS = (props: {
    cssUpdate: React.CSSProperties,
    skipUndoSave?: boolean,
  }) => {
    const {
      cssUpdate,
      skipUndoSave
    } = props;
    
    docState.releaselogsView.updateHeaderTitleCSS(cssUpdate);
    if ( ! skipUndoSave ) {
      docState.saveUndo();
    }
    setReleaselogsView();
  }

  return {
    setVisible,
    updateCSS,
  }
}

export default useFunctions;