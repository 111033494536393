import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import LogoViewComponent from 'app/ui-v2/editor-instruction/views/shared/components/doc-header-info/header-info-content/logo/logo-view';
import useAssetsRepo from '../../../hook/use-assets-repo';
import ImageViewComponent from '../../parts/image-view';


interface Props {
}


export const LogoComponent: React.FC<Props> = (props: Props) => {
  const {
  } = props;

  const addr = "logo";
  const assetsRepo = useAssetsRepo();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ( ! wrapperRef.current ) {
      return;
    }

    if ( ! assetsRepo ) {
      console.warn(`Assets repo not ready`);
      return;
    }

    const element = wrapperRef.current;
    assetsRepo.others.addElement(addr, element);

    return () => {
      assetsRepo.others.removeElement(addr);
    }
  }, []);


  return (
    <ImageViewComponent ref={wrapperRef}>
      <LogoViewComponent />
    </ImageViewComponent>
  );
}

