import styled from "styled-components";
import { Text as TextBase } from "../styles";
import { TextWrapper as TextWrapperBase } from "../styles";


export const TextWrapper = styled(TextWrapperBase)`
`;

export const Text = styled(TextBase)`
`;
