import React from 'react';

import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { useDocState }  from 'app/ui/contexts/document';
import useSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useItemMultiSelect from 'app/ui-v2/editor-instruction/views/shared/hooks/use-item-multi-select';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import { MainWrapper } from './styles';


interface Props {
  pageColumnAddr: Types.PageColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    pageColumnAddr,
    children,
  } = props;

  const docState = useDocState();
  const resetAllSelected = useSelectedReset();

  const {
    setCustomFieldsColumnsSelected,
  } = useEditorStatesSetters();


  type Addr = Types.PageColumnAddr;

  const handleSelectItem = useItemMultiSelect<Addr>({
    isSelected: (addr: Addr) => docState.customFieldsColumnsSelected.isSelected(addr),
    isMultiselected: () => docState.customFieldsColumnsSelected.isMultiselected(),
    resetSelected: () => resetAllSelected(),
    select: (addr: Addr) => docState.customFieldsColumnsSelected.select(addr),
    multiSelect: (addr: Addr) => {
      resetAllSelected({skipCustomFieldsReset: true});
      docState.customFieldsColumnsSelected.toggleSelect(addr);
    },
    toggleSelect: (addr: Addr) => docState.customFieldsColumnsSelected.toggleSelect(addr),
    setState: () => setCustomFieldsColumnsSelected(),
  });

  const handleSelectColumn = (event: React.MouseEvent) => {
    handleSelectItem(event, pageColumnAddr);
  }

  return (
    <MainWrapper onPointerDown={handleSelectColumn}>
      { children }
    </MainWrapper>
  );
}
