import { RepoMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';
import { useDocState } from 'app/ui/contexts/document';
import HLSDraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";

import MarkersDraggedViewComponent from './markers-dragged-view';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    children, 
    markerAddr, 
  } = props;

  const docState  = useDocState();
  const setDNDData = useDNDDataSet();
  const { hideMenu } = useContextMenu();

  const {
    setGalleryMarkersSelected,
  } = useEditorStatesSetters();

  const handleDragStart = () => {
  }

  const handleDragMoveFirst = () => {
    hideMenu();

    const markersSelected = docState.galleryMarkersSelected.getSelected();
    const markersKeys = Object.keys(markersSelected);
    const markersAddrs = markersKeys.map(markerKey => RepoMarkersTools.markerKeyToAddr(markerKey));
    
    if ( ! docState.galleryMarkersSelected.isSelected(markerAddr) ) {
      markersAddrs.splice(0, 0, markerAddr);
      docState.galleryMarkersSelected.select(markerAddr);
      setGalleryMarkersSelected();
    }

    const data = {markersAddrs: markersAddrs}
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  return (
    <HLSDraggerComponent
      debug={false}
      dndContext={DNDContext.marker.gallery}
      onDragStart={handleDragStart}
      onDragMoveFirst={handleDragMoveFirst}
      draggedView={ <MarkersDraggedViewComponent /> }
    >
      { children }
    </HLSDraggerComponent>
  );

}
