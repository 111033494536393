import { MenuItemType } from 'lego-v2/menu';

import { Icon } from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useFunctions from './__use-functions';


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();

  const getCreateMarker = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('marker, new'),
      iconComponent: Icon.Create,
      onClick: (event: React.MouseEvent) => fns.createMarker()
    }
  });

  return {
    getCreateMarker,
  }
}


export default useItems;