import { useRecoilValue } from 'recoil';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { UIState_ContentView } from 'app/ui/states/editor-instruction';
import usePageLayout from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-layout';
import usePageMargins from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-margins';


const useStates = () => {
  const headerMetaVisible = useRecoilValue(UIState_ContentView.headerMetaVisible);
  const markersVisible = useRecoilValue(UIState_ContentView.headerMarkersVisible);
  const headerFieldsVisible = useRecoilValue(UIState_ContentView.headerFieldsVisible);
  const headerInternalVisible = useRecoilValue(UIState_ContentView.headerInternalVisible);

  const sectionsEnabled  = useRecoilValue(UIState_Content.sectionsEnabled);
  const sectionsIndexing = useRecoilValue(UIState_Content.sectionsIndexing);
  const sectionsNamesCSS = useRecoilValue(UIState_Content.sectionsNamesCSS);

  const pageLayout = usePageLayout();
  const pageMargins = usePageMargins();


  return {
    headerMetaVisible,
    markersVisible,
    headerFieldsVisible,
    headerInternalVisible,

    sectionsEnabled,
    sectionsIndexing,
    sectionsNamesCSS,

    pageLayout,
    pageMargins,
  }
}


export default useStates;