import { HeaderRowsTypes } from "app/arch/editor-instruction/document/states/persistent/header-rows";
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import useStates from "./__use-states";
import useCustomRowPropsPanel from "../use-custom-row-props-panel";


const useFunctions = () => {
  const docState = useDocState();
  const states = useStates();

  const {
    setHeaderRows,
    setCustomRowsRowsSelected,
  } = useEditorStatesSetters();

  const showPanel = useCustomRowPropsPanel();

  const rowAddAbove = (rowAddr: HeaderRowsTypes.PageRowAddr) => {
    const newRowAddr = docState.headerRows.addRowBefore(rowAddr, HeaderRowsTypes.RowType.TEXT);
    setHeaderRows();
    docState.saveUndo();

    const newPageRowAddr = {
      pageIdx: rowAddr.pageIdx,
      ...newRowAddr,
    }
    docState.customRowsRowsSelected.unselect(rowAddr);
    docState.customRowsRowsSelected.select(newPageRowAddr);
    setCustomRowsRowsSelected();
  }

  const rowAddBelow = (rowAddr: HeaderRowsTypes.PageRowAddr) => {
    const newRowAddr = docState.headerRows.addRowAfter(rowAddr, HeaderRowsTypes.RowType.TEXT);
    setHeaderRows();
    docState.saveUndo();

    const newPageRowAddr = {
      pageIdx: rowAddr.pageIdx,
      ...newRowAddr,
    }
    docState.customRowsRowsSelected.unselect(rowAddr);
    docState.customRowsRowsSelected.select(newPageRowAddr);
    setCustomRowsRowsSelected();
  }

  const rowDelete = (rowAddr: HeaderRowsTypes.RowAddr) => {
    docState.headerRows.deleteRow(rowAddr);
    setHeaderRows();
    docState.saveUndo();
  }

  const rowProperties = (rowAddr: HeaderRowsTypes.RowAddr) => {
    showPanel();
  }
  
  const skipDelete = () => {
    const rowsAddrs = states.rowsAddrs;
    const skip = rowsAddrs.length <= 1;
    return skip;
  }

  return {
    rowAddAbove,
    rowAddBelow,
    rowDelete,
    rowProperties,
    skipDelete,
  }
}

export default useFunctions;