import useContextPanel from 'app/ui-v2/editor-instruction/hooks/use-context-panel';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ContextPanelContentComponent from 'app/ui-v2/editor-instruction/components/context-panel-content';
import usePanelColumnConfig from 'app/ui-v2/editor-instruction/views/view-content/components/content/side-panels/panel-properties/side-panel/hooks/panel-column/use-panel-column-config';


const useColumnPropsPanel = () => {
  const t = useTranslations();

  const {
    showPanel,
    hidePanel,
  }= useContextPanel();


  const hook = () => {
    showPanel({
      title: t("column"),
      content: <__PanelComponent />
    });
  }

  return hook;
}


interface __Props {}
 
const __PanelComponent: React.FC<__Props> = (props: __Props) => {
  const getConfig = usePanelColumnConfig();
  return <ContextPanelContentComponent config={getConfig()} />;
}


export default useColumnPropsPanel;