import styled from "styled-components";
import { StyledListBase } from 'lego-v2/list/styles';
import { PanelSecondary } from "lego/styles/panel_secondary";


export const MainWrapper = styled.div`
`;


export const MenuPanelBase = styled(PanelSecondary)`
  position: relative;
  min-width: 140px;
`;

export const StyledList = styled(StyledListBase)`
`;

